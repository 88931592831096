import React from "react";
import "./GettingStartedContent.css";
import { Header, Image, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import get from "lodash/get";

const GettingStartedContent = props => {
  return (
    <Segment raised className="howToSegment">
      <div className="HowToHeading">
        <Image
          wrapped
          size="small"
          src="https://s3.amazonaws.com/countingcalculi-assets/magic_images/Owl_Scroll.png"
        />
        <Header>Getting Started</Header>
      </div>

      <div className="HowToContent">
        <p>Hi {get(props, "user.displayName", "Friend")},</p>

        <p>
          Owl & Scroll makes it easy to organize and share your Kindle
          annotations with your friends. But first, you’ll need to import some
          of your annotations.
        </p>
        <p>
          Click&nbsp;
          <Link
            to={{ pathname: "/import" }}
            data-clickable="true"
            data-location="gettingStartedContent"
            data-action="goToImport"
          >
            Import
          </Link>
          &nbsp;to get started.
        </p>

        <p>
          If you have any questions, you can always reach us&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:kevin.mclaughlin70+owl-and-scroll-question@gmail.com?Subject=Owl & Scroll Question"
            data-clickable="true"
            data-location="gettingStartedContent"
            data-action="mailto"
            data-label="owl-and-scroll-question"
          >
            here.
          </a>
        </p>
      </div>
    </Segment>
  );
};

export default GettingStartedContent;
