import React from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const OpenPublicLinkButton = ({ bookId }) => (
  <Link to={{ pathname: `books/${bookId}` }} target="_blank">
    <Button
      className="shareButton"
      icon
      labelPosition="left"
      basic
      color="blue"
      compact
      size="tiny"
      data-clickable="true"
      data-location="bookTable"
      data-action="openPublicLink"
      data-label={bookId}
    >
      Open Public Link
      <Icon
        name="external"
        data-clickable="true"
        data-location="bookTable"
        data-action="share"
        data-label={bookId}
      />
    </Button>
  </Link>
);

OpenPublicLinkButton.propTypes = {
  bookId: PropTypes.string.isRequired
};

export default OpenPublicLinkButton;
