import { createSelector } from "reselect";
import keyBy from "lodash/keyBy";
import values from "lodash/values";
import filter from "lodash/filter";

const initialState = {
  byId: {},
  resourcesFetched: {},
  isFetching: false,
  receivedAt: null,
  showWelcome: false
};

function annotationsReducer(state = initialState, action) {
  switch (action.type) {
    case "FETCH_ANNOTATION_DETAILS":
      return {
        ...state,
        isFetching: !!state.byId[action.annotationid]
      };
    case "FETCH_ANNOTATIONS": {
      return {
        ...state,
        isFetching: !state.resourcesFetched[action.resourceInstanceId]
      };
    }
    case "FETCH_ANNOTATION_DETAILS_FAILURE":
    case "FETCH_ANNOTATIONS_FAILURE": {
      return {
        ...state,
        isFetching: false
      };
    }
    case "FETCH_ANNOTATION_DETAILS_SUCCESS": {
      return {
        ...state,
        byId: { ...state.byId, [action.annotationId]: action.annotation },
        // Should probably add receivedAt on a per annotation basis -- but this works for now
        receivedAt: Date.now(),
        isFetching: false
      };
    }
    case "FETCH_ANNOTATIONS_SUCCESS": {
      return {
        ...state,
        byId: { ...state.byId, ...keyBy(action.annotations, "id") },
        isFetching: false,
        receivedAt: Date.now(),
        resourcesFetched: {
          ...state.resourcesFetched,
          [action.resourceInstanceId]: true
        },
        showWelcome: action.annotations.length === 0
      };
    }
    case "SHARE_PUBLIC_LINK_SUCCESS": {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.annotationId]: {
            ...state.byId[action.annotationId],
            publicShare: true
          }
        }
      };
    }
    case "UNSHARE_PUBLIC_LINK_SUCCESS": {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.annotationId]: {
            ...state.byId[action.annotationId],
            publicShare: false
          }
        }
      };
    }
    case "ADD_TO_FAVORITES_SUCCESS": {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.annotationId]: {
            ...state.byId[action.annotationId],
            favorite: true
          }
        }
      };
    }
    case "REMOVE_FROM_FAVORITES_SUCCESS": {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.annotationId]: {
            ...state.byId[action.annotationId],
            favorite: false
          }
        }
      };
    }
    default:
      return state;
  }
}

export default annotationsReducer;

// Selectors
export const selectAnnotations = state => state.annotations.byId;

export const selectAnnotationList = createSelector(
  [selectAnnotations],
  annotations => values(annotations)
);

export const selectAnnotationListByBook = createSelector(
  [selectAnnotationList, (_, bookInstanceId) => bookInstanceId],
  (annotationsList, bookInstanceId) =>
    filter(
      annotationsList,
      annotation => annotation.resourceInstanceId === bookInstanceId
    )
);

export const selectAnnotationById = (state, id) => state.annotations.byId[id];
