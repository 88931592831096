import React from "react";
import PropTypes from "prop-types";
import { Sidebar, Menu, Button, Responsive, Icon } from "semantic-ui-react";
import "./LeftNav.css";

const LeftNav = props => {
  const { sidebarVisibilityHandler, isVisible, children } = props;
  return (
    <Sidebar
      className="annotationSelector"
      animation="overlay"
      width="wide"
      visible={isVisible}
    >
      <Menu fluid size="small" className="sidebarSearchMenu">
        <Responsive
          as={Menu.Item}
          className="toggle"
          minWidth={Responsive.onlyTablet.minWidth}
        >
          <Button
            size="mini"
            onClick={sidebarVisibilityHandler}
            data-clickable="true"
            data-location="annotationSelector"
            data-action="hideAnnotationsSelector"
            icon
          >
            <Icon
              name="left arrow"
              data-clickable="true"
              data-location="annotationSelector"
              data-action="hideAnnotationsSelector"
            />
          </Button>
        </Responsive>
      </Menu>

      <Menu vertical fluid borderless size="large">
        {children}
      </Menu>
    </Sidebar>
  );
};

LeftNav.propTypes = {
  sidebarVisibilityHandler: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isMobile: PropTypes.bool.isRequired
};

export default LeftNav;
