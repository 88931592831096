import React from "react";
import PropTypes from "prop-types";
import AirbnbPropTypes from "airbnb-prop-types";
import {
  Segment,
  Item,
  Visibility,
  Responsive,
  Image
} from "semantic-ui-react";
import "./AnnotationListHeader.css";
import GlobalSearch from "components/GlobalSearch";
import { ModelsBook } from "models";
import get from "lodash/get";

const AnnotationListHeader = props => {
  const {
    book,
    annotationsLength,
    hideTitleVisibility,
    showTitleVisibility,
    children
  } = props;

  return (
    <Segment basic className="annotationListHeader mobileCenter">
      <Item.Group>
        <Responsive {...Responsive.onlyMobile}>
          <GlobalSearch />
        </Responsive>
        <Item>
          <a
            className="ui tiny image"
            target="_blank"
            rel="noreferrer noopener"
            href={`${get(props, "book.url", "")}/?tag=kevinmclaughl-20`}
            data-clickable="true"
            data-location="bookTable"
            data-action="openBookInAmazon"
            data-label={get(book, "id", "(not-set)")}
          >
            <Image
              className="bookImage"
              src={get(book, "imgUrl", "")}
              alt={get(book, "title", "")}
              data-clickable="true"
              data-location="bookTable"
              data-action="openBookInAmazon"
              data-label={get(book, "id", "(not-set)")}
            />
          </a>
          <Item.Content>
            <Visibility
              as="div"
              className="header mobileCenter"
              once={false}
              offset={[0, 40]}
              onBottomPassedReverse={hideTitleVisibility}
              onBottomPassed={showTitleVisibility}
            >
              <div>
                <a
                  className="bookTitle"
                  target="_blank"
                  rel="noreferrer noopener"
                  href={`${get(props, "book.url", "")}/?tag=kevinmclaughl-20`}
                  data-clickable="true"
                  data-location="bookTable"
                  data-action="openBookInAmazon"
                  data-label={get(book, "id", "(not-set)")}
                >
                  {get(book, "title", "")}
                </a>
              </div>
            </Visibility>
            <Item.Meta className="mobileCenter">
              <span>by {get(book, "author", "")}</span>
            </Item.Meta>
            <Item.Description className="mobileCenter">
              <div style={{ fontSize: 16, marginTop: 15 }}>
                {annotationsLength} Annotations
              </div>
            </Item.Description>
            <Item.Extra />
          </Item.Content>
        </Item>
      </Item.Group>
      {children}
    </Segment>
  );
};

AnnotationListHeader.propTypes = {
  book: PropTypes.oneOfType([
    PropTypes.instanceOf(ModelsBook),
    AirbnbPropTypes.explicitNull()
  ]),
  annotationsLength: PropTypes.number,
  hideTitleVisibility: PropTypes.func.isRequired,
  showTitleVisibility: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired // eslint-disable-line react/forbid-prop-types
};

AnnotationListHeader.defaultProps = {
  book: null,
  annotationsLength: 0
};

export default AnnotationListHeader;
