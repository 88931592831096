import React from "react";
import LayoutsDefault from "layouts/default/Default";
import Annotations from "modules/annotations/containers/Annotations";
import BooksSelector from "modules/annotations/containers/BooksSelector";

const ScreensAnnotations = props => (
  <LayoutsDefault {...props} renderMenu={() => <BooksSelector />}>
    <Annotations />
  </LayoutsDefault>
);

export default ScreensAnnotations;
