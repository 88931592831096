import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Modal } from "semantic-ui-react";
import UsernameErrorMessage from "modules/settings/components/UsernameErrorMessage";
import UsernamePopup from "modules/settings/components/UsernamePopup";

class AddUserNameModal extends Component {
  static propTypes = {
    updateUsername: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    hideModal: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      username: ""
    };
  }

  handleSaveClick = () => {
    const { updateUsername, userId } = this.props;
    const { username } = this.state;
    updateUsername(userId, username);
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  render() {
    const { open, hideModal } = this.props;
    const { username } = this.state;

    const isAlphaNumeric = username.match(/^[a-z0-9]+$/i) !== null;
    const isLessThan15Characters = username.length < 15;
    const isUsernameError = !isAlphaNumeric || !isLessThan15Characters;

    return (
      <Modal size="mini" open={open}>
        <Modal.Header textAlign="center">Create a Username </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              Create a username so you can share your annotations with the world
              on your public profile page.
            </p>
            <p>
              {" "}
              <strong>Only</strong> books you choose to share will be shown on
              your profile page.
            </p>
          </Modal.Description>
          <Form
            style={{ marginTop: "15px" }}
            error={username === "" ? false : isUsernameError}
          >
            <Form.Group inline>
              <Form.Field error={isUsernameError}>
                <UsernamePopup
                  username={username}
                  isUsernameError={isUsernameError}
                />
                <label htmlFor="username" style={{ width: "80px" }}>
                  User Name
                </label>
                <Input
                  name="username"
                  id="username"
                  value={username}
                  onChange={this.handleChange}
                />
              </Form.Field>
            </Form.Group>
            <UsernameErrorMessage />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={hideModal}
            data-clickable="true"
            data-location="addUsernameModal"
            data-action="cancel"
          >
            Cancel
          </Button>
          <Button
            positive
            onClick={this.handleSaveClick}
            disabled={isUsernameError}
            data-clickable="true"
            data-location="addUsernameModal"
            data-action="save"
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default AddUserNameModal;
