import React from "react";
import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";
import "./EvernoteExport.css";

const EvernoteExportModal = ({ open, toggleModal, children }) => (
  <Modal
    open={open}
    closeOnEscape
    closeOnRootNodeClick={false}
    onClose={toggleModal}
    closeIcon
    className="shareModal"
  >
    <Modal.Header>Save Annotations to Evernote.</Modal.Header>
    {children}
  </Modal>
);

EvernoteExportModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.array.isRequired // eslint-disable-line react/forbid-prop-types
};

export default EvernoteExportModal;
