export const FETCH_BOOKS = "FETCH_BOOKS";
export const FETCH_BOOKS_FAILURE = "FETCH_BOOKS_FAILURE";
export const FETCH_BOOKS_SUCCESS = "FETCH_BOOKS_SUCCESS";

export const fetchBooks = () => ({
  type: FETCH_BOOKS
});

export const FETCH_BOOK = "FETCH_BOOK";
export const FETCH_BOOK_FAILURE = "FETCH_BOOK_FAILURE";
export const FETCH_BOOK_SUCCESS = "FETCH_BOOK_SUCCESS";

export const fetchBook = resourceId => ({
  type: FETCH_BOOK,
  resourceId
});

export const SET_SELECTED_BOOK = "SET_SELECTED_BOOK";
export const setSelectedBook = id => ({
  type: SET_SELECTED_BOOK,
  id
});

export const SET_SELECTED_SHARED_BOOK = "SET_SELECTED_SHARED_BOOK";
export const setSelectedSharedBook = id => ({
  type: SET_SELECTED_SHARED_BOOK,
  id
});

export const TOGGLE_PUBLIC_BOOK = "TOGGLE_PUBLIC_BOOK";
export const TOGGLE_PUBLIC_BOOK_SUCCESS = "TOGGLE_PUBLIC_BOOK_SUCCESS";
export const TOGGLE_PUBLIC_BOOK_FAILURE = "TOGGLE_PUBLIC_BOOK_FAILURE";

export const togglePublicBook = (resourceInstanceId, publicShareState) => ({
  type: TOGGLE_PUBLIC_BOOK,
  resourceInstanceId,
  publicShareState
});

export const FETCH_PUBLIC_BOOKS = "FETCH_PUBLIC_BOOKS";
export const FETCH_PUBLIC_BOOKS_FAILURE = "FETCH_PUBLIC_BOOKS_FAILURE";
export const FETCH_PUBLIC_BOOKS_SUCCESS = "FETCH_PUBLIC_BOOKS_SUCCESS";

export const fetchPublicBooks = username => ({
  type: FETCH_PUBLIC_BOOKS,
  username
});
