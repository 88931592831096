import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Header, Image } from "semantic-ui-react";
import { ModelsBook } from "models";
import { Redirect } from "react-router-dom";

class ImportedBookModalRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToMyAnnotationsFlag: false
    };
  }

  handleRowClick = () => {
    const { setSelectedBook, closeModal, book } = this.props;
    setSelectedBook(book.id);
    this.setState({ redirectToMyAnnotationsFlag: true }, () => {
      closeModal();
    });
  };

  render() {
    const { book, numberOfAddedAnnotations } = this.props;
    const { redirectToMyAnnotationsFlag } = this.state;

    if (redirectToMyAnnotationsFlag) {
      return <Redirect exact strict push to="/annotations" />;
    }

    return (
      <Table.Row
        key={book.id}
        value={book.id}
        onClick={this.handleRowClick}
        data-clickable="true"
        data-location="recentImportsModal"
        data-action="showAnnotations"
        data-label={book.id}
        style={{ cursor: "pointer" }}
      >
        <Table.Cell
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "350px"
          }}
        >
          <Header as="h4" image>
            <Image src={book.imgUrl} rounded size="mini" />
            <Header.Content
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "325px"
              }}
            >
              {book.title}
              <Header.Subheader
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: "325px"
                }}
              >
                by {book.author}
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Table.Cell>
        <Table.Cell textAlign="center">{numberOfAddedAnnotations}</Table.Cell>
        <Table.Cell
          textAlign="center"
          style={{ paddingRight: "10px" }}
          onClick={this.handleRowClick}
          data-clickable="true"
          data-location="recentImportsModal"
          data-action="showAnnotationsLink"
        >
          <u>view</u>
        </Table.Cell>
      </Table.Row>
    );
  }
}

ImportedBookModalRow.propTypes = {
  setSelectedBook: PropTypes.func.isRequired,
  book: PropTypes.instanceOf(ModelsBook).isRequired,
  numberOfAddedAnnotations: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default ImportedBookModalRow;
