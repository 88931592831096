import React from "react";
import PropTypes from "prop-types";
import { Menu } from "semantic-ui-react";
import map from "lodash/map";
import { ModelsFriend } from "models";
import "./SharedBooksMenu.css";

const SharedBooksMenu = props => {
  const { friends, selectedSharedBookId, setSelectedSharedBook } = props;

  return map(friends, friend => (
    <Menu.Item key={friend.uid}>
      <Menu.Header>{friend.name}</Menu.Header>
      <Menu.Menu>
        {friend.resources.map((book, bookIndex) => {
          return (
            <Menu.Item
              name={book.title}
              key={book.id}
              value={book.id}
              active={selectedSharedBookId === book.id}
              onClick={(e, { value }) => {
                setSelectedSharedBook(value);
              }}
              className="book"
              data-clickable="true"
              data-location="friendsBooks"
              data-action="showAnnotations"
              data-label={book.id}
              data-value={bookIndex}
            />
          );
        })}
      </Menu.Menu>
    </Menu.Item>
  ));
};

SharedBooksMenu.propTypes = {
  friends: PropTypes.arrayOf(PropTypes.instanceOf(ModelsFriend)),
  selectedSharedBookId: PropTypes.string.isRequired,
  setSelectedSharedBook: PropTypes.func.isRequired
};

export default SharedBooksMenu;
