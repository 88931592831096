import React from "react";
import PropTypes from "prop-types";
import { Menu } from "semantic-ui-react";
import { ModelsBook } from "models";
import "./BooksMenu.css";

const BooksMenu = props => {
  const { books, selectedBookId, setSelectedBook } = props;
  return books.map((val, index) => (
    <Menu.Item
      name={val.title}
      key={val.id}
      value={val.id}
      active={selectedBookId === val.id}
      onClick={(e, { value }) => {
        setSelectedBook(value);
      }}
      data-clickable="true"
      data-location="myBooks"
      data-action="showAnnotations"
      data-label={val.id}
      data-value={index}
    />
  ));
};
BooksMenu.propTypes = {
  books: PropTypes.arrayOf(PropTypes.instanceOf(ModelsBook)),
  selectedBookId: PropTypes.string.isRequired,
  setSelectedBook: PropTypes.func.isRequired
};

export default BooksMenu;
