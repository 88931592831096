import React, { Component } from "react";
import { connect } from "react-redux";
import { hideOpenKindleModal as hideOpenKindleModalAction } from "actions/uiActions";
import OpenKindleModal from "modules/annotations/components/OpenKindleModal";
import PropTypes from "prop-types";

class OpenKindle extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    bookTitle: PropTypes.string,
    ASIN: PropTypes.string,
    shared: PropTypes.bool,
    bookId: PropTypes.string,
    imgUrl: PropTypes.string,
    annotiationId: PropTypes.string,
    location: PropTypes.number,
    hideOpenKindleModal: PropTypes.func
  };

  static defaultProps = {
    bookTitle: undefined,
    ASIN: undefined,
    shared: undefined,
    bookId: undefined,
    imgUrl: undefined,
    annotiationId: undefined,
    location: undefined,
    hideOpenKindleModal: undefined
  };

  render() {
    const {
      open,
      bookTitle,
      ASIN,
      bookId,
      imgUrl,
      location,
      shared,
      annotiationId,
      hideOpenKindleModal
    } = this.props;
    return bookTitle ? (
      <OpenKindleModal
        hideOpenKindleModal={hideOpenKindleModal}
        handleRef={this.handleRef}
        open={open}
        bookTitle={bookTitle}
        ASIN={ASIN}
        bookId={bookId}
        imgUrl={imgUrl}
        location={location}
        shared={shared}
        annotiationId={annotiationId}
      />
    ) : null;
  }
}

const mapStateToProps = state => ({
  open: state.ui.showOpenKindleWindow,
  bookTitle: state.ui.kindleBookTitle,
  ASIN: state.ui.kindleBookASIN,
  bookId: state.ui.kindleBookId,
  imgUrl: state.ui.kindleBookImgUrl,
  location: state.ui.kindleLocation,
  shared: state.ui.kindleBookShsared,
  annotiationId: state.ui.kindleAnnotationId
});

export default connect(
  mapStateToProps,
  { hideOpenKindleModal: hideOpenKindleModalAction }
)(OpenKindle);
