export const FETCH_ANNOTATIONS = "FETCH_ANNOTATIONS";
export const FETCH_ANNOTATIONS_FAILURE = "FETCH_ANNOTATIONS_FAILURE";
export const FETCH_ANNOTATIONS_SUCCESS = "FETCH_ANNOTATIONS_SUCCESS";

// This might should be split into multiple parallel actions. Should probably be one to one mapping
export const fetchAnnotations = resourceInstanceId => ({
  type: FETCH_ANNOTATIONS,
  resourceInstanceId
});

export const SHARE_PUBLIC_LINK = "SHARE_PUBLIC_LINK";
export const SHARE_PUBLIC_LINK_SUCCESS = "SHARE_PUBLIC_LINK_SUCCESS";
export const SHARE_PUBLIC_LINK_FAILURE = "SHARE_PUBLIC_LINK_FAILURE";

export const sharePublicLink = annotationId => ({
  type: SHARE_PUBLIC_LINK,
  annotationId
});

export const UNSHARE_PUBLIC_LINK = "UNSHARE_PUBLIC_LINK";
export const UNSHARE_PUBLIC_LINK_SUCCESS = "UNSHARE_PUBLIC_LINK_SUCCESS";
export const UNSHARE_PUBLIC_LINK_FAILURE = "UNSHARE_PUBLIC_LINK_FAILURE";

export const unsharePublicLink = annotationId => ({
  type: UNSHARE_PUBLIC_LINK,
  annotationId
});

export const ADD_TO_FAVORITES = "ADD_TO_FAVORITES";
export const ADD_TO_FAVORITES_SUCCESS = "ADD_TO_FAVORITES_SUCCESS";
export const ADD_TO_FAVORITES_FAILURE = "ADD_TO_FAVORITES_FAILURE";

export const addToFavorites = annotationId => ({
  type: ADD_TO_FAVORITES,
  annotationId
});

export const REMOVE_FROM_FAVORITES = "REMOVE_FROM_FAVORITES";
export const REMOVE_FROM_FAVORITES_SUCCESS = "REMOVE_FROM_FAVORITES_SUCCESS";
export const REMOVE_FROM_FAVORITES_FAILURE = "REMOVE_FROM_FAVORITES_FAILURE";

export const removeFromFavorites = annotationId => ({
  type: REMOVE_FROM_FAVORITES,
  annotationId
});

export const FETCH_ANNOTATION_DETAILS = "FETCH_ANNOTATION_DETAILS";
export const FETCH_ANNOTATION_DETAILS_FAILURE =
  "FETCH_ANNOTATION_DETAILS_FAILURE";
export const FETCH_ANNOTATION_DETAILS_SUCCESS =
  "FETCH_ANNOTATION_DETAILS_SUCCESS";

export const fetchAnnotationDetails = annotationId => ({
  type: FETCH_ANNOTATION_DETAILS,
  annotationId
});
