import { put, takeLatest, all, call } from "redux-saga/effects";

import {
  FETCH_NOTES_FAILURE,
  FETCH_NOTES_SUCCESS,
  FETCH_NOTES,
  ADD_NOTE_FAILURE,
  ADD_NOTE_SUCCESS,
  ADD_NOTE,
  DELETE_NOTE_FAILURE,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE,
  EDIT_NOTE_FAILURE,
  EDIT_NOTE_SUCCESS,
  EDIT_NOTE
} from "../actions/notesActions";
import { ModelsNote } from "models";

import { firestore, auth } from "fire";

function requestNotes() {
  /* TODO: updates notes to only pull notes needed for current annotations*/
  const userId = auth().currentUser.uid;
  return firestore()
    .collection("notes")
    .where("userId", "==", userId)
    .get()
    .then(querySnapshot => {
      let notes = [];
      // should be mapping
      querySnapshot.forEach(function(doc) {
        if (doc.exists) {
          let noteData = doc.data();
          let note = new ModelsNote(
            doc.id,
            noteData.userId,
            noteData.annotationId,
            noteData.content,
            noteData.dateCreated,
            noteData.dateModified,
            noteData.type
          );
          notes.push(note);
        }
      });

      return notes;
    });
}

function* fetchNotes() {
  try {
    const notes = yield call(requestNotes);
    yield put({ type: FETCH_NOTES_SUCCESS, notes });
  } catch (e) {
    yield put({ type: FETCH_NOTES_FAILURE, message: e.message });
    console.log(e);
  }
}

function requestAddNote(annotationId, noteContent, noteType) {
  const userId = auth().currentUser.uid;
  return firestore()
    .collection("notes")
    .add({
      userId,
      annotationId,
      content: noteContent,
      type: noteType,
      dateCreated: Date.now(),
      dateModified: Date.now()
    })
    .then(resp => resp);
}

function* addNote(action) {
  try {
    const noteDoc = yield call(
      requestAddNote,
      action.annotationId,
      action.noteContent,
      action.noteType
    );
    const newNote = yield noteDoc.get();
    const noteData = newNote.data();
    const note = new ModelsNote(
      newNote.id,
      noteData.userId,
      noteData.annotationId,
      noteData.content,
      noteData.dateCreated,
      noteData.dateModified,
      noteData.type
    );
    yield put({ type: ADD_NOTE_SUCCESS, note });
  } catch (e) {
    yield put({ type: ADD_NOTE_FAILURE, message: e.message });
    console.log(e);
  }
}
function requestDeleteNote(noteId) {
  return firestore()
    .collection("notes")
    .doc(noteId)
    .delete();
}

function* deleteNote(action) {
  try {
    yield call(requestDeleteNote, action.noteId);
    yield put({ type: DELETE_NOTE_SUCCESS, noteId: action.noteId });
  } catch (e) {
    yield put({ type: DELETE_NOTE_FAILURE, message: e.message });
    console.log(e);
  }
}

function requestEditNote(noteId, noteContent, dateModified) {
  return firestore()
    .collection("notes")
    .doc(noteId)
    .update({
      content: noteContent,
      dateModified
    })
    .then(() => true);
}

function* editNote(action) {
  try {
    const dateModified = Date.now();
    const note = yield call(
      requestEditNote,
      action.noteId,
      action.noteContent,
      dateModified
    );

    if (note) {
      yield put({
        type: EDIT_NOTE_SUCCESS,
        noteId: action.noteId,
        noteContent: action.noteContent,
        dateCreated: action.dateCreated,
        userId: action.userId,
        dateModified,
        annotationId: action.annotationId
      });
    }
  } catch (e) {
    yield put({ type: EDIT_NOTE_FAILURE, message: e.message });
    console.log(e);
  }
}

export default function* notesSaga() {
  yield all([
    takeLatest(FETCH_NOTES, fetchNotes),
    takeLatest(ADD_NOTE, addNote),
    takeLatest(DELETE_NOTE, deleteNote),
    takeLatest(EDIT_NOTE, editNote)
  ]);
}
