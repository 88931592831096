
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ModelsUser } from "models";
import AddUsernameModal from "modules/settings/components/AddUsernameModal";
import { updateUsername as updateUsernameAction } from "actions/authActions";
import { hideCreateUsernameModal as hideCreateUsernameModalAction } from "actions/uiActions";
import get from "lodash/get";

const AddUsernameModalContainer = ({ user, open, updateUsername, hideCreateUsernameModal }) => {
  const userId = get(user, "id");
  const username = get(user, "username");
  const email = get(user, "email");

  return (
    <AddUsernameModal
      userId={userId}
      username={username}
      email={email}
      open={open}
      hideModal={hideCreateUsernameModal}
      updateUsername={updateUsername}
    />
  );
};

AddUsernameModalContainer.propTypes = {
  user: PropTypes.instanceOf(ModelsUser),
  open: PropTypes.bool.isRequired,
  updateUsername: PropTypes.func.isRequired,
  hideCreateUsernameModal: PropTypes.func.isRequired
};

AddUsernameModalContainer.defaultProps = {
  user: null
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    open: state.ui.showCreateUsernameModal
  };
}

export default connect(
  mapStateToProps,
  { updateUsername: updateUsernameAction, hideCreateUsernameModal: hideCreateUsernameModalAction }
)(AddUsernameModalContainer);
