import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card } from "semantic-ui-react";
import "./AnnotationList.css";
import Annotation from "modules/annotation/Annotation.js";
import AnnotationListHeaderContainer from "modules/annotations/containers/AnnotationListHeaderContainer";
import ShareBookModal from "modules/annotations/components/ShareBookModal";
import OpenKindle from "modules/annotations/containers/OpenKindle";
import EvernoteExport from "modules/annotations/containers/EvernoteExport";
import get from "lodash/get";
import filter from "lodash/filter";
import { ModelsBook, ModelsAnnotation, ModelsNote } from "models";
import AirbnbPropTypes from "airbnb-prop-types";
import { connect } from "react-redux";

class AnnotationList extends Component {
  static propTypes = {
    book: PropTypes.oneOfType([
      PropTypes.instanceOf(ModelsBook),
      AirbnbPropTypes.explicitNull
    ]),
    annotations: PropTypes.arrayOf(PropTypes.instanceOf(ModelsAnnotation))
      .isRequired,
    customNotes: PropTypes.arrayOf(PropTypes.instanceOf(ModelsNote)).isRequired,
    type: PropTypes.string.isRequired,
    showOpenKindleWindow: PropTypes.bool.isRequired
  };

  static defaultProps = {
    book: null
  };

  constructor(props) {
    super(props);
    // Should not live in presentational compponent
    this.state = {
      shareBookModalOpen: false,
      evernoteExportModalOpen: false
    };
  }

  render() {
    const {
      book,
      annotations,
      customNotes,
      type,
      showOpenKindleWindow
    } = this.props;
    const { shareBookModalOpen, evernoteExportModalOpen } = this.state;

    return (
      book && (
        <React.Fragment>
          <AnnotationListHeaderContainer
            book={book}
            annotationsLength={annotations.length}
            openShareBookModal={() =>
              this.setState({ shareBookModalOpen: true })
            }
            openEvernoteExportModal={() =>
              this.setState({ evernoteExportModalOpen: true })
            }
            type={type}
            username={get(book, "username")}
          />
          <Card.Group className="annotations">
            {annotations.map((annotation, index) => (
              <Annotation
                key={annotation.id}
                annotation={annotation}
                book={book}
                annotationId={annotation.id}
                ASIN={book.ASIN}
                bookId={book.id}
                title={book.title}
                author={book.author}
                content={annotation.content}
                location={annotation.location}
                dateCreated={annotation.dateCreated}
                note={annotation.note}
                customNotes={filter(
                  customNotes,
                  val => val.annotationId === annotation.id
                )}
                type={annotation.type}
                order={index}
                publicShare={annotation.publicShare}
                shared={type === "shared" || type === "public"}
                favorite={annotation.favorite}
              />
            ))}
          </Card.Group>
          {type === "own" && (
            <React.Fragment>
              <ShareBookModal
                toggleModal={() =>
                  this.setState(prevState => ({
                    shareBookModalOpen: !prevState.shareBookModalOpen
                  }))
                }
                open={shareBookModalOpen}
                bookName={get(book, "title")}
                bookId={get(book, "id")}
              />
              <EvernoteExport
                toggleModal={() =>
                  this.setState(prevState => ({
                    evernoteExportModalOpen: !prevState.evernoteExportModalOpen
                  }))
                }
                open={evernoteExportModalOpen}
              />
            </React.Fragment>
          )}
          <OpenKindle open={showOpenKindleWindow} />
        </React.Fragment>
      )
    );
  }
}

const mapStateToProps = state => ({
  showOpenKindleWindow: state.ui.showOpenKindleWindow
});

export default connect(
  mapStateToProps,
  {}
)(AnnotationList);
