import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import PropTypes from "prop-types";
import ScreensFriendsAnnotations from "screens/SharedAnnotations";
import ScreensAnnotations from "screens/Annotations";
import ScreensImport from "screens/Import";
import ScreensSettings from "screens/Settings";
import ScreensAnnotationDetails from "screens/AnnotationDetails";
import ScreensLogin from "screens/Login";
import ScreensPrivacyPolicy from "screens/PrivacyPolicy";
import ScreensPublicBook from "screens/PublicBook";
import ScreensPublicProfile from "screens/PublicProfile";
import ScreensForgotPassword from "screens/ForgotPassword";
import {
  updateInstalled as updateInstalledAction,
  addToRecentImports as addToRecentImportsAction
} from "actions/extensionActions";
import { showRecentImports as showRecentImportsAction } from "actions/uiActions";
import {
  setUser as setUserAction,
  getUsername as getUsernameAction,
  setNoUser as setNoUserAction
} from "actions/authActions";
import { auth } from "fire";
import { ModelsUser } from "models";
import { Loader } from "semantic-ui-react";

const PrivateRoute = ({
  component: Component,
  user,
  initialAuthSet,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (user) {
        return <Component {...props} />;
      }
      if (!initialAuthSet) {
        return (
          <Loader active inline="centered">
            Loading
          </Loader>
        );
      }
      {
        const { location } = props;
        return (
          <Redirect
            to={{
              pathname: `/login${location.pathname}`,
              state: { from: props.location }
            }}
          />
        );
      }
    }}
  />
);

export class ScreensRoutes extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(ModelsUser)
  };

  static defaultProps = {
    user: null
  };

  componentWillMount() {
    const { setUser, setNoUser, getUsername } = this.props;
    auth().onAuthStateChanged(user => {
      if (user) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          userId: user.uid
        });
        setUser(user.uid, user.email);
        getUsername();
        this.sendIdTokenToExtension();
      } else {
        setNoUser();
        this.sendLogOutMessageToExtension();
        console.log("No user session exists.");
      }
    });
  }

  componentDidMount() {
    // Listen for updates from the extension
    window.addEventListener("message", this.hasExtension, false);
    // Request install confirmation
    window.postMessage(
      { from: "APP", message: "requestInstallConfirmation" },
      "*"
    );
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.hasExtension);
  }

  /**
   * Tell extension we have logged out or are no longer logged in.
   */
  sendLogOutMessageToExtension = () => {
    window.postMessage({ from: "APP", message: "logOutExtension" }, "*");
  };

  /**
   * Sends `idToken` from current user to extension.
   */
  sendIdTokenToExtension = () => {
    const { currentUser } = auth();
    if (currentUser) {
      currentUser.getIdToken(true).then(idToken => {
        window.postMessage({ from: "APP", message: "idToken", idToken }, "*");
      });
    }
  };

  hasExtension = event => {
    const {
      updateInstalled,
      showRecentImports,
      addToRecentImports
    } = this.props;
    // We only accept messages from ourselves
    if (event.source !== window) {
      return;
    }
    if (event.data.from && event.data.from === "EXTENSION") {
      switch (event.data.message) {
        case "Extension Installed":
          console.log(event.data.message);
          updateInstalled(true);
          break;
        case "Added Annotations":
          console.log(event.data);
          addToRecentImports(
            event.data.importedResourceId,
            event.data.numberOfAddedAnnotations
          );
          showRecentImports();
          break;
        case "RequestIdToken":
          // for cases where the extension requests for the `idToken`.
          this.sendIdTokenToExtension();
          break;
        default:
          console.log("unkown message from extension", event.data);
          break;
      }
    } // We only accept messages from ourselves
    if (event.source !== window) return;
    if (event.data.type && event.data.type === "FROM_EXTENSION") {
      if (event.data.text === "Extension Installed") {
        updateInstalled(true);
      }
      console.log(`Message from extension:  ${event.data.text}`);
    }
  };

  render() {
    const { user, initialAuthSet } = this.props;
    return (
      <Router>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/annotations" />} />
          <Route path="/login/:next?" component={ScreensLogin} />
          <Route path="/forgot-password" component={ScreensForgotPassword} />
          <Route
            path="/annotation/:annotationId"
            component={ScreensAnnotationDetails}
          />
          <Route
            path="/books/:resourceInstanceId"
            render={props => (
              <ScreensPublicBook {...props} isLoggedIn={!!user} />
            )}
          />
          <Route
            exact
            path="/privacy_policy"
            render={routerProps => <ScreensPrivacyPolicy {...routerProps} />}
          />
          <PrivateRoute
            path="/social"
            component={ScreensFriendsAnnotations}
            user={user}
            initialAuthSet={initialAuthSet}
          />
          <PrivateRoute
            path="/annotations"
            component={ScreensAnnotations}
            user={user}
            initialAuthSet={initialAuthSet}
          />
          <PrivateRoute
            path="/import"
            component={ScreensImport}
            user={user}
            initialAuthSet={initialAuthSet}
          />
          <Route
            exact
            path="/public/:username"
            render={routerProps => (
              <ScreensPublicProfile
                {...routerProps}
                initialAuthSet={initialAuthSet}
                user={user}
                isLoggedIn={!!user}
              />
            )}
          />
          <PrivateRoute path="/import" component={ScreensImport} user={user} />
          <PrivateRoute
            path="/settings"
            component={ScreensSettings}
            user={user}
            initialAuthSet={initialAuthSet}
          />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  installed: state.extension.installed,
  initialAuthSet: state.auth.initialAuthSet
});

export default connect(
  mapStateToProps,
  {
    updateInstalled: updateInstalledAction,
    setUser: setUserAction,
    setNoUser: setNoUserAction,
    getUsername: getUsernameAction,
    addToRecentImports: addToRecentImportsAction,
    showRecentImports: showRecentImportsAction
  }
)(ScreensRoutes);
