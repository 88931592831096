import React, { Component } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { Container, Sidebar, Segment, Responsive } from "semantic-ui-react";
import TopNav from "modules/topNav/TopNav";
import "./Default.css";
import PropTypes from "prop-types";
import LeftNav from "components/leftNav/LeftNav";
import LeftNavToggle from "components/leftNavToggle/LeftNavToggle";
import ImportedBookModal from "modules/import/ImportedBookModal";
import AddUsernameModalContainer from "modules/settings/containers/AddUsernameModalContainer";
import withFullStoryIdentity from "hoc/withFullStoryIdentity";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  showSidebarVisibility as showSidebarVisibilityAction,
  hideSidebarVisibility as hideSidebarVisibilityAction
} from "actions/uiActions";

class LayoutsDefault extends Component {
  constructor(props) {
    super(props);

    this.state = {
      containerWidth: window.innerWidth
    };
  }

  sidebarVisibilityHandler = () => {
    const {
      showSidebar,
      showSidebarVisibility,
      hideSidebarVisibility
    } = this.props;
    showSidebar ? hideSidebarVisibility() : showSidebarVisibility();
  };

  isMobile = () => window.innerWidth < Responsive.onlyTablet.minWidth;

  render() {
    const {
      location,
      children,
      showSidebarVisibilityHandler,
      renderMenu,
      showImportWindow,
      showSidebar
    } = this.props;
    const { containerWidth } = this.state;
    const pathname = get(location, "pathname");
    const isMobile = this.isMobile();

    return (
      <div>
        <Container className="TopMenu" fluid>
          <TopNav
            className="TopMenu"
            pathname={pathname}
            showSidebarVisibilityHandler={showSidebarVisibilityHandler}
            sidebarVisibilityHandler={this.sidebarVisibilityHandler}
          />
        </Container>
        <Sidebar.Pushable as={Segment} className="annotationsSegement">
          <LeftNav
            sidebarVisibilityHandler={this.sidebarVisibilityHandler}
            isVisible={showSidebar}
            isMobile={isMobile}
          >
            {renderMenu()}
          </LeftNav>
          <LeftNavToggle
            isVisible={!showSidebar}
            sidebarVisibilityHandler={this.sidebarVisibilityHandler}
          />
          <Sidebar.Pusher
            style={
              showSidebar && !isMobile ? { marginLeft: 350 } : { marginLeft: 0 }
            }
          >
            <Responsive
              onUpdate={() =>
                this.setState({ containerWidth: window.innerWidth })
              }
            >
              <div>
                {React.cloneElement(children, { containerWidth, showSidebar })}
              </div>
            </Responsive>
          </Sidebar.Pusher>
        </Sidebar.Pushable>

        <ToastContainer />
        <ImportedBookModal open={showImportWindow} />
        <AddUsernameModalContainer />
      </div>
    );
  }
}
// ImportedBookModal should live in a root component
// fix showSidebarVisibililityHandler and sidebarVisibilityHander

LayoutsDefault.defaultProps = {
  showSidebarVisibilityHandler: true
};

LayoutsDefault.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  showSidebarVisibilityHandler: PropTypes.bool,
  renderMenu: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  showImportWindow: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  showSidebar: state.ui.showSidebar,
  showImportWindow: state.ui.showImportWindow,
  showOpenKindleWindow: state.ui.showOpenKindleWindow
});

export default connect(
  mapStateToProps,
  {
    showSidebarVisibility: showSidebarVisibilityAction,
    hideSidebarVisibility: hideSidebarVisibilityAction
  }
)(withFullStoryIdentity(LayoutsDefault));
