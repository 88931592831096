export const SET_CURRENT_SELECTED_BOOK = "SET_CURRENT_SELECTED_BOOK";
export const setCurrentSelectedBook = view => ({
  type: SET_CURRENT_SELECTED_BOOK,
  view
});

export const SHOW_TITLE_VISIBILITY = "SHOW_TITLE_VISIBILITY";
export const showTitleVisibility = () => ({
  type: SHOW_TITLE_VISIBILITY
});

export const HIDE_TITLE_VISIBILITY = "HIDE_TITLE_VISIBILITY";
export const hideTitleVisibility = () => ({
  type: HIDE_TITLE_VISIBILITY
});

export const SET_SEARCH_VAL = "SET_SEARCH_VAL";
export const setSearchVal = searchVal => ({
  type: SET_SEARCH_VAL,
  searchVal
});

export const SET_SEARCH_FILTER = "SET_SEARCH_FILTER";
export const setSearchFilter = filterText => ({
  type: SET_SEARCH_FILTER,
  filterText
});

export const SHOW_SIDEBAR_VISIBILITY = "SHOW_SIDEBAR_VISIBILITY";
export const showSidebarVisibility = () => ({
  type: SHOW_SIDEBAR_VISIBILITY
});

export const HIDE_SIDEBAR_VISIBILITY = "HIDE_SIDEBAR_VISIBILITY";
export const hideSidebarVisibility = () => ({
  type: HIDE_SIDEBAR_VISIBILITY
});

export const SHOW_RECENT_IMPORTS = "SHOW_RECENT_IMPORTS";
export const showRecentImports = () => ({
  type: SHOW_RECENT_IMPORTS
});

export const HIDE_RECENT_IMPORTS = "HIDE_RECENT_IMPORTS";
export const hideRecentImports = () => ({
  type: HIDE_RECENT_IMPORTS
});

export const SHOW_OPEN_KINDLE_MODAL = "SHOW_OPEN_KINDLE_MODAL";
export const showOpenKindleModal = () => ({
  type: SHOW_OPEN_KINDLE_MODAL
});

export const HIDE_OPEN_KINDLE_MODAL = "HIDE_OPEN_KINDLE_MODAL";
export const hideOpenKindleModal = () => ({
  type: HIDE_OPEN_KINDLE_MODAL
});

export const SET_KINDLE_MODAL_INFO = "SET_KINDLE_MODAL_INFO";
export const setKindleModalInfo = (
  kindleBookTitle,
  kindleBookASIN,
  kindleBookId,
  kindleBookImgUrl,
  kindleLocation,
  kindleBookShsared,
  kindleAnnotationId
) => ({
  type: SET_KINDLE_MODAL_INFO,
  kindleBookTitle,
  kindleBookASIN,
  kindleBookId,
  kindleBookImgUrl,
  kindleLocation,
  kindleBookShsared,
  kindleAnnotationId
});

export const SHOW_CREATE_USERNAME_MODAL = "SHOW_CREATE_USERNAME_MODAL";
export const showCreateUsernameModal = () => ({
  type: SHOW_CREATE_USERNAME_MODAL
});

export const HIDE_CREATE_USERNAME_MODAL = "HIDE_CREATE_USERNAME_MODAL";
export const hideCreateUsernameModal = () => ({
  type: HIDE_CREATE_USERNAME_MODAL
});
