import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { Redirect } from "react-router-dom";

class ModalButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToMyAnnotationsFlag: false
    };
  }

  viewAnnotationsHandler = () => {
    const { closeModal } = this.props;
    this.setState({ redirectToMyAnnotationsFlag: true }, () => {
      closeModal();
    });
  };

  render() {
    const { hasAnnotations, importMoreHandler } = this.props;
    const { redirectToMyAnnotationsFlag } = this.state;

    if (redirectToMyAnnotationsFlag) {
      return <Redirect exact strict push to="/annotations" />;
    }

    return (
      <div className="buttons">
        <Button
          size="tiny"
          compact
          onClick={this.viewAnnotationsHandler}
          data-location="importedBookModel"
          data-action="viewAnnotations"
          data-clickable="true"
        >
          View Annotations
        </Button>
        <Button
          size="tiny"
          positive
          compact
          onClick={importMoreHandler}
          data-location="importedBookModel"
          data-action="importMore"
          data-clickable="true"
        >
          Import&nbsp;
          {hasAnnotations && <span>More&nbsp;</span>}
          Annotations
        </Button>
      </div>
    );
  }
}

ModalButtons.propTypes = {
  hasAnnotations: PropTypes.bool.isRequired,
  importMoreHandler: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default ModalButtons;
