import React, { Component } from "react";
import PropTypes from "prop-types";
import { googleProvider, auth, firestore } from "fire";
import { connect } from "react-redux";
import queryString from "query-string";
import get from "lodash/get";
import { setUser as setUserAction } from "actions/authActions";
import LoginForm from "modules/login/LoginForm";
import "./Login.css";

class Login extends Component {
  /**
   * Redirect to '/' if the user prop has been populated.
   */

  static propTypes = {
    history: PropTypes.object.isRequired,
    user: PropTypes.object,
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    const queryStringParams = queryString.parse(props.location.search);
    const showLogin = queryStringParams.login === "true";
    this.state = {
      isEmailLoginExpanded: false,
      createAccountByEmailMode: !showLogin
    };
  }

  componentWillReceiveProps(nextProps) {
    const { user } = this.props;
    if (user || nextProps.user) {
      this.redirectToHome();
    }
  }

  /**
   * Login with google.
   */
  loginWithGoogle = () => {
    googleProvider.addScope(
      "https://www.googleapis.com/auth/contacts.readonly"
    );
    return auth()
      .signInWithPopup(googleProvider)
      .then(result => {
        // const token = result.credential.accessToken // we get tokens too.
        const { user } = result;
        this.saveUser(user);
      })
      .catch(console.error.bind(console));
  };

  // On-Click'ers
  expandEmailLogin = () => {
    const { isEmailLoginExpanded } = this.state;
    this.setState({ isEmailLoginExpanded: !isEmailLoginExpanded });
  };

  toggleCreateAccountByEmailMode = () => {
    const { createAccountByEmailMode } = this.state;
    this.setState({
      createAccountByEmailMode: !createAccountByEmailMode
    });
  };

  saveUser = user => {
    return firestore()
      .collection("users")
      .where("email", "==", user.email)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          console.log("user already exists, bypassing write...");
          // TODO: Should probably live elsewhere.
          this.redirectToHome();
        } else {
          this.writeUserToDb(user, false);
        }
      });
  };

  writeUserToDb(user) {
    /*
    Writes a totally new user data to the firebase store.
    */
    const { setUser } = this.props;
    const userInfo = {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      profile_picture: user.email,
      photoURL: user.photoURL,
      providerId: get(user, "providerData[0].providerId"),
      providerUid: get(user, "providerData[0].uid")
    };
    firestore()
      .collection("users")
      .doc(user.uid)
      .set(userInfo)
      .then(() => {
        setUser(user.uid, user.email);
        this.redirectToHome(user);
      })
      .catch(console.error.bind(console));
  }

  redirectToHome() {
    // TODO: abstract this into some authentication concern...
    const { match, history } = this.props;
    const redirectRoute = match.params.next ? `/${match.params.next}` : "/";
    console.log(`redirecting to ${redirectRoute}`);
    history.push(redirectRoute);
  }

  render() {
    const { createAccountByEmailMode } = this.state;
    return (
      <LoginForm
        loginWithGoogle={this.loginWithGoogle}
        createAccountByEmailMode={createAccountByEmailMode}
        toggleCreateAccountByEmailMode={this.toggleCreateAccountByEmailMode}
        saveUser={this.saveUser}
      />
    );
  }
}

const mapStateToProps = state => ({ user: state.auth.user });

export default connect(
  mapStateToProps,
  { setUser: setUserAction }
)(Login);
