import React from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const BuyBookButton = ({ bookId, ASIN }) => (
  <Link
    to={{ pathname: `https://www.amazon.com/dp/${ASIN}/?tag=kevinmclaughl-20` }}
    target="_blank"
  >
    <Button
      className="shareButton"
      icon
      labelPosition="left"
      basic
      color="blue"
      compact
      size="tiny"
      data-clickable="true"
      data-location="bookTable"
      data-action="openPublicLink"
      data-label={bookId}
    >
      Buy Book
      <Icon
        name="plus"
        data-clickable="true"
        data-location="bookTable"
        data-action="buyOnAmazon"
        data-label={bookId}
      />
    </Button>
  </Link>
);

BuyBookButton.propTypes = {
  bookId: PropTypes.string.isRequired,
  ASIN: PropTypes.string.isRequired
};

export default BuyBookButton;
