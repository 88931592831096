import { put, takeLatest, all, call } from "redux-saga/effects";
import {
  FETCH_ANNOTATIONS,
  FETCH_ANNOTATIONS_SUCCESS,
  FETCH_ANNOTATIONS_FAILURE,
  SHARE_PUBLIC_LINK,
  SHARE_PUBLIC_LINK_FAILURE,
  SHARE_PUBLIC_LINK_SUCCESS,
  UNSHARE_PUBLIC_LINK,
  UNSHARE_PUBLIC_LINK_FAILURE,
  UNSHARE_PUBLIC_LINK_SUCCESS,
  ADD_TO_FAVORITES,
  ADD_TO_FAVORITES_SUCCESS,
  ADD_TO_FAVORITES_FAILURE,
  REMOVE_FROM_FAVORITES,
  REMOVE_FROM_FAVORITES_SUCCESS,
  REMOVE_FROM_FAVORITES_FAILURE,
  FETCH_ANNOTATION_DETAILS,
  FETCH_ANNOTATION_DETAILS_SUCCESS,
  FETCH_ANNOTATION_DETAILS_FAILURE
} from "actions/annotationsActions";

import { FETCH_BOOK } from "actions/booksActions";

import { firestore } from "fire";
import { ModelsAnnotation } from "models";

const requestAnnotations = resourceInstanceId => {
  return firestore()
    .collection("annotations")
    .where("resourceInstanceId", "==", resourceInstanceId)
    .get()
    .then(querySnapshot => {
      const annotations = [];
      querySnapshot.forEach(doc => {
        if (doc.exists) {
          const annotationData = doc.data();
          const annotation = new ModelsAnnotation(
            doc.id,
            annotationData.resourceInstanceId,
            annotationData.bookId,
            annotationData.content,
            annotationData.dateCreated,
            annotationData.dateModified,
            annotationData.location,
            annotationData.amazonId,
            annotationData.pageNumber,
            annotationData.note,
            annotationData.type,
            annotationData.publicShare,
            annotationData.userId,
            annotationData.favorite
          );
          annotations.push(annotation);
        }
      });

      return annotations;
    });
};

function* fetchAnnotations(action) {
  try {
    const annotations = yield call(
      requestAnnotations,
      action.resourceInstanceId
    );

    yield put({
      type: FETCH_ANNOTATIONS_SUCCESS,
      annotations,
      resourceInstanceId: action.resourceInstanceId
    });
  } catch (e) {
    yield put({ type: FETCH_ANNOTATIONS_FAILURE, payload: e.message });
    console.log(e);
  }
}

function requestSharePublicLink(annotationId) {
  /* Sets the public link to shared */
  return firestore()
    .collection("annotations")
    .doc(annotationId)
    .update({
      publicShare: true
    });
}

function* sharePublicLink(action) {
  try {
    yield call(requestSharePublicLink, action.annotationId);
    yield put({
      type: SHARE_PUBLIC_LINK_SUCCESS,
      annotationId: action.annotationId
    });
  } catch (e) {
    yield put({ type: SHARE_PUBLIC_LINK_FAILURE, payload: e.message });
    console.log(e);
  }
}

function requestUnsharePublicLink(annotationId) {
  /* Sets the public link to not shared */
  return firestore()
    .collection("annotations")
    .doc(annotationId)
    .update({
      publicShare: false
    });
}

function* unsharePublicLink(action) {
  try {
    yield call(requestUnsharePublicLink, action.annotationId);
    yield put({
      type: UNSHARE_PUBLIC_LINK_SUCCESS,
      annotationId: action.annotationId
    });
  } catch (e) {
    yield put({ type: UNSHARE_PUBLIC_LINK_FAILURE, payload: e.message });
    console.log(e);
  }
}

function requestAddToFavorites(annotationId) {
  /* Sets the favorite to true */
  return firestore()
    .collection("annotations")
    .doc(annotationId)
    .update({
      favorite: true
    });
}

function* addToFavorites(action) {
  try {
    yield call(requestAddToFavorites, action.annotationId);
    yield put({
      type: ADD_TO_FAVORITES_SUCCESS,
      annotationId: action.annotationId
    });
  } catch (e) {
    yield put({ type: ADD_TO_FAVORITES_FAILURE, payload: e.message });
    console.log(e);
  }
}

function requestRemoveFromFavorites(annotationId) {
  /* Sets the favorite to true */
  return firestore()
    .collection("annotations")
    .doc(annotationId)
    .update({
      favorite: false
    });
}

function* removeFromFavorites(action) {
  try {
    yield call(requestRemoveFromFavorites, action.annotationId);
    yield put({
      type: REMOVE_FROM_FAVORITES_SUCCESS,
      annotationId: action.annotationId
    });
  } catch (e) {
    yield put({ type: REMOVE_FROM_FAVORITES_FAILURE, payload: e.message });
    console.log(e);
  }
}

function requestAnnotationDetails(annotationId) {
  return firestore()
    .collection("annotations")
    .doc(annotationId)
    .get()
    .then(doc => {
      if (doc.exists) {
        const annotationData = doc.data();
        return new ModelsAnnotation(
          doc.id,
          annotationData.resourceInstanceId,
          annotationData.bookId,
          annotationData.content,
          annotationData.dateCreated,
          annotationData.dateModified,
          annotationData.location,
          annotationData.amazonId,
          annotationData.pageNumber,
          annotationData.note,
          annotationData.type,
          annotationData.publicShare,
          annotationData.userId,
          annotationData.favorite
        );
      }
      return null;
    });
}

function* fetchAnnotationDetails(action) {
  try {
    const annotation = yield call(
      requestAnnotationDetails,
      action.annotationId
    );

    yield put({
      type: FETCH_ANNOTATION_DETAILS_SUCCESS,
      annotation,
      annotationId: action.annotationId
    });
    if (annotation) {
      yield put({
        type: FETCH_BOOK,
        resourceId: annotation.resourceInstanceId
      });
    }
  } catch (e) {
    yield put({ type: FETCH_ANNOTATION_DETAILS_FAILURE, message: e.message });
    console.log(e);
  }
}

export default function* annotationsSaga() {
  yield all([
    takeLatest(FETCH_ANNOTATIONS, fetchAnnotations),
    takeLatest(SHARE_PUBLIC_LINK, sharePublicLink),
    takeLatest(UNSHARE_PUBLIC_LINK, unsharePublicLink),
    takeLatest(ADD_TO_FAVORITES, addToFavorites),
    takeLatest(REMOVE_FROM_FAVORITES, removeFromFavorites),
    takeLatest(FETCH_ANNOTATION_DETAILS, fetchAnnotationDetails)
  ]);
}
