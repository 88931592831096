import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logOutUser as logOutUserAction } from "actions/authActions";
import { Menu, Responsive, Button, Icon } from "semantic-ui-react";
import GlobalSearch from "components/GlobalSearch";
import get from "lodash/get";
import "./TopNav.css";
import { selectBookById } from "reducers/books";
import { showCreateUsernameModal as showCreateUsernameModalAction } from "actions/uiActions";

class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: window.innerWidth > Responsive.onlyTablet.minWidth
    };
  }

  handleToggle = () => this.setState({ visible: !this.state.visible });

  handlePublicProfileClick = () => {
    const { user, showCreateUsernameModal } = this.props;
    const username = get(user, "username");
    if (username === null) {
      // if we have fetched username and it doesn't exist -- show modal
      showCreateUsernameModal();
    }
  };

  render() {
    const { visible } = this.state;
    const {
      showSidebarVisibilityHandler,
      user,
      pathname,
      logOutUser,
      bookInfo,
      showTitleInMenu,
      sidebarVisibilityHandler
    } = this.props;

    const username = get(user, "username");

    return (
      <Menu stackable fluid fixed="top">
        <Responsive
          as={Menu.Item}
          header
          content="Owl &amp; Scroll"
          minWidth={Responsive.onlyTablet.minWidth}
        />
        <Responsive {...Responsive.onlyMobile} as={Menu.Menu}>
          <Menu.Item>
            {showSidebarVisibilityHandler && (
              <Button
                className="toggle"
                icon
                onClick={sidebarVisibilityHandler}
                data-clickable="true"
                data-location="mobileMenu"
                data-action="toggleAnnotationSelector"
              >
                <Icon
                  name="options"
                  data-clickable="true"
                  data-location="mobileMenu"
                  data-action="toggleAnnotationSelector"
                />
              </Button>
            )}
            <div className="title"> Owl & Scroll</div>
            <Button
              className="drop"
              icon
              onClick={this.handleToggle}
              data-clickable="true"
              data-location="mobileMenu"
              data-action="toggleMobileDropdown"
            >
              <Icon
                name="sidebar"
                data-clickable="true"
                data-location="mobileMenu"
                data-action="toggleMobileDropdown"
              />
            </Button>
          </Menu.Item>
        </Responsive>

        <Menu.Item
          className={visible ? "showMenu" : "hideMenu"}
          name="My Annotations"
          as={Link}
          to={{ pathname: "/annotations" }}
          value="myAnnotations"
          active={pathname === "/annotations"}
          onClick={this.handleItemClick}
          data-clickable="true"
          data-location="topMenu"
          data-action="goToMyAnnotations"
          data-value="0"
        />
        {/* <Menu.Item */}
        {/*   className={visible ? "showMenu" : "hideMenu"} */}
        {/*   name="Social" */}
        {/*   as={Link} */}
        {/*   to={{ pathname: "/social" }} */}
        {/*   value="social" */}
        {/*   active={pathname === "/social"} */}
        {/*   onClick={this.handleItemClick} */}
        {/*   data-clickable="true" */}
        {/*   data-location="topMenu" */}
        {/*   data-action="goToSocial" */}
        {/*   data-value="1" */}
        {/* /> */}
        <Menu.Item
          className={visible ? "showMenu" : "hideMenu"}
          name="Import"
          as={Link}
          to={{ pathname: "/import" }}
          value="import"
          active={pathname === "/import"}
          onClick={this.handleItemClick}
          data-clickable="true"
          data-location="topMenu"
          data-action="goToImport"
          data-value="2"
        />
        <Menu.Item
          className={visible ? "showMenu" : "hideMenu"}
          name="Settings"
          as={Link}
          to={{ pathname: "/settings" }}
          value="settings"
          active={pathname === "/settings"}
          onClick={this.handleItemClick}
          data-clickable="true"
          data-location="topMenu"
          data-action="goToSettings"
          data-value="3"
        />
        <Menu.Item
          className={visible ? "showMenu" : "hideMenu"}
          name="Public Profile"
          as={Link}
          to={username ? { pathname: `/public/${username}` } : false}
          value="publicProfile"
          active={pathname === `/public/${username}`}
          onClick={this.handlePublicProfileClick}
          data-clickable="true"
          data-location="topMenu"
          data-action="goToPublicProfile"
          data-value="3"
        />
        {bookInfo &&
          showTitleInMenu && (
            <Responsive
              as={Menu.Item}
              header
              className="borderless menuBookTitle"
              content={bookInfo.title}
              minWidth={Responsive.onlyTablet.minWidth}
            />
          )}
        <Menu.Menu
          position="right"
          className={visible ? "showMenu" : "hideMenu"}
        >
          <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <Menu.Item>
              <GlobalSearch />
            </Menu.Item>
          </Responsive>
          {user && (
            <Menu.Item
              onClick={logOutUser}
              data-clickable="true"
              data-location="topMenu"
              data-action="logOut"
            >
              Log out
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
    );
  }
}

function mapStateToProps(state) {
  const selectedBook = selectBookById(state, state.books.selectedBookId);
  return {
    bookInfo: selectedBook,
    user: state.auth.user,
    showTitleInMenu: state.ui.showTitleInMenu
  };
}

export default connect(
  mapStateToProps,
  {
    logOutUser: logOutUserAction,
    showCreateUsernameModal: showCreateUsernameModalAction
  }
)(TopNav);
