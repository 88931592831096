import lunr from "lunr";
import reduce from "lodash/reduce";

const searchAnnotations = (annotations, filterText) => {
  const idx = lunr(function() {
    // Remove stemmer from lunr pipeline
    this.pipeline.remove(lunr.stemmer);
    this.searchPipeline.remove(lunr.stemmer);
    this.ref("id");
    this.field("content");

    annotations.forEach(function(doc) {
      this.add(doc);
    }, this);
  });
  const filteredAnnotationRefs = idx.search(`*${filterText}*`);
  const annotationsDict = reduce(
    annotations,
    (result, val) => {
      result[val.id] = val;
      return result;
    },
    {}
  );
  let filteredAnnotations = filteredAnnotationRefs.map(annotationRef => {
    return annotationsDict[annotationRef.ref];
  });
  return filteredAnnotations.sort((a, b) => (a.location > b.location ? 1 : -1));
};

export default searchAnnotations;
