import React, { Component } from "react";
import { Button, Form, Message, Loader } from "semantic-ui-react";

class AnnotationAddNote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      noteContent: "",
      noteError: false
    };
  }
  _addNote = () => {
    this.props.addNote(
      this.props.annotationId,
      this.state.noteContent,
      "custom"
    );
    this.setState({ noteContent: "", noteError: false });
    this.props.toggleAddingNote();
  };

  _handleChange(e) {
    let noteError = false;
    if (e.target.value.length > 500) {
      noteError = true;
    }
    this.setState({ noteContent: e.target.value, noteError });
  }

  render() {
    const {
      isLoading,
      selectedAnnotation,
      annotationId,
      addingNote
    } = this.props;
    const { noteError } = this.state;
    return (
      <div>
        {isLoading && selectedAnnotation === annotationId ? (
          <Loader active />
        ) : addingNote ? (
          <Form onSubmit={this._addNote} error={noteError}>
            <Form.TextArea
              label="New Note"
              onChange={e => this._handleChange(e)}
              placeholder="Add your note here..."
            />
            <Message error content="Note must be less than 500 characters." />
            <Form.Group>
              <Form.Button
                disabled={noteError}
                data-clickable="true"
                data-location="addNoteForm"
                data-action="submit"
              >
                Submit
              </Form.Button>
              <Button
                content="Cancel"
                onClick={this.props.toggleAddingNote}
                data-clickable="true"
                data-location="addNoteForm"
                data-action="cancel"
              />
            </Form.Group>
          </Form>
        ) : null}
      </div>
    );
  }
}

export default AnnotationAddNote;
