// eslint-disable-next-line no-console
console.log(`NODE_ENV=${process.env.NODE_ENV}`);
// eslint-disable-next-line no-console
console.log(`REACT_APP_ENV=${process.env.REACT_APP_ENV}`);

let apiKey;
let authDomain;
let databaseURL;
let projectId;
let storageBucket;
let messagingSenderId;
let apiUrl;
let hostUrl;

switch (process.env.REACT_APP_ENV) {
  case "production":
    apiKey = process.env.REACT_APP_FIREBASE_PROD_API_KEY;
    authDomain = process.env.REACT_APP_FIREBASE_PROD_AUTH_DOMAIN;
    databaseURL = process.env.REACT_APP_FIREBASE_PROD_DB_URL;
    projectId = process.env.REACT_APP_FIREBASE_PROD_PROJECT_ID;
    storageBucket = process.env.REACT_APP_FIREBASE_PROD_STORAGE_BUCKET;
    messagingSenderId = process.env.REACT_APP_FIREBASE_PROD_SENDER_ID;
    apiUrl = process.env.REACT_APP_FIREBASE_PROD_API_URL;
    hostUrl = process.env.REACT_APP_FIREBASE_PROD_HOST_URL;
    break;
  case "development":
    apiKey = process.env.REACT_APP_FIREBASE_DEV_API_KEY;
    authDomain = process.env.REACT_APP_FIREBASE_DEV_AUTH_DOMAIN;
    databaseURL = process.env.REACT_APP_FIREBASE_DEV_DB_URL;
    projectId = process.env.REACT_APP_FIREBASE_DEV_PROJECT_ID;
    storageBucket = process.env.REACT_APP_FIREBASE_DEV_STORAGE_BUCKET;
    messagingSenderId = process.env.REACT_APP_FIREBASE_DEV_SENDER_ID;
    apiUrl = process.env.REACT_APP_FIREBASE_DEV_API_URL;
    hostUrl = process.env.REACT_APP_FIREBASE_DEV_HOST_URL;
    break;
  default:
    apiKey = process.env.REACT_APP_FIREBASE_DEV_API_KEY;
    authDomain = process.env.REACT_APP_FIREBASE_DEV_AUTH_DOMAIN;
    databaseURL = process.env.REACT_APP_FIREBASE_DEV_DB_URL;
    projectId = process.env.REACT_APP_FIREBASE_DEV_PROJECT_ID;
    storageBucket = process.env.REACT_APP_FIREBASE_DEV_STORAGE_BUCKET;
    messagingSenderId = process.env.REACT_APP_FIREBASE_DEV_SENDER_ID;
    apiUrl = process.env.REACT_APP_FIREBASE_LOCAL_API_URL;
    hostUrl = process.env.REACT_APP_FIREBASE_LOCAL_HOST_URL;
    break;
}

const config = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  apiUrl,
  hostUrl
};

export default config;
