class ModelsBook {
  constructor(
    resourceInstanceId,
    bookId,
    ownerId,
    ownerName,
    ASIN,
    author,
    dateCreated,
    dateModified,
    title,
    url,
    imgUrl,
    isShared,
    publicShare,
    username,
    annotationsCount
  ) {
    this.id = resourceInstanceId;
    this.bookId = bookId;
    this.ownerId = ownerId;
    this.ownerName = ownerName;
    this.ASIN = ASIN;
    this.author = author;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.title = title;
    this.url = url;
    this.imgUrl = imgUrl;
    this.isShared = isShared;
    this.publicShare = publicShare;
    this.username = username;
    this.annotationsCount = annotationsCount;
  }
}

export default ModelsBook;
