import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Form, Input, Button } from "semantic-ui-react";
import UsernameErrorMessage from "modules/settings/components/UsernameErrorMessage";
import UsernamePopup from "modules/settings/components/UsernamePopup";
import "./SettingsForm.css";

class SettingsForm extends Component {
  static propTypes = {
    username: PropTypes.string,
    userId: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    updateUsername: PropTypes.func.isRequired
  };

  static defaultProps = {
    username: ""
  };

  constructor(props) {
    super(props);
    const { username, email } = props;
    this.state = {
      isEditingUsername: false,
      isEditingEmail: false,
      username,
      email
    };
  }

  componentDidUpdate(prevProps) {
    const { username } = this.props;
    if (username !== null && username !== prevProps.username) {
      this.setState({ username });
    }
  }

  handleEditClick = () => this.setState({ isEditingUsername: true });

  handleSaveClick = () => {
    const { updateUsername, userId, username: previousUsername } = this.props;
    const { username } = this.state;
    this.setState({ isEditingUsername: false });
    updateUsername(userId, username, previousUsername);
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  render() {
    const { isEditingUsername, isEditingEmail, username, email } = this.state;
    const isAlphaNumeric = username && username.match(/^[a-z0-9]+$/i) !== null;
    const isLessThan15Characters = username && username.length < 15;
    const isUsernameError = !isAlphaNumeric || !isLessThan15Characters;
    const saveDisabled = isUsernameError || username == null || username === "";
    const saveButton = (
      <Button
        positive
        disabled={saveDisabled}
        onClick={this.handleSaveClick}
        data-clickable="true"
        data-location="settingsForm"
        data-action="createUsername"
      >
        Save
      </Button>
    );
    const editButton = (
      <Button
        onClick={this.handleEditClick}
        data-clickable="true"
        data-location="settingsForm"
        data-action="editUsername"
      >
        Edit
      </Button>
    );

    return (
      <div className="settingsCanvas">
        <Card centered fluid>
          <Card.Content>
            <Card.Header textAlign="center">Settings</Card.Header>
            <Form
              style={{ marginTop: "15px" }}
              error={username === "" ? false : isUsernameError}
            >
              <Form.Group inline>
                <Form.Field>
                  <label htmlFor="email" style={{ width: "100px" }}>
                    Email
                  </label>
                  <Input
                    disabled={!isEditingEmail}
                    id="email"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field error={isUsernameError}>
                  <label htmlFor="username" style={{ width: "100px" }}>
                    <UsernamePopup
                      username={username}
                      isUsernameError={isUsernameError}
                    />
                    User Name
                  </label>
                  <Input
                    disabled={!isEditingUsername}
                    name="username"
                    id="username"
                    value={username}
                    onChange={this.handleChange}
                  />
                </Form.Field>
                {isEditingUsername ? saveButton : editButton}
              </Form.Group>
              {username !== null && <UsernameErrorMessage />}
            </Form>
          </Card.Content>
        </Card>
      </div>
    );
  }
}

export default SettingsForm;
