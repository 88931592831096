import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Image } from "semantic-ui-react";
import { ModelsBook } from "models";

const EvernoteFinishedDescription = ({
  bookInstance,
  displayError,
  displayMessage,
  loading,
  successfulSaves,
  failedSaves,
  maxRateWait,
  toggleModal,
  exportIndividually
}) => (
  <React.Fragment>
    <Modal.Content image scrolling>
      <Image wrapped size="small" bordered src={bookInstance.imgUrl} />
      <Modal.Description>
        <div>Export Finished</div>
        <div
          style={
            displayError ? { color: "red" } : { color: "rgba(0, 0, 0, 0.6)" }
          }
        >
          <div>
            {exportIndividually && (
              <span>
                We have finsihed saving your {successfulSaves}&nbsp;
                {successfulSaves > 1 ? "annotations" : "annotation"}&nbsp; to an
                Evernote notebook named <i>{displayMessage}</i>.
              </span>
            )}
            {exportIndividually === false && (
              <span>
                We have finsihed saving your {successfulSaves}&nbsp;
                {successfulSaves > 1 ? "annotations" : "annotation"}&nbsp; to an
                Evernote note named <i>{displayMessage}</i> in the notebook Owl
                & Scoll.
              </span>
            )}
          </div>
          <div>
            Check your&nbsp;
            <a
              href="https://evernote.com/"
              target="_blank"
              rel="noopener noreferrer"
              data-clickable="true"
              data-location="evernoteExportModal"
              data-action="openEvernote"
              data-label={bookInstance.id}
            >
              evernote account
            </a>
            &nbsp;to see the saved annotations.
          </div>
          {failedSaves.length > 0 && (
            <div style={{ color: "red" }}>
              <div>
                However {failedSaves.length}{" "}
                {failedSaves.length > 1 ? "annotations" : "annotation"} did not
                save due to Evernote&apos;s draconian export limits. Here{" "}
                {failedSaves.length > 1
                  ? "are the annotations"
                  : "is the annotation"}{" "}
                that did not export.
              </div>
              {failedSaves.map((failedSave, index) => {
                return (
                  <div key={index}>
                    Location - {failedSave.annotationData.location}
                  </div>
                );
              })}
              <br />
              <div>
                Wait {maxRateWait} seconds before exporting your next book.
              </div>
              <div>
                You can create these notes manually in Evernote, try exporting
                again, or commit to using Owl &amp; Scroll to manage your
                annotations and never have to deal with Evernote again.
              </div>
            </div>
          )}
        </div>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button
        basic
        disabled={loading}
        color="green"
        compact
        size="tiny"
        onClick={toggleModal}
        data-clickable="true"
        data-location="evernoteExportModal"
        data-action="doneSaveToEvernote"
        data-label={bookInstance.id}
      >
        {loading ? "Saving..." : "Done"}
      </Button>
    </Modal.Actions>
  </React.Fragment>
);

EvernoteFinishedDescription.propTypes = {
  bookInstance: PropTypes.instanceOf(ModelsBook).isRequired,
  displayError: PropTypes.bool.isRequired,
  displayMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  successfulSaves: PropTypes.number.isRequired,
  failedSaves: PropTypes.array.isRequired,
  maxRateWait: PropTypes.number.isRequired,
  toggleModal: PropTypes.func.isRequired,
  exportIndividually: PropTypes.bool.isRequired
};

export default EvernoteFinishedDescription;
