import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchAnnotations as fetchAnnotationsAction } from "actions/annotationsActions";
import { selectAnnotationListByBook } from "reducers/annotations";
import { selectBookById } from "reducers/books";
import { selectNotesList } from "reducers/notes";
import AnnotationList from "modules/annotations/components/annotationList/AnnotationList";
import PublicBookUnavailable from "modules/annotations/components/PublicBookUnavailable";
import searchAnnotations from "utils/searchAnnotations";
import PropTypes from "prop-types";
import { ModelsNote, ModelsBook, ModelsUser, ModelsAnnotation } from "models";
import withLoading from "hoc/withLoading";
import { fetchBook as fetchBookAction } from "actions/booksActions";
import get from "lodash/get";

const AnnotationListWithLoading = withLoading(AnnotationList);

class PublicBook extends Component {
  static propTypes = {
    selectedBook: PropTypes.instanceOf(ModelsBook),
    filteredAnnotations: PropTypes.arrayOf(
      PropTypes.instanceOf(ModelsAnnotation)
    ).isRequired,
    user: PropTypes.instanceOf(ModelsUser),
    notes: PropTypes.arrayOf(PropTypes.instanceOf(ModelsNote)).isRequired,
    isFetching: PropTypes.bool.isRequired,
    fetchAnnotations: PropTypes.func.isRequired
  };

  static defaultProps = {
    selectedBook: null,
    user: null
  };

  componentDidMount() {
    const { fetchAnnotations, bookId, fetchBook } = this.props;
    fetchBook(bookId);
    fetchAnnotations(bookId);
  }

  render() {
    const {
      filteredAnnotations,
      selectedBook,
      isFetching,
      user,
      notes
    } = this.props;
    const userId = get(user, "id");
    const isPubliclyShared = get(selectedBook, "publicShare");
    const showDefault =
      !isPubliclyShared &&
      userId !== get(selectedBook, "ownerId") &&
      isFetching === false;

    return showDefault ? (
      <PublicBookUnavailable />
    ) : (
      <AnnotationListWithLoading
        book={selectedBook}
        annotations={filteredAnnotations}
        isFetching={isFetching}
        user={user}
        customNotes={notes}
        canSaveToEvernote={false}
        type="public"
      />
    );
  }
}

function mapStateToProps(state, props) {
  const bookId = props.match.params.resourceInstanceId;
  const annotations = selectAnnotationListByBook(state, bookId);
  const selectedBook = selectBookById(state, bookId);
  return {
    selectedBook,
    user: state.auth.user,
    filteredAnnotations: searchAnnotations(annotations, state.ui.filterText),
    isFetching: state.annotations.isFetching || state.books.isFetching,
    notes: selectNotesList(state),
    bookId: props.match.params.resourceInstanceId
  };
}

export default connect(
  mapStateToProps,
  {
    fetchAnnotations: fetchAnnotationsAction,
    fetchBook: fetchBookAction
  }
)(PublicBook);
