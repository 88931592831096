import React from "react";
import { Link } from "react-router-dom";
import { Segment, Header } from "semantic-ui-react";

const PublicProfilePlaceholder = () => (
  <Segment basic>
    <Header icon>
      No Public Books? Select a book from
      <Link to="/annotations">
        <strong> Annotations </strong>
      </Link>
      and click "Share Book Publicly".
    </Header>
  </Segment>
);

export default PublicProfilePlaceholder;
