import React, { Component } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { Container } from "semantic-ui-react";
import TopNav from "modules/topNav/TopNav";
import "./DefaultNoSideNav.css";
import PropTypes from "prop-types";
import ImportedBookModal from "modules/import/ImportedBookModal";
import AddUsernameModalContainer from "modules/settings/containers/AddUsernameModalContainer";
import withFullStoryIdentity from "hoc/withFullStoryIdentity";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class LayoutsDefaultNoSideNav extends Component {
  render() {
    const { location, children, showImportWindow } = this.props;
    const pathname = get(location, "pathname");

    return (
      <div>
        <Container className="TopMenu" fluid>
          <TopNav className="TopMenu" pathname={pathname} />
        </Container>
        {children}
        <ToastContainer />
        <ImportedBookModal open={showImportWindow} />
        <AddUsernameModalContainer />
      </div>
    );
  }
}

LayoutsDefaultNoSideNav.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  showImportWindow: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  showImportWindow: state.ui.showImportWindow
});

export default connect(
  mapStateToProps,
  {}
)(withFullStoryIdentity(LayoutsDefaultNoSideNav));
