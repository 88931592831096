import React, { Component } from "react";
import { Form, Message, Segment } from "semantic-ui-react";
import { auth } from "fire";
/**
 * @name ResetPassword
 * @description Reset password
 */
export default class ResetPassword extends Component {
  state = {
    email: "",
    hasError: false,
    errorHeader: undefined,
    errorContent: undefined,
    loading: false,
    hasSentInstructions: false
  };

  handleChange = event =>
    this.setState({ [event.target.name]: event.target.value });

  submit = () => {
    const { email } = this.state;
    const nodeEnv = process.env.NODE_ENV;
    const redirectUrl =
      nodeEnv === "development"
        ? "https://dev.owlandscroll.com/login?login=true"
        : "https://app.owlandscroll.com/login?login=true";

    this.setState({ loading: true, hasError: false });

    return auth()
      .sendPasswordResetEmail(email.trim(), {
        url: redirectUrl
      })
      .then(() => {
        this.setState({ loading: false, hasSentInstructions: true });
      })
      .catch(err => {
        this.setState({
          loading: false,
          hasError: true,
          errorHeader: err.code,
          errorContent: err.message
        });
        console.log(err);
      });
  };

  render() {
    const {
      email,
      loading,
      hasError,
      errorHeader,
      errorContent,
      hasSentInstructions
    } = this.state;

    return (
      <React.Fragment>
        {!hasSentInstructions && "Reset Password"}
        {!hasSentInstructions ? (
          <Segment stacked>
            <Form loading={loading} error={hasError} onSubmit={this.submit}>
              <Message error header={errorHeader} content={errorContent} />
              <Form.Input
                name="email"
                value={email}
                onChange={this.handleChange}
                type="email"
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-mail address"
              />
              <Form.Button fluid color="blue">
                Send Reset Instructions
              </Form.Button>
            </Form>
          </Segment>
        ) : (
          "Password reset email sent successfully!"
        )}
      </React.Fragment>
    );
  }
}
