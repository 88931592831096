import React from "react";
import PropTypes from "prop-types";
import Loading from "components/Loading";
import { Table } from "semantic-ui-react";

const withLoading = Component => {
  const wrappedComponent = ({ isFetching, isTable, ...props }) => {
    if (!isFetching) return <Component {...props} />;
    if (isTable) {
      return (
        <Table.Row>
          <Loading />
        </Table.Row>
      );
    }
    return <Loading />;
  };

  wrappedComponent.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    isTable: PropTypes.bool
  };

  wrappedComponent.defaultProps = {
    isTable: false
  };

  return wrappedComponent;
};

withLoading.PropTypes = {
  Component: PropTypes.element.isRequired
};

export default withLoading;
