import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectBooksList } from "reducers/books";
import BookList from "modules/annotations/components/bookList/BookList";
import BooksMenu from "modules/annotations/components/booksMenu/BooksMenu";
import {
  setSelectedBook as setSelectedBookAction,
  fetchBooks as fetchBooksAction
} from "actions/booksActions";
import { ModelsBook } from "models";
import withLoading from "hoc/withLoading";
import { Button } from "semantic-ui-react";

const BookListWithLoading = withLoading(BookList);

class BooksSelector extends Component {
  static propTypes = {
    selectedBookId: PropTypes.string,
    books: PropTypes.arrayOf(PropTypes.instanceOf(ModelsBook)).isRequired,
    isFetching: PropTypes.bool.isRequired,
    setSelectedBook: PropTypes.func.isRequired,
    fetchBooks: PropTypes.func.isRequired
  };

  static defaultProps = {
    selectedBookId: ""
  };

  componentDidMount() {
    const { fetchBooks } = this.props;
    fetchBooks();
  }

  render() {
    const { isFetching, selectedBookId, books, setSelectedBook } = this.props;

    return (
      <BookListWithLoading
        activeItem={selectedBookId}
        books={books}
        handleItemClick={(e, { value }) => {
          setSelectedBook(value);
        }}
        isFetching={isFetching}
        title="My Books"
      >
        <BooksMenu
          books={books}
          selectedBookId={selectedBookId}
          setSelectedBook={setSelectedBook}
        />
        <div style={{ textAlign: "center" }}>
          <Link to={{ pathname: "import" }}>
            <Button positive>Import Books!</Button>{" "}
          </Link>
        </div>
      </BookListWithLoading>
    );
  }
}

const mapStateToProps = state => ({
  books: selectBooksList(state),
  selectedBookId: state.books.selectedBookId,
  isFetching: state.books.isFetching
});

export default connect(
  mapStateToProps,
  { setSelectedBook: setSelectedBookAction, fetchBooks: fetchBooksAction }
)(BooksSelector);
