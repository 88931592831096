import { ModelsUser } from "models";
import toLower from "lodash/toLower";

export const SET_USER = "SET_USER";
export const setUser = (userId, email) => ({
  type: SET_USER,
  user: new ModelsUser(userId, email)
});

export const SET_NO_USER = "SET_NO_USER";
export const setNoUser = () => ({
  type: SET_NO_USER
});

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE";
export const logOutUser = () => ({
  type: LOGOUT_USER
});

export const UPDATE_USERNAME = "UPDATE_USERNAME";
export const UPDATE_USERNAME_SUCCESS = "UPDATE_USERNAME_SUCCESS";
export const UPDATE_USERNAME_FAILURE = "UPDATE_USERNAME_FAILURE";
export const updateUsername = (userId, username, previousUsername) => ({
  type: UPDATE_USERNAME,
  username: toLower(username),
  userId,
  previousUsername
});

export const GET_USERNAME = "GET_USERNAME";
export const GET_USERNAME_SUCCESS = "GET_USERNAME_SUCCESS";
export const GET_USERNAME_FAILURE = "GET_USERNAME_FAILURE";
export const getUsername = userId => ({
  type: GET_USERNAME,
  userId
});

export const FOLLOW_USER = "FOLLOW_USER";
export const FOLLOW_USER_SUCCESS = "FOLLOW_USER_SUCCESS";
export const FOLLOW_USER_FAILURE = "FOLLOW_USER_FAILURE";
export const followUser = username => ({
  type: FOLLOW_USER,
  username: toLower(username)
});

export const UNFOLLOW_USER = "UNFOLLOW_USER";
export const UNFOLLOW_USER_SUCCESS = "UNFOLLOW_USER_SUCCESS";
export const UNFOLLOW_USER_FAILURE = "UNFOLLOW_USER_FAILURE";
export const unfollowUser = username => ({
  type: UNFOLLOW_USER,
  username: toLower(username)
});

export const GET_FOLLOWED = "GET_FOLLOWED";
export const GET_FOLLOWED_SUCCESS = "GET_FOLLOWED_SUCCESS";
export const GET_FOLLOWED_FAILURE = "GET_FOLLOWED_FAILURE";
export const getFollowed = username => ({
  type: GET_FOLLOWED,
  username
});

export const GET_FOLLOWERS = "GET_FOLLOWERS";
export const GET_FOLLOWERS_SUCCESS = "GET_FOLLOWERS_SUCCESS";
export const GET_FOLLOWERS_FAILURE = "GET_FOLLOWERS_FAILURE";
export const getFollowers = username => ({
  type: GET_FOLLOWERS,
  username
});

export const GET_STATS = "GET_STATS";
export const GET_STATS_SUCCESS = "GET_STATS_SUCCESS";
export const GET_STATS_FAILURE = "GET_STATS_FAILURE";
export const getStats = argObj => ({
  type: GET_STATS,
  username: argObj.username,
  userId: argObj.userId
});

export const GET_USER_ID = "GET_USER_ID";
export const GET_USER_ID_SUCCESS = "GET_USER_ID_SUCCESS";
export const GET_USER_ID_FAILURE = "GET_USER_ID_FAILURE";
export const getUserId = username => ({
  type: GET_USER_ID,
  username: toLower(username)
});

export const GET_USERNAME_BY_USER_ID = "GET_USERNAME_BY_USER_ID";
export const GET_USERNAME_BY_USER_ID_SUCCESS =
  "GET_USERNAME_BY_USER_ID_SUCCESS";
export const GET_USERNAME_BY_USER_ID_FAILURE =
  "GET_USERNAME_BY_USER_ID_FAILURE";
export const getUsernameByUserId = userId => ({
  type: GET_USERNAME_BY_USER_ID,
  userId
});
