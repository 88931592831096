import React, { Component } from "react";
import { Card, Icon, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import truncate from "lodash/truncate";
import {
  sharePublicLink as sharePublicLinkAction,
  unsharePublicLink as unsharePublicLinkAction,
  addToFavorites as addToFavoritesAction,
  removeFromFavorites as removeFromFavoritesAction
} from "actions/annotationsActions";
import "./Toolbar.css";
import { TwitterShareButton, FacebookShareButton } from "react-share";

class AnnotationToolbar extends Component {
  static propTypes = {
    bookId: PropTypes.string.isRequired,
    annotationId: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    shared: PropTypes.bool.isRequired,
    fullPage: PropTypes.bool.isRequired,
    ASIN: PropTypes.string.isRequired,
    location: PropTypes.number.isRequired,
    toggleAddingNote: PropTypes.func.isRequired,
    showNote: PropTypes.bool,
    handleKindleLink: PropTypes.func.isRequired,
    publicShare: PropTypes.bool,
    sharePublicLink: PropTypes.func.isRequired,
    unsharePublicLink: PropTypes.func.isRequired,
    favorite: PropTypes.bool,
    addToFavorites: PropTypes.func.isRequired,
    removeFromFavorites: PropTypes.func.isRequired,
    content: PropTypes.string.isRequired
  };

  static defaultProps = {
    showNote: true,
    publicShare: false,
    favorite: false
  };

  handleSharePublicLink = (e, { value }) => {
    const { annotationId, sharePublicLink } = this.props;
    sharePublicLink(value);
    window.open(`/annotation/${annotationId}`, "_blank");
  };

  handleUnsharePublicLink = (e, { value }) => {
    const { unsharePublicLink } = this.props;
    unsharePublicLink(value);
  };

  handleFavorite = () => {
    const { annotationId, addToFavorites } = this.props;
    addToFavorites(annotationId);
  };

  handleUnfavorite = () => {
    const { annotationId, removeFromFavorites } = this.props;
    removeFromFavorites(annotationId);
  };

  render() {
    const {
      annotationId,
      order,
      shared,
      fullPage,
      bookId,
      ASIN,
      location,
      toggleAddingNote,
      showNote,
      handleKindleLink,
      publicShare,
      content
    } = this.props;

    const sharePublicLinkButton = (
      <div>
        <Button
          className="buttonLink"
          value={annotationId}
          onClick={this.handleSharePublicLink}
          data-clickable="true"
          data-location="annotationToolbar"
          data-action="sharePublicLink"
          data-label={annotationId}
          data-value={order}
        >
          <Icon
            name="share"
            data-clickable="true"
            data-location="annotationToolbar"
            data-action="sharePublicLink"
            data-label={annotationId}
            data-value={order}
          />
          Create Public Link
        </Button>
      </div>
    );
    const unsharePublicLinkButton = (
      <div>
        <Button
          className="buttonLink"
          value={annotationId}
          onClick={this.handleUnsharePublicLink}
          data-clickable="true"
          data-location="annotationToolbar"
          data-action="unsharePublicLink"
          data-label={annotationId}
          data-value={order}
        >
          <Icon
            name="user cancel"
            data-clickable="true"
            data-location="annotationToolbar"
            data-action="unsharePublicLink"
            data-label={annotationId}
            data-value={order}
          />
          Remove Public Link
        </Button>
      </div>
    );

    return (
      <Card.Content extra className="annotationToolbar">
        <div>
          {/* {!shared && ( */}
          {/*   <div> */}
          {/*     <Button */}
          {/*       className="buttonLink" */}
          {/*       value={annotationId} */}
          {/*       onClick={favorite ? this.handleUnfavorite : this.handleFavorite} */}
          {/*       data-clickable="true" */}
          {/*       data-location="annotationToolbar" */}
          {/*       data-action="sharePublicLink" */}
          {/*       data-label={annotationId} */}
          {/*       data-value={order} */}
          {/*     > */}
          {/*       <Icon */}
          {/*         name={favorite ? "star" : "star outline"} */}
          {/*         data-clickable="true" */}
          {/*         data-location="annotationToolbar" */}
          {/*         data-action="favorite" */}
          {/*         data-label={annotationId} */}
          {/*         data-value={order} */}
          {/*       /> */}
          {/*       {favorite ? "Remove from Favorites" : "Add to Favorites"} */}
          {/*     </Button> */}
          {/*   </div> */}
          {/* )} */}
          {shared && (
            <div>
              <a
                href={`https://www.amazon.com/dp/${ASIN}/?tag=kevinmclaughl-20`}
                target="_blank"
                rel="noopener noreferrer"
                data-clickable="true"
                data-location="annotationToolbar"
                data-action="buyOnAmazon"
                data-label={bookId}
                data-value={order}
              >
                <Icon
                  name="plus"
                  data-clickable="true"
                  data-location="annotationToolbar"
                  data-action="buyOnAmazon"
                  data-label={bookId}
                  data-value={order}
                />
                Buy Book
              </a>
            </div>
          )}
          <div>
            <a
              href={`kindle://book?action=open&asin=${ASIN}&location=${location}`}
              onClick={handleKindleLink}
              data-clickable="true"
              data-location="annotationToolbar"
              data-action="showInKindle"
              data-label={annotationId}
              data-value={order}
            >
              <Icon
                name="book"
                data-clickable="true"
                data-location="annotationToolbar"
                data-action="showInKindle"
                data-label={annotationId}
                data-value={order}
              />
              Open in Kindle
            </a>
          </div>
          {showNote && (
            <div>
              <Button
                className="buttonLink"
                onClick={toggleAddingNote}
                data-clickable="true"
                data-location="annotationToolbar"
                data-action="addNote"
                data-label={annotationId}
                data-value={order}
              >
                <Icon
                  name="plus"
                  data-clickable="true"
                  data-location="annotationToolbar"
                  data-action="addNote"
                  data-label={annotationId}
                  data-value={order}
                />
                Add Note
              </Button>
            </div>
          )}
          {shared === false &&
            (publicShare ? unsharePublicLinkButton : sharePublicLinkButton)}
          {fullPage === false &&
            (shared === false || publicShare) && (
              <div>
                <a
                  href={`/annotation/${annotationId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-clickable="true"
                  data-location="annotationToolbar"
                  data-action="openFullPage"
                  data-label={annotationId}
                  data-value={order}
                >
                  <Icon
                    name="external"
                    data-clickable="true"
                    data-location="annotationToolbar"
                    data-action="openFullPage"
                    data-label={annotationId}
                    data-value={order}
                  />
                  Open Full Page
                </a>
              </div>
            )}
        </div>
        {publicShare && (
          <div>
            <TwitterShareButton
              url={`${window.location.origin}/annotation/${annotationId}`}
              title={truncate(content, { length: 220 })}
              via="owlandscroll"
            >
              <Button
                className="buttonLink"
                data-clickable="true"
                data-location="annotationToolbar"
                data-action="shareTwitter"
                data-label={annotationId}
                data-value={order}
              >
                <Icon
                  name="twitter"
                  data-clickable="true"
                  data-location="annotationToolbar"
                  data-action="shareTwitter"
                  data-label={annotationId}
                  data-value={order}
                />
                Tweet
              </Button>
            </TwitterShareButton>
            <FacebookShareButton
              url={`${window.location.origin}/annotation/${annotationId}`}
              quote={content}
              hashtag="#owlandscroll"
            >
              <Button
                className="buttonLink"
                data-clickable="true"
                data-location="annotationToolbar"
                data-action="shareFacebook"
                data-label={annotationId}
                data-value={order}
              >
                <Icon
                  name="facebook"
                  data-clickable="true"
                  data-location="annotationToolbar"
                  data-action="shareFacebook"
                  data-label={annotationId}
                  data-value={order}
                />
                Share
              </Button>
            </FacebookShareButton>
          </div>
        )}
      </Card.Content>
    );
  }
}

export default connect(
  null,
  {
    sharePublicLink: sharePublicLinkAction,
    unsharePublicLink: unsharePublicLinkAction,
    addToFavorites: addToFavoritesAction,
    removeFromFavorites: removeFromFavoritesAction
  }
)(AnnotationToolbar);
