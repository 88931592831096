import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const FollowButton = ({
  isFollowingUser,
  isLoggedIn,
  followUser,
  unfollowUser,
  username
}) => {
  const followButton = (
    <Button
      basic={!isFollowingUser}
      as="div"
      style={{ marginLeft: "10px", verticalAlign: "text-top" }}
      color="blue"
      onClick={() =>
        isFollowingUser ? unfollowUser(username) : followUser(username)
      }
      size="mini"
      data-clickable="true"
      data-location="publicProfile"
      data-action={isFollowingUser ? "Following" : "Follow"}
      data-label={username}
    >
      {isFollowingUser ? "Following" : "Follow"}
    </Button>
  );
  return isLoggedIn ? followButton : <Link to="/login">{followButton}</Link>;
};

FollowButton.propTypes = {
  isFollowingUser: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  followUser: PropTypes.func.isRequired,
  unfollowUser: PropTypes.func.isRequired
};

export default FollowButton;
