import React from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "semantic-ui-react";
import OpenPublicLinkButton from "modules/annotations/components/annotationListHeader/OpenPublicLinkButton";

const PublicLinkButtons = ({
  bookId,
  publicShare,
  togglePublicBook,
  handleSharePublicBook
}) => (
  <React.Fragment>
    {publicShare === true ? (
      <React.Fragment>
        <Button
          className="shareButton"
          icon
          labelPosition="left"
          basic
          color="blue"
          compact
          size="tiny"
          onClick={() => togglePublicBook(bookId, false)}
          data-clickable="true"
          data-location="bookTable"
          data-action="removePublicLink"
          data-label={bookId}
        >
          Remove Public Link
          <Icon
            name="user cancel"
            data-clickable="true"
            data-location="bookTable"
            data-action="share"
            data-label={bookId}
          />
        </Button>
        <OpenPublicLinkButton bookId={bookId} />
      </React.Fragment>
    ) : (
      <Button
        className="shareButton"
        icon
        labelPosition="left"
        basic
        color="blue"
        compact
        size="tiny"
        onClick={handleSharePublicBook}
        data-clickable="true"
        data-location="bookTable"
        data-action="share"
        data-label={bookId}
      >
        Share Book Publicly
        <Icon
          name="share"
          data-clickable="true"
          data-location="bookTable"
          data-action="share"
          data-label={bookId}
        />
      </Button>
    )}
  </React.Fragment>
);

PublicLinkButtons.propTypes = {
  bookId: PropTypes.string.isRequired,
  publicShare: PropTypes.bool.isRequired,
  togglePublicBook: PropTypes.func.isRequired
};

export default PublicLinkButtons;
