import React from "react";
import PropTypes from "prop-types";
import { Grid, Header, Image, Message, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import logo from "images/logo-transparent.png";
import GoogleButton from "react-google-button";
import "./Login.css";
import CreateAccount from "modules/login/CreateAccount";
import EmailLogin from "modules/login/EmailLogin";

/**
 * Presentational component for rendering the login form.
 */
const LoginForm = props => {
  const {
    loginWithGoogle,
    createAccountByEmailMode,
    toggleCreateAccountByEmailMode,
    saveUser
  } = props;

  const helpMessage = createAccountByEmailMode
    ? "Already have an account?"
    : "New to us?";

  const buttonMessage = createAccountByEmailMode ? "Login" : "Sign Up";

  return (
    <div className="login-form">
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="blue" textAlign="center">
            <Image src={logo} /> Owl & Scroll
          </Header>
          <GoogleButton
            className="loginWithGoogle"
            type="light"
            onClick={loginWithGoogle}
            data-clickable="true"
            data-location="loginModule"
            data-action="loginWithGoogle"
          />
          <Divider horizontal>Or</Divider>
          {createAccountByEmailMode ? (
            <CreateAccount saveUser={saveUser} />
          ) : (
            <EmailLogin />
          )}
          <Message>
            {helpMessage}{" "}
            <span
              role="button"
              tabIndex="-1"
              className="link"
              onClick={toggleCreateAccountByEmailMode}
              onKeyPress={toggleCreateAccountByEmailMode}
            >
              {buttonMessage}
            </span>
            <Link to="/forgot-password">
              <div className="link">Forgot Password?</div>
            </Link>
          </Message>
        </Grid.Column>
      </Grid>
    </div>
  );
};

LoginForm.propTypes = {
  loginWithGoogle: PropTypes.func.isRequired,
  createAccountByEmailMode: PropTypes.bool.isRequired,
  toggleCreateAccountByEmailMode: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired
};

export default LoginForm;
