class ModelsNote {
  constructor(
    id,
    userId,
    annotationId,
    content,
    dateCreated,
    dateModified,
    type
  ) {
    this.id = id;
    this.userId = userId;
    this.annotationId = annotationId;
    this.content = content;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.type = type;
  }
}

export default ModelsNote;
