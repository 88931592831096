import React from "react";
import { Grid, Segment, Header, Icon } from "semantic-ui-react";

const ProfileNotFound = () => {
  return (
    <Grid columns={1} centered style={{ marginTop: "30px" }} doubling>
      <Segment basic>
        <Header icon>
          <Icon name="frown outline" />
          Sorry this profile doesn't exist!
        </Header>
      </Segment>
    </Grid>
  );
};

export default ProfileNotFound;
