import React, { Component } from "react";
import { Form, Message, Segment } from "semantic-ui-react";
import { auth } from "fire";

const ERROR_CODE = {
  "auth/invalid-email": "Invalid Email"
};

/**
 * @name CreateAccount
 * @description Create Account with Email Component
 */
export default class CreateAccount extends Component {
  state = {
    loading: false,
    email: "",
    password: "",
    passwordConfirmation: "",
    hasError: false,
    errorHeader: undefined,
    errorContent: undefined
  };

  passwordsAreValid() {
    const { password, passwordConfirmation } = this.state;
    return password !== passwordConfirmation;
  }

  submit = () => {
    // TODO: Move the validation into it's own class/util/whatever.
    const { saveUser } = this.props;
    const { email, password, passwordConfirmation } = this.state;
    // confirmation password does not match.
    if (password !== passwordConfirmation) {
      return this.setState({
        hasError: true,
        errorHeader: "Invalid Password",
        errorContent: "Password and Password Confirmation do not match."
      });
    }
    // empty password
    if (password.trim() === "") {
      this.setState({
        hasError: true,
        errorHeader: "Invalid Password",
        errorContent: "You cannot use an empty password."
      });
    }
    this.setState({
      hasError: false,
      loading: true
    });
    // On success, firebase handles redirecting to home page.
    return auth()
      .createUserWithEmailAndPassword(email.trim(), password.trim())
      .then(ref => {
        saveUser(ref.user);
      })
      .catch(err => {
        this.setState({
          hasError: true,
          loading: false,
          errorHeader: ERROR_CODE[err.code] || "Error",
          errorContent: err.message
        });
      });
  };
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const {
      email,
      password,
      passwordConfirmation,
      hasError,
      errorHeader,
      errorContent
    } = this.state;
    return (
      <Segment stacked>
        <Form
          loading={this.state.loading}
          onSubmit={this.submit}
          error={hasError}
        >
          <Message error header={errorHeader} content={errorContent} />
          <Form.Input
            name="email"
            value={email}
            onChange={this.handleChange}
            type="email"
            fluid
            icon="user"
            iconPosition="left"
            placeholder="E-mail address"
          />
          <Form.Input
            name="password"
            value={password}
            onChange={this.handleChange}
            type="password"
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Password"
          />
          <Form.Input
            name="passwordConfirmation"
            value={passwordConfirmation}
            onChange={this.handleChange}
            type="password"
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Password"
          />
          <Form.Button fluid color="blue">
            Submit
          </Form.Button>
        </Form>
      </Segment>
    );
  }
}
