import { combineReducers } from "redux";
import auth from "./auth";
import books from "./books";
import ui from "./ui";
import notes from "./notes";
import annotations from "./annotations";
import extension from "./extension";

export default combineReducers({
  auth,
  books,
  ui,
  annotations,
  notes,
  extension
});
