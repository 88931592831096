import { fork, all } from "redux-saga/effects";
import booksSaga from "./booksSaga";
import annotationsSaga from "./annotationsSaga";
import notesSaga from "./notesSaga";
import authSaga from "./authSaga";

// Update this to a map later
export default function* rootSaga() {
  yield all([
    fork(booksSaga),
    fork(annotationsSaga),
    fork(notesSaga),
    fork(authSaga)
  ]);
}
