import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { List, Grid, Segment, Header, Icon } from "semantic-ui-react";
import UserCardContainer from "modules/publicProfile/containers/UserCardContainer";

const Followers = ({ followers }) => (
  <Grid columns={1} centered style={{ marginTop: "30px" }} doubling>
    <List selection verticalAlign="middle" size="massive">
      {followers.length === 0 ? (
        <Segment basic>
          <Header icon>
            <Icon name="group" />
            No Followers? Give
            <Link to="/public/kevin">
              <strong> Kevin </strong>
            </Link>
            a follow and see if he returns the favor.
          </Header>
        </Segment>
      ) : (
        followers.map(follow => <UserCardContainer userId={follow} />)
      )}
    </List>
  </Grid>
);

Followers.propTypes = {
  followers: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Followers;
