import React from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "semantic-ui-react";

const EvernoteButton = ({ bookId, openEvernoteExportModal }) => (
  <React.Fragment>
    <Button
      className="shareButton"
      icon
      labelPosition="left"
      basic
      color="blue"
      compact
      size="tiny"
      onClick={() => openEvernoteExportModal()}
      data-clickable="true"
      data-location="bookTable"
      data-action="evernoteExport"
      data-label={bookId}
    >
      Save to Evernote
      <Icon
        name="save"
        data-clickable="true"
        data-location="bookTable"
        data-action="evernoteExport"
        data-label={bookId}
      />
    </Button>
  </React.Fragment>
);

EvernoteButton.propTypes = {
  bookId: PropTypes.string.isRequired,
  openEvernoteExportModal: PropTypes.func.isRequired
};

export default EvernoteButton;
