import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, Table } from "semantic-ui-react";
import { hideRecentImports as hideRecentImportsAction } from "actions/uiActions";
import {
  setSelectedBook as setSelectedBookAction,
  fetchBooks as fetchBooksAction
} from "actions/booksActions";
import { selectRecentImportsList } from "reducers/extension";
import withLoading from "hoc/withLoading";
import ImportedBookModalRow from "modules/import/components/ImportedBookModalRow";
import ModalButtons from "modules/import/components/ModalButtons";
import { ModelsBook } from "models";

const ImportedBookModalRowWithLoading = withLoading(ImportedBookModalRow);

class ImportedBookModal extends Component {
  componentDidUpdate() {
    const { booksById, recentImports, fetchBooks } = this.props;
    const missingBooks = recentImports.filter(
      recentImport => !booksById[recentImport.resourceId]
    );
    if (missingBooks.length > 0) {
      fetchBooks();
    }
  }

  importMoreHandler = () => {
    this.closeModal();
  };

  closeModal = () => {
    const { hideRecentImports } = this.props;
    hideRecentImports();
  };

  render() {
    const { open, recentImports, booksById, setSelectedBook } = this.props;
    const noImportedBooks = (
      <Modal
        open={open}
        closeOnEscape
        closeOnRootNodeClick={false}
        onClose={this.closeModal}
        closeIcon
        size="tiny"
        className="importCompleteModal"
      >
        <Modal.Header>No Recent Imports</Modal.Header>
        <Modal.Content scrolling>
          You have imported any books recently. Follow the instructions on the
          Import page to import new book annotations.
          <ModalButtons
            hasAnnotations={false}
            closeModal={this.closeModal}
            importMoreHandler={this.importMoreHandler}
          />
        </Modal.Content>
      </Modal>
    );

    const importedBooks = (
      <Modal
        open={open}
        closeOnEscape
        closeOnRootNodeClick={false}
        onClose={this.closeModal}
        closeIcon
        size="tiny"
        className="importCompleteModal"
      >
        <Modal.Header>Import Complete</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <div className="stepContent">
              Congrats! You've imported your book's annotations into Owl &
              Scroll.
            </div>
            <Table
              basic="very"
              celled
              collapsing
              selectable
              style={{
                marginLeft: "auto",
                marginRight: "auto"
              }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                  <Table.HeaderCell>Annotations Added</Table.HeaderCell>
                  <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {recentImports.map(recentImport => {
                  const book = booksById[recentImport.resourceId];
                  return (
                    <ImportedBookModalRowWithLoading
                      closeModal={this.closeModal}
                      book={book}
                      key={recentImport.resourceId}
                      setSelectedBook={setSelectedBook}
                      isFetching={!book}
                      isTable
                      numberOfAddedAnnotations={
                        recentImport.numberOfAnnotations
                      }
                    />
                  );
                })}
              </Table.Body>
            </Table>

            <ModalButtons
              hasAnnotations
              closeModal={this.closeModal}
              importMoreHandler={this.importMoreHandler}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );

    const modalContent =
      recentImports.length > 0 ? importedBooks : noImportedBooks;
    return modalContent;
  }
}

ImportedBookModal.propTypes = {
  open: PropTypes.bool.isRequired,
  recentImports: PropTypes.arrayOf(PropTypes.object).isRequired,
  booksById: PropTypes.objectOf(PropTypes.instanceOf(ModelsBook)).isRequired,
  setSelectedBook: PropTypes.func.isRequired,
  hideRecentImports: PropTypes.func.isRequired,
  fetchBooks: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const recentImports = selectRecentImportsList(state);
  return {
    booksById: state.books.byId,
    recentImports
  };
};

export default connect(
  mapStateToProps,
  {
    setSelectedBook: setSelectedBookAction,
    hideRecentImports: hideRecentImportsAction,
    fetchBooks: fetchBooksAction
  }
)(ImportedBookModal);
