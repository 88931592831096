import concat from "lodash/concat";
import get from "lodash/get";
import filter from "lodash/filter";

// break user into its own reducer
const initialState = {
  user: null,
  isFetching: false,
  initialAuthSet: false,
  hasFetchedUsername: false,
  followed: {},
  followers: {},
  followersByUserId: {}, // userid: [] list of followers
  followedByUserId: {},
  usernameMap: {}
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_USER": {
      return {
        ...state,
        isFetching: false,
        user: action.user,
        initialAuthSet: true
      };
    }
    case "SET_NO_USER": {
      return { ...state, isFetching: false, user: null, initialAuthSet: true };
    }
    case "GET_USERNAME_SUCCESS": {
      return {
        ...state,
        user: {
          ...state.user,
          username: action.username
        },
        hasFetchedUsername: true
      };
    }
    case "GET_USERNAME_FAILURE": {
      return {
        ...state,
        hasFetchedUsername: true
      };
    }
    case "UPDATE_USERNAME_SUCCESS": {
      return { ...state, user: { ...state.user, username: action.username } };
    }
    case "LOGOUT_USER_SUCCESS": {
      return { ...state, user: null };
    }
    case "FOLLOW_USER_SUCCESS": {
      return {
        ...state,
        followedByUserId: {
          ...state.followedByUserId,
          [action.userId]: concat(
            get(state, `followedByUserId${action.userId}`, []),
            action.followed
          )
        },
        followersByUserId: {
          ...state.followersByUserId,
          [action.followed]: concat(
            get(state, `followersByUserId${action.followed}`, []),
            action.userId
          )
        }
      };
    }
    case "UNFOLLOW_USER_SUCCESS": {
      return {
        ...state,
        followedByUserId: {
          ...state.followedByUserId,
          [action.userId]: filter(
            get(state, `followedByUserId${action.userId}`, []),
            id => id === action.unfollowed
          )
        },
        followersByUserId: {
          ...state.followersByUserId,
          [action.unfollowed]: filter(
            get(state, `followersByUserId${action.unfollowed}`, []),
            id => id === action.userId
          )
        }
      };
    }
    case "GET_FOLLOWED_SUCCESS": {
      return {
        ...state,
        followedByUserId: {
          ...state.followedByUserId,
          [action.userId]: action.followed
        }
      };
    }
    case "GET_FOLLOWERS_SUCCESS": {
      return {
        ...state,
        followersByUserId: {
          ...state.followersByUserId,
          [action.userId]: action.followers
        }
      };
    }
    case "GET_STATS_SUCCESS": {
      return {
        ...state,
        statsByUserId: {
          ...state.statsByUserId,
          [action.userId]: action.stats
        }
      };
    }
    case "GET_USER_ID_SUCCESS": {
      return {
        ...state,
        usernameMap: { ...state.usernameMap, [action.username]: action.userId }
      };
    }
    case "GET_USERNAME_BY_USER_ID_SUCCESS": {
      return {
        ...state,
        usernameMap: { ...state.usernameMap, [action.username]: action.userId }
      };
    }
    case "GET_USERNAME_BY_USER_ID_FAILURE": {
      return {
        ...state,
        usernameMap: { ...state.usernameMap, [action.username]: action.userId }
      };
    }
    default:
      return state;
  }
}

export default authReducer;

export const selectUser = state => state.auth.user;
export const selectFollowed = state => state.auth.followed;
export const selectFollowers = state => state.auth.followersByUserId;
