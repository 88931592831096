import { createSelector } from "reselect";
import values from "lodash/values";

const initialState = {
  installed: false,
  recentImports: {}
};

function extensionReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_INSTALLED": {
      return { ...state, installed: action.installed };
    }
    case "ADD_TO_RECENT_IMPORTS": {
      return {
        ...state,
        recentImports: {
          ...state.recentImports,
          [action.resourceId]: {
            resourceId: action.resourceId,
            numberOfAnnotations: action.numberOfAnnotations
          }
        }
      };
    }
    default:
      return state;
  }
}

export default extensionReducer;

export const selectRecentImports = state => {
  return state.extension.recentImports;
};

export const selectRecentImportsList = createSelector(
  [selectRecentImports],
  imports => values(imports)
);
