import React, { Component } from "react";
import PropTypes from "prop-types";
import { auth, firestore } from "fire";
import { filter, maxBy, get } from "lodash";
import { connect } from "react-redux";
import { selectBookById } from "reducers/books";
import Cookies from "cookies-js";
import EvernoteExportModal from "modules/annotations/components/evernoteExportModal/EvernoteExportModal";
import EvernoteSaveDescription from "modules/annotations/components/evernoteExportModal/EvernoteSaveDescription";
import EvernoteAuthDescription from "modules/annotations/components/evernoteExportModal/EvernoteAuthDescription";
import EvernoteFinishedDescription from "modules/annotations/components/evernoteExportModal/EvernoteFinishedDescription";
import { ModelsBook, ModelsUser } from "models";
import config from "config";

class EvernoteExport extends Component {
  static propTypes = {
    bookInstance: PropTypes.instanceOf(ModelsBook),
    user: PropTypes.instanceOf(ModelsUser),
    toggleModal: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  };

  static defaultProps = {
    bookInstance: undefined,
    user: null
  };

  constructor(props) {
    super(props);
    const { bookInstance } = props;
    this.state = {
      loading: false,
      displayError: false,
      failedSaves: [],
      maxRateWait: 0,
      successfulSaves: 0,
      firebaseToken: false,
      displayMessage: `Each annotation will be saved as a seperate note in a notebook titled ${get(
        bookInstance,
        "title",
        ""
      )}.`,
      displayElement: "evernoteSaveElement",
      exportIndividually: true
    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    const { bookInstance } = this.props;
    if (bookInstance && prevProps.bookInstance) {
      if (bookInstance.id !== prevProps.bookInstance.id) {
        this.setState({
          loading: false,
          displayError: false,
          failedSaves: [],
          successfulSaves: 0,
          maxRateWait: 0,
          displayMessage: `Each annotation will be saved as a seperate note in a notebook titled ${
            bookInstance.title
          }.`,
          displayElement: "evernoteSaveElement"
        });
      }
    }
  }

  listenForEvernoteToken = () => {
    const { user } = this.props;
    firestore()
      .collection("users")
      .doc(user.id)
      .get()
      .then(doc => {
        if (doc.exists) {
          const { bookInstance } = this.props;
          const { exportIndividually } = this.state;
          if (doc.data().evernoteToken) {
            this.setState({
              displayError: false,
              displayElement: "evernoteSaveElement"
            });
            if (exportIndividually) {
              this.setState({
                displayMessage: `Your Evernote account is now connected to Owl & Scroll. Click Save and each annotation will be saved as a seperate note in a notebook titled ${get(
                  bookInstance,
                  "title",
                  ""
                )}.`
              });
            } else {
              this.setState({
                displayMessage: `Your Evernote account is now connected to Owl & Scroll. Click Save and all annotations will be saved in a single note titled ${get(
                  bookInstance,
                  "title",
                  ""
                )} in a notebook named Owl & Scroll.`
              });
            }

            window.removeEventListener("focus", this.listenForEvernoteToken);
          }
        }
      });
  };

  showEvernoteAuthMessage() {
    // Set check and idToken cookies for the http call to the firebase function evernote-login

    Cookies.set("checkForEvernoteToken", "waiting", { expires: 300000 * 5 });

    // List for when the user comes back to this window and check if the evernoteToken is set
    window.addEventListener("focus", this.listenForEvernoteToken);
  }

  writeToEvernote() {
    const { bookInstance } = this.props;
    const { exportIndividually } = this.state;
    const apiURL = config.apiUrl;

    const resourceInstanceId = bookInstance.id;
    const resourceInstanceTitle = bookInstance.title;
    const linkURL = config.hostUrl;
    let firebaseToken;
    this.setState({ loading: true });

    auth()
      .currentUser.getIdToken()
      .then(token => {
        firebaseToken = token;
        const exportURL = exportIndividually
          ? `${apiURL}/api/evernote-export`
          : `${apiURL}/api/evernote-export-note-per-book`;
        fetch(exportURL, {
          body: JSON.stringify({
            resourceInstanceId,
            resourceInstanceTitle,
            linkURL
          }),
          headers: {
            Authorization: `Bearer ${firebaseToken}`,
            "content-type": "application/json"
          },
          method: "POST"
        })
          .then(response => {
            if (!response.ok) {
              response.text().then(text => {
                const responseText = JSON.parse(text);
                if (
                  responseText.message === "User does not have an evernoteToken"
                ) {
                  this.setState({
                    displayError: false,
                    displayMessage:
                      "Login to your Evernote account to connect it to Owl & Scroll.",
                    displayElement: "evernoteAuthElement",
                    firebaseToken
                  });
                  this.showEvernoteAuthMessage();
                } else if (
                  responseText.message ===
                  "The evernoteToken is expired or revoked"
                ) {
                  this.setState({
                    displayError: false,
                    displayMessage:
                      "Your Evernote connection has expired. Login to your Evernote account to reconnect it to Owl & Scroll.",
                    displayElement: "evernoteAuthElement",
                    firebaseToken
                  });
                  this.showEvernoteAuthMessage();
                } else if (
                  responseText.message ===
                  "A notebook with that name already exists"
                ) {
                  this.setState({
                    displayError: true,
                    displayMessage: `You already have an Evernote notebook with the name "${
                      responseText.notebookName
                    }". Rename the existing notebook in Evernote and try again.`,
                    displayElement: "evernoteSaveElement"
                  });
                } else if (response.errorCode === 19) {
                  this.setState({
                    displayError: true,
                    displayMessage: `Evernote prevents you from saving notebooks with same name over and over again. Wait  ${
                      responseText.error.rateLimitDuration
                    } seconds to save this book again. You can still save other books.`,
                    displayElement: "evernoteSaveElement"
                  });
                } else {
                  this.setState({
                    displayError: true,
                    displayMessage: `And Unkown Error Occured. Please try again. ${get(
                      responseText,
                      "message",
                      responseText
                    )}.`
                  });
                }
              });
            } else {
              response.text().then(text => {
                const responseText = JSON.parse(text);
                if (responseText.message === "Export Finished") {
                  this.setState({
                    displayError: false,
                    displayMessage: responseText.notebookName,
                    displayElement: "exportFinishedMessage"
                  });
                  const failedSaves = filter(
                    responseText.noteArray,
                    note => note.error
                  );
                  const longestError = maxBy(
                    failedSaves,
                    failedSave => failedSave.error.rateLimitDuration
                  );
                  let maxRateWait = 0;
                  if (longestError) {
                    maxRateWait = longestError.error.rateLimitDuration;
                  }
                  const successfulSaves =
                    responseText.noteArray.length - failedSaves.length;
                  this.setState({
                    failedSaves,
                    maxRateWait,
                    successfulSaves
                  });
                  return responseText;
                }
              });
            }
          })
          .catch(error => {
            console.log(error);
            this.setState({
              loading: false,
              displayError: true,
              displayMessage: "Error exporting to evernote."
            });
          })
          .finally(() => this.setState({ loading: false }));
      });
  }

  updateExportConfig(value) {
    const { displayElement } = this.state;
    const { bookInstance } = this.props;
    this.setState({ exportIndividually: value });
    if (value === true && displayElement === "evernoteSaveElement") {
      this.setState({
        displayMessage: `Each annotation will be saved as a seperate note in a notebook titled ${get(
          bookInstance,
          "title",
          ""
        )}.`
      });
    } else if (displayElement === "evernoteSaveElement") {
      this.setState({
        displayMessage: `All annotations will be saved in a single note titled ${get(
          bookInstance,
          "title",
          ""
        )} in a notebook named Owl & Scroll.`
      });
    }
  }

  switchDescription(displayElement) {
    const { bookInstance, toggleModal } = this.props;
    const apiURL = config.apiUrl;
    const {
      loading,
      displayMessage,
      displayError,
      successfulSaves,
      maxRateWait,
      failedSaves,
      firebaseToken,
      exportIndividually
    } = this.state;
    switch (displayElement) {
      case "evernoteSaveElement":
        return [
          <EvernoteSaveDescription
            key={1}
            bookInstance={bookInstance}
            loading={loading}
            displayMessage={displayMessage}
            displayError={displayError}
            writeToEvernote={() => {
              this.writeToEvernote();
            }}
            exportIndividually={exportIndividually}
            selectExportIndividually={() => {
              this.updateExportConfig(true);
            }}
            selectExportAll={() => {
              this.updateExportConfig(false);
            }}
          />
        ];
      case "evernoteAuthElement":
        return [
          <EvernoteAuthDescription
            key={2}
            bookInstance={bookInstance}
            loading={loading}
            displayMessage={displayMessage}
            displayError={displayError}
            apiURL={apiURL}
            firebaseToken={firebaseToken}
          />
        ];
      case "exportFinishedMessage":
        return [
          <EvernoteFinishedDescription
            key={3}
            bookInstance={bookInstance}
            loading={loading}
            displayMessage={displayMessage}
            displayError={displayError}
            successfulSaves={successfulSaves}
            failedSaves={failedSaves}
            maxRateWait={maxRateWait}
            toggleModal={toggleModal}
            exportIndividually={exportIndividually}
          />
        ];
      default:
        return [
          <EvernoteSaveDescription
            key={4}
            bookInstance={bookInstance}
            loading={loading}
            displayMessage={displayMessage}
            displayError={displayError}
            writeToEvernote={this.writeToEvernote}
          />
        ];
    }
  }

  render() {
    const { displayElement } = this.state;
    const { open, toggleModal, bookInstance } = this.props;
    return bookInstance ? (
      <EvernoteExportModal
        toggleModal={toggleModal}
        open={open}
        bookInstance={bookInstance}
      >
        {this.switchDescription(displayElement)}
      </EvernoteExportModal>
    ) : null;
  }
}

function mapStateToProps(state) {
  const bookInstance = selectBookById(state, state.books.selectedBookId);

  return {
    bookInstance,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(EvernoteExport);
