import React from "react";
import PublicProfile from "modules/publicProfile/containers/PublicProfile";
import LayoutsDetails from "layouts/details/Details";

const ScreensPublicBook = props => {
  return (
    <LayoutsDetails {...props}>
      <PublicProfile {...props} />
    </LayoutsDetails>
  );
};

export default ScreensPublicBook;
