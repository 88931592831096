import React from "react";
import Settings from "modules/settings/containers/Settings";
import LayoutsDefaultNoSideNav from "layouts/defaultNoSideNav/DefaultNoSideNav";

const ScreensSettings = props => (
  <LayoutsDefaultNoSideNav {...props}>
    <Settings {...props} />
  </LayoutsDefaultNoSideNav>
);

export default ScreensSettings;
