import React from "react";
import { Grid, Header, Image, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";
import logo from "images/logo-transparent.png";
import "./Login.css";
import ResetPassword from "modules/login/ResetPassword";

/**
 * Presentational component for rendering the forgot password form.
 */
const ForgotPasswordForm = () => {
  return (
    <div className="login-form">
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="blue" textAlign="center">
            <Image src={logo} /> Owl & Scroll
          </Header>
          <ResetPassword />
          <Message>
            <Link to="/login">
              <div className="link">Back to Login</div>
            </Link>
          </Message>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ForgotPasswordForm;
