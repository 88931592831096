import React from "react";
import PropTypes from "prop-types";
import { Button, Responsive, Icon, Rail } from "semantic-ui-react";
import "./LeftNavToggle.css";

const LeftNavToggle = props => {
  const { sidebarVisibilityHandler } = props;
  return (
    <Responsive
      as={Rail}
      minWidth={Responsive.onlyTablet.minWidth}
      internal
      attached
      position="fixed"
      size="mini"
      className="mainToggleMenu"
      style={{ zIndex: 101 }}
    >
      <Button
        data-clickable="true"
        data-location="annotationsSegement"
        data-action="showAnnotationsSelector"
        icon
        size="large"
        onClick={sidebarVisibilityHandler}
      >
        <Icon
          name="options"
          data-clickable="true"
          data-location="annotationsSegement"
          data-action="showAnnotationsSelector"
        />
      </Button>
    </Responsive>
  );
};

LeftNavToggle.propTypes = {
  sidebarVisibilityHandler: PropTypes.func.isRequired
};

export default LeftNavToggle;
