import React from "react";
import { Header, Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const PublicBookUnavailable = () => (
  <React.Fragment>
    <Header as="h2" icon textAlign="center">
      <Icon name="frown" size="big" />
      <Header.Content>Sorry! Public Book Unavailable</Header.Content>
      <br />
      <Header.Content>
        <Link to={{ pathname: "/" }}>
          <Button positive>Home</Button>
        </Link>
      </Header.Content>
    </Header>
  </React.Fragment>
);

export default PublicBookUnavailable;
