import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BookList from "modules/annotations/components/bookList/BookList";
import SharedBooksMenu from "modules/annotations/components/sharedBooksMenu/SharedBooksMenu";
import { ModelsBook, ModelsFriend } from "models";
import withLoading from "hoc/withLoading";
import {
  setSelectedSharedBook as setSelectedSharedBookAction,
  fetchBooks as fetchBooksAction
} from "actions/booksActions";
import { selectFriends, selectSharedBooksList } from "reducers/books";

const BookListWithLoading = withLoading(BookList);

class SharedBooksSelector extends Component {
  static propTypes = {
    selectedSharedBookId: PropTypes.string,
    books: PropTypes.arrayOf(PropTypes.instanceOf(ModelsBook)).isRequired,
    friends: PropTypes.arrayOf(PropTypes.instanceOf(ModelsFriend)).isRequired,
    isFetching: PropTypes.bool.isRequired,
    setSelectedSharedBook: PropTypes.func.isRequired,
    fetchBooks: PropTypes.func.isRequired
  };

  static defaultProps = {
    selectedSharedBookId: ""
  };

  componentDidMount() {
    const { fetchBooks } = this.props;
    fetchBooks();
  }

  render() {
    const {
      isFetching,
      selectedSharedBookId,
      books,
      setSelectedSharedBook,
      friends
    } = this.props;
    return (
      <BookListWithLoading
        activeItem={selectedSharedBookId}
        books={books}
        friends={friends}
        handleItemClick={(e, { value }) => {
          setSelectedSharedBook(value);
        }}
        isFetching={isFetching}
        title="Friends Books"
      >
        <SharedBooksMenu
          friends={friends}
          selectedSharedBookId={selectedSharedBookId}
          setSelectedSharedBook={setSelectedSharedBook}
        />
      </BookListWithLoading>
    );
  }
}

const mapStateToProps = state => ({
  books: selectSharedBooksList(state),
  selectedSharedBookId: state.books.selectedSharedBookId,
  isFetching: state.books.isFetching,
  friends: selectFriends(state)
});

export default connect(
  mapStateToProps,
  {
    setSelectedSharedBook: setSelectedSharedBookAction,
    fetchBooks: fetchBooksAction
  }
)(SharedBooksSelector);
