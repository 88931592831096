import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { togglePublicBook as togglePublicBookAction } from "actions/booksActions";
import AnnotationListHeader from "modules/annotations/components/annotationListHeader/AnnotationListHeader";
import { ModelsBook } from "models";
import AirbnbPropTypes from "airbnb-prop-types";
import {
  hideTitleVisibility as hideTitleVisibilityAction,
  showTitleVisibility as showTitleVisibilityAction,
  showCreateUsernameModal as showCreateUsernameModalAction
} from "actions/uiActions";
import PublicLinkButtonsContainer from "modules/annotations/containers/PublicLinkButtonsContainer";
import EvernoteButton from "modules/annotations/components/annotationListHeader/EvernoteButton";
import OpenPublicLinkButton from "modules/annotations/components/annotationListHeader/OpenPublicLinkButton";
import BuyBookButton from "modules/annotations/components/annotationListHeader/BuyBookButton";
import ReturnToProfileButton from "modules/annotations/components/annotationListHeader/ReturnToProfileButton";
import get from "lodash/get";

class AnnotationListHeaderContainer extends Component {
  static propTypes = {
    book: PropTypes.oneOfType([
      PropTypes.instanceOf(ModelsBook),
      AirbnbPropTypes.explicitNull()
    ]),
    togglePublicBook: PropTypes.func.isRequired,
    annotationsLength: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    openEvernoteExportModal: PropTypes.func.isRequired,
    showTitleVisibility: PropTypes.func.isRequired,
    hideTitleVisibility: PropTypes.func.isRequired
  };

  static defaultProps = {
    book: undefined
  };

  switchToolBar() {
    const {
      type,
      book,
      togglePublicBook,
      openEvernoteExportModal,
      username
    } = this.props;
    switch (type) {
      case "own":
        return [
          <React.Fragment key={1}>
            <PublicLinkButtonsContainer
              bookId={get(book, "id", "")}
              publicShare={get(book, "publicShare", false)}
              togglePublicBook={togglePublicBook}
            />
            <EvernoteButton
              bookId={get(book, "id", "")}
              openEvernoteExportModal={openEvernoteExportModal}
            />
          </React.Fragment>
        ];
      case "shared":
        return [
          <React.Fragment key={2}>
            {get(book, "publicShare", false) && (
              <OpenPublicLinkButton bookId={get(book, "id", "")} />
            )}
          </React.Fragment>
        ];
      case "public":
        return [
          <React.Fragment key={3}>
            {" "}
            <BuyBookButton
              bookId={get(book, "id", "")}
              ASIN={get(book, "ASIN", "")}
            />
            <ReturnToProfileButton username={username} />
          </React.Fragment>
        ];
      default:
        return [];
    }
  }

  render() {
    const {
      annotationsLength,
      book,
      showTitleVisibility,
      hideTitleVisibility
    } = this.props;
    return (
      <AnnotationListHeader
        annotationsLength={annotationsLength}
        book={book}
        showTitleVisibility={showTitleVisibility}
        hideTitleVisibility={hideTitleVisibility}
      >
        {this.switchToolBar()}
      </AnnotationListHeader>
    );
  }
}
export default connect(
  null,
  {
    togglePublicBook: togglePublicBookAction,
    hideTitleVisibility: hideTitleVisibilityAction,
    showTitleVisibility: showTitleVisibilityAction,
    showCreateUsernameModal: showCreateUsernameModalAction
  }
)(AnnotationListHeaderContainer);
