import config from "config";
import firebase from "firebase";
import "@firebase/firestore";

firebase.initializeApp(config);

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const { auth } = firebase;
export const { database } = firebase;
export const { firestore } = firebase;

export default firebase;
