import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Menu, Image, Card, Loader, Grid } from "semantic-ui-react";
import get from "lodash/get";
import AnnotationToolbar from "modules/annotation/toolbar/Toolbar";
import { fetchAnnotationDetails } from "actions/annotationsActions";
import { fetchBook } from "actions/booksActions";
import "./AnnotationDetails.css";
import { selectAnnotationById } from "reducers/annotations";
import { selectBookById } from "reducers/books";
import {
  setKindleModalInfo as setKindleModalInfoAction,
  showOpenKindleModal as showOpenKindleModalAction
} from "actions/uiActions";
import OpenKindle from "modules/annotations/containers/OpenKindle";

class AnnotationDetails extends Component {
  componentDidMount() {
    const { fetchAnnotationDetails } = this.props;
    fetchAnnotationDetails(this.props.match.params.annotationId);
  }

  componentDidUpdate(prevProps) {
    const { bookId, fetchBook } = this.props;
    if (prevProps.bookId !== bookId && bookId) {
      fetchBook(bookId);
    }
  }

  toggleAddingNote = () => {
    this.setState({ addingNote: !this.state.addingNote });
  };

  handleKindleLink = () => {
    const annotationUserId = get(this.props, "annotation.userId", null);
    const userId = get(this.props, "user.id", null);
    const shared = !(annotationUserId !== null && userId === annotationUserId);
    const {
      showOpenKindleModal,
      setKindleModalInfo,
      bookInfo,
      annotation
    } = this.props;
    setKindleModalInfo(
      get(bookInfo, "title", undefined),
      get(bookInfo, "ASIN", undefined),
      get(bookInfo, "id", undefined),
      get(bookInfo, "imgUrl", undefined),
      get(annotation, "location", undefined),
      shared,
      get(annotation, "id", undefined)
    );
    showOpenKindleModal();
  };

  render() {
    const {
      annotation,
      bookInfo,
      isFetching,
      openKindleModal,
      showOpenKindleWindow
    } = this.props;
    const annotationUserId = get(this.props, "annotation.userId", null);
    const userId = get(this.props, "user.id", null);
    const shared = annotationUserId === null || userId !== annotationUserId;
    const content = get(annotation, "content", "");

    // Create the annotation conent
    const annotationContent = (
      <Card raised fluid centered className="annotation">
        <Card.Content>
          <Image
            floated="right"
            size="mini"
            src={get(bookInfo, "imgUrl", undefined)}
            alt={get(bookInfo, "title", undefined)}
            data-clickable="true"
            data-location="bookTable"
            data-action="openBookInAmazon"
            data-label={get(bookInfo, "id", undefined)}
          />
          <Card.Header>{get(bookInfo, "title", undefined)}</Card.Header>
          <Card.Meta>by {get(bookInfo, "author", undefined)}</Card.Meta>
          <Card.Description>
            <Grid>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                className="annotationContent"
              >
                {get(annotation, "content", undefined)}
              </Grid.Column>
            </Grid>
          </Card.Description>
        </Card.Content>
        <AnnotationToolbar
          annotationId={get(annotation, "id", "")}
          ASIN={get(bookInfo, "ASIN", "")}
          bookId={get(bookInfo, "id", "")}
          location={get(annotation, "location", "")}
          order={0}
          publicShare={get(annotation, "publicShare", false)}
          shared={shared}
          fullPage
          showNote={false}
          openKindleModal={openKindleModal}
          handleKindleLink={this.handleKindleLink}
          favorite={get(annotation, "favorite", false)}
          content={content}
        />
      </Card>
    );

    // Create the annotation not shared content
    const notSharedContent = (
      <Card raised fluid centered className="annotation">
        <Card.Content>
          <Card.Header>Annotation Not Shared</Card.Header>
          <Card.Description>
            <p>The owner of this annotation has not shared it publicly.</p>
            <p>
              If you are the owner of the annotation, please login to your Owl &
              Scroll account to view and share it.
            </p>
          </Card.Description>
        </Card.Content>
      </Card>
    );

    // Create the annotation not shared content
    const noAnnotationContent = (
      <Card raised fluid centered className="annotation">
        <Card.Content>
          <Card.Header>Annotation Not Found</Card.Header>
          <Card.Description>
            We could not find the annotation you're looking for.
          </Card.Description>
        </Card.Content>
      </Card>
    );

    const loading = (
      <Loader active inline="centered">
        Loading
      </Loader>
    );

    let mainContent = noAnnotationContent;
    if (annotation) {
      if (shared) {
        mainContent = annotation.publicShare
          ? annotationContent
          : notSharedContent;
      } else {
        mainContent = annotationContent;
      }
    }

    const loginButton = (
      <Menu.Item
        href="/login"
        className="logoText"
        position="right"
        data-clickable="true"
        data-location="logoHeader"
        data-action="logIn"
      >
        Login
      </Menu.Item>
    );

    const homeButton = (
      <Menu.Item
        href="/"
        className="logoText"
        position="right"
        data-clickable="true"
        data-location="logoHeader"
        data-action="goHome"
      >
        Home
      </Menu.Item>
    );

    return (
      <div className="singleAnnotation">
        <Menu borderless className="logoHeader">
          <Menu.Item
            href="/"
            className="logoText"
            data-clickable="true"
            data-location="logoHeader"
            data-action="goHome"
          >
            <Image
              size="mini"
              src="https://s3.amazonaws.com/countingcalculi-assets/magic_images/Owl_Scroll.png"
            />
            &nbsp;&nbsp;&nbsp;Owl & Scroll
          </Menu.Item>

          {userId === null ? loginButton : homeButton}
        </Menu>
        <Container id="singleNoteContainer" className="singleNoteContainer">
          {isFetching ? loading : mainContent}
        </Container>
        {userId === null && (
          <div className="noteFooter">
            <p>Want to organize and share your Kindle Highlights?</p>
            <p>
              <a href="/login">Sign Up for Owl & Scroll</a>
            </p>
          </div>
        )}
        <OpenKindle open={showOpenKindleWindow} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { annotationId } = ownProps.match.params;
  const annotation = selectAnnotationById(state, annotationId);
  const bookId = get(annotation, "resourceInstanceId");
  const isFetching =
    state.annotations.isFetching ||
    !state.annotations.receivedAt ||
    state.books.isFetching;
  return {
    user: state.auth.user,
    annotation,
    bookId,
    isFetching,
    bookInfo: selectBookById(state, bookId),
    showOpenKindleWindow: state.ui.showOpenKindleWindow
  };
};

export default connect(
  mapStateToProps,
  {
    fetchAnnotationDetails,
    fetchBook,
    setKindleModalInfo: setKindleModalInfoAction,
    showOpenKindleModal: showOpenKindleModalAction
  }
)(AnnotationDetails);
