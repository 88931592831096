import { Responsive } from "semantic-ui-react";

const initialState = {
  showTitleInMenu: false,
  filterText: "",
  searchVal: "",
  showImportWindow: false,
  showSidebar: window.innerWidth > Responsive.onlyTablet.minWidth,
  showOpenKindleWindow: false,
  kindleBookTitle: undefined,
  kindleBookASIN: undefined,
  kindleBookId: undefined,
  kindleBookImgUrl: undefined,
  kindleLocation: undefined,
  kindleBookShsared: undefined,
  kindleAnnotationId: undefined,
  showCreateUsernameModal: false
};

function uiReducer(state = initialState, action) {
  switch (action.type) {
    case "SHOW_TITLE_VISIBILITY": {
      return {
        ...state,
        showTitleInMenu: true
      };
    }
    case "HIDE_TITLE_VISIBILITY": {
      return {
        ...state,
        showTitleInMenu: false
      };
    }
    case "SET_SEARCH_FILTER": {
      return {
        ...state,
        filterText: action.filterText
      };
    }
    case "SET_SEARCH_VAL": {
      return {
        ...state,
        searchVal: action.searchVal
      };
    }
    case "SHOW_SIDEBAR_VISIBILITY": {
      return {
        ...state,
        showSidebar: true
      };
    }
    case "HIDE_SIDEBAR_VISIBILITY": {
      return {
        ...state,
        showSidebar: false
      };
    }
    case "SHOW_RECENT_IMPORTS": {
      return {
        ...state,
        showImportWindow: true
      };
    }
    case "HIDE_RECENT_IMPORTS": {
      return {
        ...state,
        showImportWindow: false
      };
    }
    case "SHOW_OPEN_KINDLE_MODAL": {
      return {
        ...state,
        showOpenKindleWindow: true
      };
    }
    case "HIDE_OPEN_KINDLE_MODAL": {
      return {
        ...state,
        showOpenKindleWindow: false
      };
    }
    case "SET_KINDLE_MODAL_INFO": {
      return {
        ...state,
        kindleBookTitle: action.kindleBookTitle,
        kindleBookASIN: action.kindleBookASIN,
        kindleBookId: action.kindleBookId,
        kindleBookImgUrl: action.kindleBookImgUrl,
        kindleLocation: action.kindleLocation,
        kindleBookShsared: action.kindleBookShsared,
        kindleAnnotationId: action.kindleAnnotationId
      };
    }
    case "SHOW_CREATE_USERNAME_MODAL": {
      return {
        ...state,
        showCreateUsernameModal: true
      };
    }
    case "HIDE_CREATE_USERNAME_MODAL": {
      return {
        ...state,
        showCreateUsernameModal: false
      };
    }
    default:
      return state;
  }
}

export default uiReducer;
