import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Icon,
  List,
  Button,
  Grid,
  Form,
  Message,
  Responsive
} from "semantic-ui-react";
import { editNote as editNoteAction } from "actions/notesActions";

import "./Note.css";

class AnnotationNote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editingNote: false,
      noteContent: "",
      noteError: false
    };
  }

  _editNote = () => {
    const { annotationId, noteId, dateCreated, userId, editNote } = this.props;
    const { noteContent } = this.state;

    editNote(annotationId, noteId, noteContent, dateCreated, userId);
    this.setState({ editingNote: false, noteContent: "", noteError: false });
  };

  _handleChange(e) {
    let noteError = false;
    if (e.target.value.length > 500) {
      noteError = true;
    }
    this.setState({ noteContent: e.target.value, noteError });
  }

  render() {
    const { dateCreated, deleteNote, content, type } = this.props;
    const { noteError, editingNote } = this.state;

    return (
      <div className="note">
        <Responsive as={List.Item} {...Responsive.onlyMobile}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={2}>
                <List.Icon name="sticky note outline" />
              </Grid.Column>
              <Grid.Column width={6}>
                {dateCreated
                  ? moment(dateCreated).format("MM/DD/YYYY")
                  : "No Date"}
              </Grid.Column>
              <Grid.Column width={8}>
                {type === "custom" ? (
                  <List.Content floated="right">
                    <Button
                      icon
                      basic
                      size="tiny"
                      onClick={() =>
                        this.setState({ editingNote: !editingNote })
                      }
                      data-clickable="true"
                      data-location="note"
                      data-action="edit"
                      data-label={this.props.noteId}
                    >
                      <Icon
                        name="edit"
                        data-clickable="true"
                        data-location="note"
                        data-action="edit"
                        data-label={this.props.noteId}
                      />
                    </Button>
                    <Button
                      icon
                      basic
                      size="tiny"
                      onClick={() => {
                        deleteNote();
                      }}
                      data-clickable="true"
                      data-location="note"
                      data-action="delete"
                      data-label={this.props.noteId}
                    >
                      <Icon
                        name="delete"
                        color="red"
                        data-clickable="true"
                        data-location="note"
                        data-action="delete"
                        data-label={this.props.noteId}
                      />
                    </Button>
                  </List.Content>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                {editingNote ? (
                  <Form onSubmit={this._editNote} error={noteError}>
                    <Form.TextArea
                      onChange={e => this._handleChange(e)}
                      placeholder="Add your note here..."
                      defaultValue={content}
                    />
                    <Message
                      error
                      content="Note must be less than 500 characters."
                    />
                    <Form.Group>
                      <Form.Button disabled={noteError}>Submit</Form.Button>
                      <Button
                        content="Cancel"
                        onClick={() => this.setState({ editingNote: false })}
                      />
                    </Form.Group>
                  </Form>
                ) : (
                  <List.Content>{content}</List.Content>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>
        <Responsive as={List.Item} minWidth={768}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={1}>
                <List.Icon name="sticky note outline" />
              </Grid.Column>
              <Grid.Column width={2}>
                {dateCreated
                  ? moment(dateCreated).format("MM/DD/YYYY")
                  : "No Date"}
              </Grid.Column>
              <Grid.Column width={10}>
                {editingNote ? (
                  <Form onSubmit={this._editNote} error={noteError}>
                    <Form.TextArea
                      onChange={e => this._handleChange(e)}
                      placeholder="Add your note here..."
                      defaultValue={content}
                    />
                    <Message
                      error
                      content="Note must be less than 500 characters."
                    />
                    <Form.Group>
                      <Form.Button disabled={noteError}>Submit</Form.Button>
                      <Button
                        content="Cancel"
                        onClick={() => this.setState({ editingNote: false })}
                      />
                    </Form.Group>
                  </Form>
                ) : (
                  <List.Content>{content}</List.Content>
                )}
              </Grid.Column>

              <Grid.Column width={3}>
                {type === "custom" ? (
                  <List.Content floated="right">
                    <Button
                      icon
                      basic
                      onClick={() =>
                        this.setState({ editingNote: !editingNote })
                      }
                      data-clickable="true"
                      data-location="note"
                      data-action="edit"
                      data-label={this.props.noteId}
                    >
                      <Icon
                        name="edit"
                        data-clickable="true"
                        data-location="note"
                        data-action="edit"
                        data-label={this.props.noteId}
                      />
                    </Button>
                    <Button
                      icon
                      basic
                      onClick={() => {
                        deleteNote();
                      }}
                      data-clickable="true"
                      data-location="note"
                      data-action="delete"
                      data-label={this.props.noteId}
                    >
                      <Icon
                        name="delete"
                        color="red"
                        data-clickable="true"
                        data-location="note"
                        data-action="delete"
                        data-label={this.props.noteId}
                      />
                    </Button>
                  </List.Content>
                ) : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>
      </div>
    );
  }
}

export default connect(
  null,
  { editNote: editNoteAction }
)(AnnotationNote);
