import React from "react";
import PropTypes from "prop-types";
import { Menu } from "semantic-ui-react";

const BookList = props => {
  const { title, children } = props;
  return (
    <Menu.Item>
      <Menu.Header id="booksMenuHeader">{title}</Menu.Header>
      <Menu.Menu>{children}</Menu.Menu>
    </Menu.Item>
  );
};

BookList.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
export default BookList;
