import React from "react";
import PropTypes from "prop-types";
import { Grid, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import posed from "react-pose";
import PublicProfilePlaceholder from "modules/publicProfile/components/PublicProfilePlaceholder";

const Hover = posed.div({
  hoverable: true,
  init: {
    scale: 1,
    boxShadow: "0px 0px 0px rgba(0,0,0,0)",
    width: "103px",
    margin: "auto"
  },
  hover: {
    scale: 1.2,
    boxShadow: "0px 5px 10px rgba(0,0,0,0.2)"
  }
});

const ProfileBooks = ({ books, userStats }) => (
  <Grid columns={5} centered style={{ marginTop: "30px" }} doubling>
    {userStats && userStats.metricsPublicShareCount === 0 ? (
      <PublicProfilePlaceholder />
    ) : (
      books.map(book => (
        <Grid.Column key={book.id} textAlign="center">
          <Link to={{ pathname: `/books/${book.id}` }}>
            <Hover>
              <Image
                className="bookImage"
                style={{ margin: "auto" }}
                src={book.imgUrl}
                alt={book.title}
                data-clickable="true"
                data-location="publicProfile"
                data-action="openPublicBook"
                data-label={book.id}
              />
            </Hover>
          </Link>
          <div style={{ marginTop: "20px" }}>{book.title}</div>

          <div style={{ fontSize: ".75em", opacity: ".8" }}>{book.author}</div>
          <div style={{ fontSize: ".75em", opacity: ".8" }}>
            {book.annotationsCount} Annotations
          </div>
        </Grid.Column>
      ))
    )}
  </Grid>
);

ProfileBooks.propTypes = {
  books: PropTypes.arrayOf(PropTypes.object).isRequired,
  userStats: PropTypes.shape(PropTypes.numbers)
};

ProfileBooks.defaultProps = {
  userStats: {}
};

export default ProfileBooks;
