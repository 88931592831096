import { createSelector } from "reselect";
import keyBy from "lodash/keyBy";
import get from "lodash/get";
import { ModelsFriend } from "models";
import values from "lodash/values";
import filter from "lodash/filter";
import uniqBy from "lodash/uniqBy";
import map from "lodash/map";
import concat from "lodash/concat";
import { sortBy } from "lodash";
import sortAlphabeticalWithoutThe from "utils/sortAlphabeticalWithoutThe";

/* ex:
  {
      byId: { // Books by id
        bookId1: {
          ...bookAttrs
      },
      isFetching: false, // is the data set of books fetching
      recievedAt: Date.now(), // Timestamp, when did we recieve the data (for cache invalidation)
    }
  }
*/

const initialState = {
  byId: {},
  isFetching: false,
  receivedAt: null,
  selectedBookId: "",
  sharedItems: [],
  items: [],
  selectedSharedBookId: ""
};

function booksReducer(state = initialState, action) {
  switch (action.type) {
    case "FETCH_BOOKS": {
      return {
        ...state,
        isFetching: !state.receivedAt
      };
    }
    case "FETCH_BOOKS_FAILURE": {
      return {
        ...state,
        isFetching: false
      };
    }
    case "FETCH_BOOKS_SUCCESS": {
      return {
        ...state,
        byId: keyBy(concat(action.books, action.sharedBooks), "id"),
        isFetching: false,
        receivedAt: Date.now(),
        items: map(action.books, "id"),
        sharedItems: map(action.sharedBooks, "id"),
        selectedBookId: state.selectedBookId || get(action.books, "[0].id"),
        selectedSharedBookId:
          state.selectedSharedBookId || get(action.sharedBooks, "[0].id")
      };
    }
    case "FETCH_BOOK": {
      return {
        ...state,
        isFetching: !state.byId[action.resourceId]
      };
    }
    case "FETCH_BOOK_FAILURE": {
      return {
        ...state,
        isFetching: false
      };
    }
    case "FETCH_BOOK_SUCCESS": {
      return {
        ...state,
        byId: { ...state.byId, [action.book.id]: action.book },
        isFetching: false
      };
    }
    case "SET_SELECTED_BOOK": {
      return { ...state, selectedBookId: action.id };
    }
    case "SET_SELECTED_SHARED_BOOK": {
      return { ...state, selectedSharedBookId: action.id };
    }
    case "TOGGLE_PUBLIC_BOOK_FAILURE": {
      return {
        ...state,
        isFetching: false
      };
    }
    case "TOGGLE_PUBLIC_BOOK_SUCCESS": {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.resourceInstanceId]: {
            ...state.byId[action.resourceInstanceId],
            publicShare: action.publicShareState
          }
        },
        isFetching: false
      };
    }
    case "FETCH_PUBLIC_BOOKS": {
      return {
        ...state,
        isFetching: !state.receivedAt
      };
    }
    case "FETCH_PUBLIC_BOOKS_FAILURE": {
      return {
        ...state,
        isFetching: false
      };
    }
    case "FETCH_PUBLIC_BOOKS_SUCCESS": {
      return {
        ...state,
        byId: keyBy(action.publicBooks, "id"),
        isFetching: false
      };
    }
    default:
      return state;
  }
}

export default booksReducer;

export const selectBooks = state => {
  return state.books.byId;
};

export const selectBookItems = state => {
  return state.books.items;
};

export const selectSharedBookItems = state => {
  return state.books.sharedItems;
};

export const selectBooksList = createSelector([selectBooks], books =>
  filter(values(books), val => !val.isShared)
);

// Make sure everything gets cached correctly
export const selectSharedBooksList = createSelector([selectBooks], books =>
  filter(values(books), val => val.isShared)
);

export const selectFriends = createSelector(
  [selectSharedBooksList],
  sharedBooks =>
    map(uniqBy(sharedBooks, "ownerId"), val => {
      // Make not slow
      let friendsBooks = filter(sharedBooks, { ownerId: val.ownerId });
      friendsBooks = sortBy(friendsBooks, [
        book => sortAlphabeticalWithoutThe(book)
      ]);
      return new ModelsFriend(val.ownerId, val.ownerName, friendsBooks);
    })
);

export const selectBookById = (state, id) => {
  return state.books.byId[id];
};

export const selectPublicBooksByUsername = createSelector(
  [selectBooks, (_, username) => username],
  (books, username) =>
    filter(values(books), book => {
      return book.username === username;
    })
);
