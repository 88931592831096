export const FETCH_NOTES = "FETCH_NOTES";
export const FETCH_NOTES_FAILURE = "FETCH_NOTES_FAILURE";
export const FETCH_NOTES_SUCCESS = "FETCH_NOTES_SUCCESS";

export const fetchNotes = () => ({
  type: FETCH_NOTES
});

export const ADD_NOTE = "ADD_NOTE";
export const ADD_NOTE_FAILURE = "ADD_NOTE_FAILURE";
export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS";

export const addNote = (annotationId, noteContent, noteType) => ({
  type: ADD_NOTE,
  annotationId,
  noteContent,
  noteType
});

export const EDIT_NOTE = "EDIT_NOTE";
export const EDIT_NOTE_FAILURE = "EDIT_NOTE_FAILURE";
export const EDIT_NOTE_SUCCESS = "EDIT_NOTE_SUCCESS";

export const editNote = (annotationId, noteId, noteContent, dateCreated, userId) => ({
  type: EDIT_NOTE,
  noteId,
  noteContent,
  annotationId,
  dateCreated,
  userId
});

export const DELETE_NOTE = "DELETE_NOTE";
export const DELETE_NOTE_FAILURE = "DELETE_NOTE_FAILURE";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";

export const deleteNote = (annotationId, noteId) => ({
  type: DELETE_NOTE,
  annotationId,
  noteId
});
