import React from "react";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import ReactDOM from "react-dom";
import "./index.css";
import "semantic-ui-css/semantic.min.css";
import { Provider } from "react-redux";
import Routes from "screens/Routes";
import reducers from "reducers";
import rootSaga from "sagas";
import withAuthentication from "hoc/withAuthentication";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// mount it on the Store
const store = createStore(reducers, applyMiddleware(sagaMiddleware));

const AuthedRoutes = withAuthentication(Routes);
// replace with sagas
sagaMiddleware.run(rootSaga);
ReactDOM.render(
  <Provider store={store}>
    <AuthedRoutes />
  </Provider>,
  document.getElementById("root")
);
