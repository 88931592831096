class ModelsAnnotation {
  constructor(
    id,
    resourceInstanceId,
    bookId,
    content,
    dateCreated,
    dateModified,
    location,
    amazonId,
    pageNumber,
    note,
    type,
    publicShare,
    userId,
    favorite
  ) {
    this.id = id;
    this.resourceInstanceId = resourceInstanceId;
    this.bookId = bookId;
    this.content = content;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.location = location;
    this.amazonId = amazonId;
    this.pageNumber = pageNumber;
    this.note = note;
    this.type = type;
    this.publicShare = publicShare;
    this.userId = userId;
    this.favorite = favorite;
  }
}

export default ModelsAnnotation;
