import React, { Component } from "react";
import {
  Container,
  Segment,
  Button,
  Icon,
  Step,
  Responsive
} from "semantic-ui-react";
import PropTypes from "prop-types";
import "./Import.css";
import { connect } from "react-redux";
import { logOutUser } from "actions/authActions";
import { showRecentImports as showRecentImportsAction } from "actions/uiActions";
import { ModelsUser } from "models";

class Import extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(ModelsUser).isRequired,
    showImportWindow: PropTypes.bool.isRequired,
    installed: PropTypes.bool.isRequired,
    showRecentImports: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    const { installed } = props;

    this.state = {
      currentStep: installed ? 1 : 0,
      steps: {
        0: {
          name: "chromeExtensionInstall",
          completed: installed
        },
        1: { name: "openKindleNotebook", completed: false }
      }
    };
  }

  componentDidUpdate(prevProps) {
    const { installed } = this.props;
    const { currentStep } = this.state;
    if (installed !== prevProps.installed) {
      if (installed && currentStep === 0) {
        this.setState({ currentStep: 1 });
      }
    }
  }

  successCallback = () => {
    console.log("chrome extension install success");
    const { steps } = this.state;
    steps[0].completed = true;
    this.setState({ steps, currentStep: 1 });
  };

  failureCallback = failureReason => {
    console.log(`chrome extension install failed - ${failureReason}`);
    window.alert(`chrome extension install failed - ${failureReason}`);
  };

  returnContent = currentStep => {
    const { installed } = this.props;
    switch (currentStep) {
      case 0:
        return (
          <div>
            {installed === false && (
              <Button
                size="tiny"
                positive
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                href="https://chrome.google.com/webstore/detail/ehncpahhfadkdfneafgehhdfjfiddpje"
                data-location="importSteps"
                data-action="installChromeExtension"
                data-clickable="true"
              >
                Install our Chrome Extension
              </Button>
            )}
            {installed === true && (
              <span>Chrome Extension already installed</span>
            )}
            <Responsive {...Responsive.onlyMobile} className="mobileWarning">
              Chrome extensions are not available on mobile or tablets. Please
              use your computer to import notes.
            </Responsive>
          </div>
        );
      case 1:
        return (
          <div>
            <Button
              size="tiny"
              positive
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href="https://read.amazon.com/notebook"
              data-location="importSteps"
              data-action="kindleNotebookLink"
              data-clickable="true"
            >
              Open your Amazon Kindle Notebook
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  handleStepChange = (e, { value }) => {
    this.setState({ currentStep: value });
  };

  completeStep = (e, { value }) => {
    //  Set current step to done
    const { steps } = this.state;
    const { showRecentImports } = this.props;
    if (value === 1) {
      showRecentImports();
    } else {
      steps[value].completed = true;
      this.setState({ steps });
      // increment to next step
      this.setState({ currentStep: value + 1 });
    }
  };

  handleBackClip = (e, { value }) => {
    this.setState({ currentStep: value - 1 });
  };

  render() {
    const { currentStep, steps } = this.state;
    const { installed } = this.props;
    return (
      <div>
        <Container>
          <div className="importCanvass">
            <Step.Group attached="top" ordered size="mini">
              <Step
                active={currentStep === 0}
                completed={installed}
                value={0}
                onClick={this.handleStepChange}
                data-clickable="true"
                data-location="importSteps"
                data-action="clickStep"
                data-label={steps[0].name}
                data-value="0"
              >
                <Step.Content>
                  <Step.Title
                    data-clickable="true"
                    data-location="importSteps"
                    data-action="clickStep"
                    data-label={steps[0].name}
                    data-value="0"
                  >
                    Install Chrome Extension
                  </Step.Title>
                </Step.Content>
              </Step>

              <Step
                active={currentStep === 1}
                completed={steps[1].completed}
                value={1}
                onClick={this.handleStepChange}
                data-clickable="true"
                data-location="importSteps"
                data-action="clickStep"
                data-label={steps[1].name}
                data-value="1"
              >
                <Step.Content>
                  <Step.Title
                    data-clickable="true"
                    data-location="importSteps"
                    data-action="clickStep"
                    data-label={steps[1].name}
                    data-value="1"
                  >
                    Open Kindle Notebook
                  </Step.Title>
                </Step.Content>
              </Step>
            </Step.Group>

            <Segment attached>
              <div>
                <div className="stepContent">
                  {this.returnContent(currentStep)}
                </div>
                <div className="prevNext buttons">
                  {currentStep > 0 && (
                    <Button
                      size="tiny"
                      compact
                      value={currentStep}
                      onClick={this.handleBackClip}
                      data-clickable="true"
                      data-location="importSteps"
                      data-action="back"
                      data-label={steps[currentStep].name}
                      data-value={currentStep}
                    >
                      <Icon
                        name="left arrow"
                        data-clickable="true"
                        data-location="importSteps"
                        data-action="back"
                        data-label={steps[currentStep].name}
                        data-value={currentStep}
                      />
                      Back
                    </Button>
                  )}
                  {currentStep === 0 && (
                    <Button
                      size="tiny"
                      compact
                      value={currentStep}
                      onClick={this.completeStep}
                      data-location="importSteps"
                      data-action="next"
                      data-label={steps[currentStep].name}
                      data-value={currentStep}
                      data-clickable="true"
                    >
                      Next
                      <Icon
                        name="right arrow"
                        data-location="importSteps"
                        data-action="next"
                        data-label={steps[currentStep].name}
                        data-value={currentStep}
                        data-clickable="true"
                      />
                    </Button>
                  )}
                  {currentStep === 1 && (
                    <Button
                      size="tiny"
                      compact
                      value={currentStep}
                      onClick={this.completeStep}
                      data-location="importSteps"
                      data-action="done"
                      data-label={steps[currentStep].name}
                      data-value={currentStep}
                      data-clickable="true"
                    >
                      Done
                    </Button>
                  )}
                </div>
              </div>
            </Segment>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  installed: state.extension.installed,
  showImportWindow: state.ui.showImportWindow
});

export default connect(
  mapStateToProps,
  { logOutUser, showRecentImports: showRecentImportsAction }
)(Import);
