import React from "react";
import { Message } from "semantic-ui-react";
/* eslint arrow-body-style: [0] */

const UsernameErrorMessage = () => {
  return (
    <Message
      error
      header="Invalid Username"
      content="Usernames must be less than 15 characters and contain only letters and numbers."
    />
  );
};

export default UsernameErrorMessage;
