import React from "react";
import PublicBook from "modules/annotations/containers/PublicBook";
import LayoutsDetails from "layouts/details/Details";

const ScreensPublicBook = props => {
  return (
    <LayoutsDetails {...props}>
      <PublicBook {...props} />
    </LayoutsDetails>
  );
};

export default ScreensPublicBook;
