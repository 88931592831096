import React from "react";
import PropTypes from "prop-types";
import { Popup, Icon } from "semantic-ui-react";

const UsernamePopup = ({ username, isUsernameError }) => (
  <Popup
    trigger={<Icon name="question circle" />}
    content={
      <React.Fragment>
        <p>Your username is how people will find your public profile online.</p>
        <span style={{ color: "gray" }}>
          Example: http://app.owlandscroll.com/public/<strong>
            {username && !isUsernameError ? username : "kevin"}
          </strong>
        </span>
      </React.Fragment>
    }
  />
);

UsernamePopup.propTypes = {
  username: PropTypes.string.isRequired,
  isUsernameError: PropTypes.bool.isRequired
};

export default UsernamePopup;
