import React from "react";
import { Container, Menu, Image } from "semantic-ui-react";
import "./Details.css";
import PropTypes from "prop-types";
import withFullStoryIdentity from "hoc/withFullStoryIdentity";
import { connect } from "react-redux";

const LayoutsDetails = props => {
  const { children, isLoggedIn } = props;
  const loginButton = (
    <Menu.Item
      href="/login"
      className="logoText"
      position="right"
      data-clickable="true"
      data-location="logoHeader"
      data-action="logIn"
    >
      Login
    </Menu.Item>
  );

  const homeButton = (
    <Menu.Item
      href="/"
      className="logoText"
      position="right"
      data-clickable="true"
      data-location="logoHeader"
      data-action="goHome"
    >
      Home
    </Menu.Item>
  );
  return (
    <div>
      <Menu borderless className="logoHeader">
        <Menu.Item
          href="https://owlandscroll.com"
          target="_blank"
          rel="noopener noreferrer"
          className="logoText"
          data-clickable="true"
          data-location="logoHeader"
          data-action="goHome"
        >
          <Image
            size="mini"
            src="https://s3.amazonaws.com/countingcalculi-assets/magic_images/Owl_Scroll.png"
          />
          &nbsp;&nbsp;&nbsp;Owl & Scroll
        </Menu.Item>

        {!isLoggedIn ? loginButton : homeButton}
      </Menu>
      <Container id="detailsContainer" className="detailsContainer">
        {children}
      </Container>
    </div>
  );
};

LayoutsDetails.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isLoggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  {}
)(withFullStoryIdentity(LayoutsDetails));
