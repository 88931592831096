import {
  put,
  all,
  call,
  throttle,
  takeLatest,
  takeEvery
} from "redux-saga/effects";
import {
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  UPDATE_USERNAME,
  UPDATE_USERNAME_SUCCESS,
  UPDATE_USERNAME_FAILURE,
  GET_USERNAME,
  GET_USERNAME_SUCCESS,
  GET_USERNAME_FAILURE,
  FOLLOW_USER,
  FOLLOW_USER_FAILURE,
  FOLLOW_USER_SUCCESS,
  UNFOLLOW_USER,
  UNFOLLOW_USER_FAILURE,
  UNFOLLOW_USER_SUCCESS,
  GET_FOLLOWED,
  GET_FOLLOWED_SUCCESS,
  GET_FOLLOWED_FAILURE,
  GET_FOLLOWERS,
  GET_FOLLOWERS_SUCCESS,
  GET_FOLLOWERS_FAILURE,
  GET_USER_ID,
  GET_USER_ID_SUCCESS,
  GET_USER_ID_FAILURE,
  GET_USERNAME_BY_USER_ID,
  GET_USERNAME_BY_USER_ID_SUCCESS,
  GET_USERNAME_BY_USER_ID_FAILURE,
  GET_STATS,
  GET_STATS_SUCCESS,
  GET_STATS_FAILURE
} from "actions/authActions";
import { HIDE_CREATE_USERNAME_MODAL } from "actions/uiActions";
import { auth, firestore } from "fire";
import { toast } from "react-toastify";

function requestLogOutUser() {
  auth().signOut();
}

function* logOutUser() {
  try {
    yield call(requestLogOutUser);
    yield put({ type: LOGOUT_USER_SUCCESS });
    // Fix later
    window.location.reload();
  } catch (e) {
    yield put({ type: LOGOUT_USER_FAILURE });
    console.log(e);
  }
}

function requestUpdateUsername(userId, username, previousUsername) {
  const userRef = firestore()
    .collection("users")
    .doc(userId);
  const usernameRef = firestore()
    .collection("usernames")
    .doc(username);
  const previousUsernameRef = previousUsername
    ? firestore()
        .collection("usernames")
        .doc(previousUsername)
    : null;

  // User and username collection must be in sync, if both don't update concurrently
  // changes will be rolled back
  return firestore().runTransaction(transaction => {
    const updateUserDoc = transaction.update(userRef, {
      username
    });
    const createNewUsername = transaction.set(usernameRef, {
      userId
    });
    const allTransactions = [updateUserDoc, createNewUsername];

    if (previousUsername) {
      const deletePreviousUsername = transaction.delete(previousUsernameRef);
      allTransactions.push(deletePreviousUsername);
    }
    return Promise.all(allTransactions);
  });
}

function requestIsUsernameUnique(username) {
  // replace with firebase function
  return firestore()
    .collection("usernames")
    .doc(username)
    .get()
    .then(doc => {
      return !doc.exists;
    });
}

function* updateUsername(action) {
  try {
    const isUsernameUnique = yield call(
      requestIsUsernameUnique,
      action.username,
      action.previousUsername
    );
    if (isUsernameUnique) {
      yield call(
        requestUpdateUsername,
        action.userId,
        action.username,
        action.previousUsername
      );
      yield put({
        type: UPDATE_USERNAME_SUCCESS,
        username: action.username
      });
      yield put({
        type: HIDE_CREATE_USERNAME_MODAL
      });
      toast.success("Username updated!", {
        position: toast.POSITION.TOP_CENTER
      });
    } else {
      toast.error("Username exists", {
        position: toast.POSITION.TOP_CENTER
      });
    }
  } catch (e) {
    yield put({ type: UPDATE_USERNAME_FAILURE, payload: e.message });
    toast.error(
      "There was an error updating your username. Please try again.",
      {
        position: toast.POSITION.TOP_CENTER
      }
    );
    console.log(e);
  }
}

// maybe this should set entire user object so theres one source of truth
const requestUsername = () => {
  const userId = auth().currentUser.uid;

  return firestore()
    .collection("users")
    .doc(userId)
    .get()
    .then(doc => {
      const userData = doc.data();
      return userData.username === undefined ? null : userData.username;
    });
};

function* getUsername() {
  try {
    const username = yield call(requestUsername);
    yield put({ type: GET_USERNAME_SUCCESS, username });
  } catch (e) {
    yield put({ type: GET_USERNAME_FAILURE });
    console.log(e);
  }
}

function getUserIdByUsername(username) {
  return firestore()
    .collection("usernames")
    .doc(username)
    .get()
    .then(doc => (doc.exists ? doc.data().userId : null));
}

function requestFollowUser(targetUserId) {
  const userId = auth().currentUser.uid;
  return firestore()
    .collection("relationships")
    .doc(`${userId}-${targetUserId}`)
    .set({
      followedId: targetUserId,
      followerId: userId,
      createdAt: Date.now()
    });
}

function* followUser(action) {
  try {
    const targetUserId = yield call(getUserIdByUsername, action.username);
    yield call(requestFollowUser, targetUserId);
    yield put({
      type: FOLLOW_USER_SUCCESS,
      followed: targetUserId,
      userId: auth().currentUser.uid
    });
  } catch (e) {
    yield put({ type: FOLLOW_USER_FAILURE, payload: e.message });
    console.log(e);
  }
}

function requestUnfollowUser(targetUserId) {
  const userId = auth().currentUser.uid;
  return firestore()
    .collection("relationships")
    .doc(`${userId}-${targetUserId}`)
    .delete();
}

function* unfollowUser(action) {
  try {
    const targetUserId = yield call(getUserIdByUsername, action.username);
    yield call(requestUnfollowUser, targetUserId);
    yield put({
      type: UNFOLLOW_USER_SUCCESS,
      unfollowed: targetUserId,
      userId: auth().currentUser.uid
    });
  } catch (e) {
    yield put({ type: UNFOLLOW_USER_FAILURE, payload: e.message });
    console.log(e);
  }
}

const requestFollowed = userId => {
  return firestore()
    .collection("relationships")
    .where("followerId", "==", userId)
    .get()
    .then(querySnapshot => {
      const followed = [];
      // should be mapping
      querySnapshot.forEach(function(doc) {
        if (doc.exists) {
          const follow = doc.data();
          followed.push(follow.followedId);
        }
      });
      return followed;
    });
};

function* getFollowed(action) {
  try {
    const userId = yield call(getUserIdByUsername, action.username);
    const followed = yield call(requestFollowed, userId);
    yield put({ type: GET_FOLLOWED_SUCCESS, userId, followed });
  } catch (e) {
    yield put({ type: GET_FOLLOWED_FAILURE });
    console.log(e);
  }
}

const requestFollowers = userId => {
  return firestore()
    .collection("relationships")
    .where("followedId", "==", userId)
    .get()
    .then(querySnapshot => {
      const followers = [];
      // should be mapping
      querySnapshot.forEach(function(doc) {
        if (doc.exists) {
          const follow = doc.data();
          followers.push(follow.followerId);
        }
      });
      return followers;
    });
};

function* getFollowers(action) {
  try {
    const userId = yield call(getUserIdByUsername, action.username);
    const followers = yield call(requestFollowers, userId);
    yield put({ type: GET_FOLLOWERS_SUCCESS, userId, followers });
  } catch (e) {
    yield put({ type: GET_FOLLOWERS_FAILURE });
    console.log(e);
  }
}

function* getUserId(action) {
  try {
    const userId = yield call(getUserIdByUsername, action.username);
    yield put({
      type: GET_USER_ID_SUCCESS,
      username: action.username,
      userId
    });
  } catch (e) {
    yield put({ type: GET_USER_ID_FAILURE });
    console.log(e);
  }
}

const requestUsernameByUserId = userId => {
  return firestore()
    .collection("usernames")
    .where("userId", "==", userId)
    .limit(1)
    .get()
    .then(querySnapshot => {
      let username = null;
      querySnapshot.forEach(function(doc) {
        if (doc.exists) {
          username = doc.id
        }
      });
      return username;
      // const userData = doc.data();
      // return userData.username === undefined ? null : userData.username;
    });
};

function* getUsernameByUserId(action) {
  try {
    const username = yield call(requestUsernameByUserId, action.userId);
    yield put({
      type: GET_USERNAME_BY_USER_ID_SUCCESS,
      username,
      userId: action.userId
    });
  } catch (e) {
    yield put({ type: GET_USERNAME_BY_USER_ID_FAILURE });
    console.log(e);
  }
}

const requestStats = userId => {
  return firestore()
    .collection("userMetrics")
    .doc(userId)
    .get()
    .then(doc => doc.data());
};

function* getStats(action) {
  try {
    const userId = yield action.userId
      ? action.userId
      : call(getUserIdByUsername, action.username);
    const stats = yield call(requestStats, userId);
    yield put({ type: GET_STATS_SUCCESS, stats, userId });
  } catch (e) {
    yield put({ type: GET_STATS_FAILURE });
    console.log(e);
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(LOGOUT_USER, logOutUser),
    takeLatest(UPDATE_USERNAME, updateUsername),
    takeEvery(GET_USERNAME, getUsername),
    throttle(1000, FOLLOW_USER, followUser),
    throttle(1000, UNFOLLOW_USER, unfollowUser),
    takeLatest(GET_FOLLOWED, getFollowed),
    takeLatest(GET_FOLLOWERS, getFollowers),
    takeEvery(GET_USER_ID, getUserId),
    takeEvery(GET_USERNAME_BY_USER_ID, getUsernameByUserId),
    takeEvery(GET_STATS, getStats)
  ]);
}
