import React from "react";
import { Loader } from "semantic-ui-react";

const Loading = props => {
  return (
    <Loader active inline="centered">
      Loading
    </Loader>
  );
};

export default Loading;
