import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Image } from "semantic-ui-react";

const OpenKindleModal = props => {
  const {
    open,
    hideOpenKindleModal,
    ASIN,
    bookId,
    bookTitle,
    shared,
    imgUrl,
    location
  } = props;

  const standardDescription = (
    <div>
      <p>
        <i>{bookTitle}</i> will now open in your Kindle app at location{" "}
        {location}.
      </p>
      <p>
        If it doesn&apos;t, make sure you have the Kindle app installed and that
        you own the Kindle version of the book.
      </p>
    </div>
  );

  const sharedDescription = (
    <div>
      <div>
        To open this highlight in the Kindle app you have to own the Kindle
        version of{" "}
        <a
          href={`https://www.amazon.com/dp/${ASIN}/?tag=kevinmclaughl-20`}
          target="_blank"
          rel="noopener noreferrer"
          data-clickable="true"
          data-location="openKindleModal"
          data-action="buyOnAmazon"
          data-label={bookId}
        >
          <i>{bookTitle}</i>
        </a>{" "}
        and install the app.
      </div>
      <div>
        If you already own the Kindle book and have the app installed, the
        highlight should open now at location {location}.
      </div>
    </div>
  );

  const standardActions = (
    <Modal.Actions>
      <Button
        href="https://www.amazon.com/kindle-dbs/fd/kcp"
        target="_blank"
        as="a"
        positive
        rel="noopener noreferrer"
        data-clickable="true"
        data-location="openKindleModal"
        data-action="installKindleApp"
        data-label={bookId}
      >
        Install Kindle App
      </Button>
      <Button
        href={`https://www.amazon.com/dp/${ASIN}/?tag=kevinmclaughl-20`}
        target="_blank"
        rel="noopener noreferrer"
        as="a"
        data-clickable="true"
        data-location="openKindleModal"
        data-action="buyOnAmazon"
        data-label={bookId}
      >
        Buy Kindle Version
      </Button>
    </Modal.Actions>
  );

  const sharedActions = (
    <Modal.Actions>
      <Button
        href={`https://www.amazon.com/dp/${ASIN}/?tag=kevinmclaughl-20`}
        target="_blank"
        rel="noopener noreferrer"
        as="a"
        positive
        data-clickable="true"
        data-location="openKindleModal"
        data-action="buyOnAmazon"
        data-label={bookId}
      >
        Buy Kindle Version
      </Button>
      <Button
        href="https://www.amazon.com/kindle-dbs/fd/kcp"
        target="_blank"
        as="a"
        rel="noopener noreferrer"
        data-clickable="true"
        data-location="openKindleModal"
        data-action="installKindleApp"
        data-label={bookId}
      >
        Install Kindle App
      </Button>
    </Modal.Actions>
  );

  return (
    <Modal
      open={open}
      closeOnEscape
      closeOnRootNodeClick
      onClose={hideOpenKindleModal}
      closeIcon
      className="kindleModal"
    >
      <Modal.Header>Open in Kindle</Modal.Header>
      <Modal.Content image scrolling>
        <Image wrapped size="small" bordered src={imgUrl} />
        <Modal.Description>
          {shared ? sharedDescription : standardDescription}
        </Modal.Description>
      </Modal.Content>
      {shared ? sharedActions : standardActions}
    </Modal>
  );
};

OpenKindleModal.propTypes = {
  ASIN: PropTypes.string,
  bookId: PropTypes.string,
  bookTitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
  hideOpenKindleModal: PropTypes.func.isRequired,
  shared: PropTypes.bool.isRequired,
  imgUrl: PropTypes.string,
  location: PropTypes.number
};

OpenKindleModal.defaultProps = {
  bookId: undefined,
  ASIN: undefined,
  bookTitle: undefined,
  imgUrl: undefined,
  location: undefined
};

export default OpenKindleModal;
