import React from "react";
import PropTypes from "prop-types";
import { Header, Icon } from "semantic-ui-react";
import FollowButton from "components/FollowButton";
import { Grid } from "semantic-ui-react";

const underlineStyle = "2px solid gray";

const ProfileHeader = ({
  username,
  isLoggedIn,
  isFollowingUser,
  showFollow,
  followUser,
  unfollowUser,
  setSelectedState,
  selected,
  numberOfFollowers,
  numberOfFollowed,
  userStats
}) => {
  return (
    <Header
      as="h2"
      icon
      textAlign="center"
      style={{ maxWidth: "450px", padding: "24px", textAlign: "left" }}
    >
      <Grid>
        <Grid.Column width={12}>
          <Grid.Row verticalAlign="middle" style={{ marginBottom: "4px" }}>
            <span style={{ fontSize: "36px" }}>{username}</span>
            {showFollow && (
              <FollowButton
                isFollowingUser={isFollowingUser}
                followUser={followUser}
                unfollowUser={unfollowUser}
                isLoggedIn={isLoggedIn}
                username={username}
              />
            )}
          </Grid.Row>
          <Grid.Row>
            <Header.Subheader>
              <Grid.Row>
                <span
                  onClick={() => setSelectedState("following")}
                  style={{
                    marginRight: "5px",
                    cursor: "pointer",
                    textDecoration: "none",
                    borderBottom:
                      selected === "following" ? underlineStyle : "none"
                  }}
                  data-clickable="true"
                  data-location="publicProfile"
                  data-action="showFollowing"
                >
                  {`${numberOfFollowed} Following`}
                </span>
                |
                <span
                  onClick={() => setSelectedState("followers")}
                  style={{
                    marginLeft: "5px",
                    cursor: "pointer",
                    textDecoration: "none",
                    borderBottom:
                      selected === "followers" ? underlineStyle : "none"
                  }}
                  data-clickable="true"
                  data-location="publicProfile"
                  data-action="showFollowers"
                  data-label={username}
                >
                  {`${numberOfFollowers} Followers`}
                </span>
              </Grid.Row>
              <Grid.Row style={{ marginTop: "5px" }}>
                <span
                  onClick={() => setSelectedState("books")}
                  style={{
                    marginRight: "5px",
                    cursor: "pointer",
                    textDecoration: "none",
                    borderBottom: selected === "books" ? underlineStyle : "none"
                  }}
                  data-clickable="true"
                  data-location="publicProfile"
                  data-action="showPublicBooks"
                  data-label={username}
                >
                  {`${userStats.metricsPublicShareCount} Public Books`}
                </span>
                |
                <span style={{ marginLeft: "5px" }}>{`${
                  userStats.metricsResourceInstanceCount
                } Books`}</span>
              </Grid.Row>
              <Grid.Row style={{ marginTop: "5px" }}>
                <span>{`${userStats.metricsAnnotationCount} Annotations`}</span>
              </Grid.Row>
            </Header.Subheader>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={4} verticalAlign="middle">
          <Icon name="book" />
        </Grid.Column>
      </Grid>
    </Header>
  );
};

ProfileHeader.propTypes = {
  username: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isFollowingUser: PropTypes.bool.isRequired,
  showFollow: PropTypes.bool.isRequired,
  followUser: PropTypes.func.isRequired,
  unfollowUser: PropTypes.func.isRequired,
  setSelectedState: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  numberOfFollowers: PropTypes.number.isRequired,
  numberOfFollowed: PropTypes.number.isRequired,
  userStats: PropTypes.shape(PropTypes.number).isRequired
};

export default ProfileHeader;
