import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Radio, Image } from "semantic-ui-react";
import { ModelsBook } from "models";

const EvernoteSaveDescription = ({
  bookInstance,
  displayError,
  displayMessage,
  loading,
  writeToEvernote,
  exportIndividually,
  selectExportIndividually,
  selectExportAll
}) => (
  <React.Fragment>
    <Modal.Content image scrolling>
      <Image wrapped size="small" bordered src={bookInstance.imgUrl} />
      <Modal.Description>
        <div>
          <Form className="exportOptions">
            <Form.Field style={{ display: "flex", paddingLeft: "0px" }}>
              <span style={{ alignSelf: "center" }}>Export as</span>
            </Form.Field>
            <Form.Field>
              <Radio
                label="One Note per Highlight"
                name="radioGroup"
                value="this"
                checked={exportIndividually}
                onChange={selectExportIndividually}
                data-clickable="true"
                data-location="evernoteExportModal"
                data-action="notePerHighlight"
                data-label={bookInstance.id}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label="One Note per Book"
                name="radioGroup"
                value="that"
                checked={!exportIndividually}
                onChange={selectExportAll}
                data-clickable="true"
                data-location="evernoteExportModal"
                data-action="notePerBook"
                data-label={bookInstance.id}
              />
            </Form.Field>
          </Form>
        </div>
        <div
          style={
            displayError ? { color: "red" } : { color: "rgba(0, 0, 0, 0.6)" }
          }
        >
          {displayMessage}
        </div>
      </Modal.Description>
    </Modal.Content>

    <Modal.Actions>
      <Button
        basic
        disabled={loading}
        color="green"
        compact
        size="tiny"
        onClick={writeToEvernote}
        data-clickable="true"
        data-location="evernoteExportModal"
        data-action="saveToEvernote"
        className="exportButton"
      >
        {loading ? "Saving..." : "Save to Evernote"}
      </Button>
    </Modal.Actions>
  </React.Fragment>
);

EvernoteSaveDescription.propTypes = {
  bookInstance: PropTypes.instanceOf(ModelsBook).isRequired,
  displayError: PropTypes.bool.isRequired,
  displayMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  writeToEvernote: PropTypes.func.isRequired,
  exportIndividually: PropTypes.bool.isRequired,
  selectExportIndividually: PropTypes.func.isRequired,
  selectExportAll: PropTypes.func.isRequired
};

export default EvernoteSaveDescription;
