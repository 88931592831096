import React from "react";
import PropTypes from "prop-types";

const AnnotationTitle = props => {
  const { type, location, ASIN, annotationId, handleKindleLink } = props;
  let highlightTitle;
  if (type === "amzn_highlight") {
    highlightTitle = "Kindle Highlight";
  }
  if (type === "amzn_note") {
    highlightTitle = "Kindle Note";
  }
  return (
    <React.Fragment>
      <span>{highlightTitle}</span>
      &nbsp;&nbsp;&nbsp;
      <span className="location">
        <a
          href={`kindle://book?action=open&asin=${ASIN}&location=${location}`}
          onClick={handleKindleLink}
          data-clickable="true"
          data-location="annotationToolbar"
          data-action="showInKindle"
          data-label={annotationId}
        >
          Kindle Location {location}
        </a>
      </span>
    </React.Fragment>
  );
};

AnnotationTitle.propTypes = {
  type: PropTypes.string.isRequired,
  ASIN: PropTypes.string.isRequired,
  annotationId: PropTypes.string.isRequired,
  location: PropTypes.number.isRequired,
  handleKindleLink: PropTypes.func.isRequired
};

export default AnnotationTitle;
