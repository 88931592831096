import React from "react";
import { connect } from "react-redux";
import { auth } from "fire";

const withAuthentication = Component => {
  class Authentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isInitialized: false
      };
    }

    componentDidMount() {
      auth().onAuthStateChanged(() => {
        this.setState({ isInitialized: true });
      });
    }

    render() {
      const { isInitialized } = this.state;
      return isInitialized ? <Component {...this.props} /> : null;
    }
  }
  return connect(null)(Authentication);
};

export default withAuthentication;
