import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { showCreateUsernameModal as showCreateUsernameModalAction } from "actions/uiActions";
import PublicLinkButtons from "modules/annotations/components/annotationListHeader/PublicLinkButtons";

class PublicLinkButtonsContainer extends Component {
  static propTypes = {
    username: PropTypes.string,
    showCreateUsernameModal: PropTypes.func.isRequired,
    togglePublicBook: PropTypes.func.isRequired,
    bookId: PropTypes.string.isRequired,
    publicShare: PropTypes.bool.isRequired
  };

  static defaultProps = { username: null };

  handleSharePublicBook = () => {
    const {
      username,
      showCreateUsernameModal,
      togglePublicBook,
      bookId
    } = this.props;
    if (username == null) {
      // if we have fetched username and it doesn't exist -- show modal
      showCreateUsernameModal();
    } else {
      togglePublicBook(bookId, true);
    }
  };

  render() {
    const { bookId, publicShare, togglePublicBook } = this.props;

    return (
      <PublicLinkButtons
        bookId={bookId}
        publicShare={publicShare}
        togglePublicBook={togglePublicBook}
        handleSharePublicBook={this.handleSharePublicBook}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    username: get(state, "auth.user.username", null)
  };
}
export default connect(
  mapStateToProps,
  {
    showCreateUsernameModal: showCreateUsernameModalAction
  }
)(PublicLinkButtonsContainer);
