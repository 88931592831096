import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import findKey from "lodash/findKey";
import get from "lodash/get";
import {
  getUsernameByUserId as getUsernameByUserIdAction,
  getStats as getStatsAction
} from "actions/authActions";
import UserCard from "modules/publicProfile/components/UserCard";
import withLoading from "hoc/withLoading";

const UserCardWithLoading = withLoading(UserCard);

class UserCardContainer extends Component {
  static propTypes = {
    getUsernameByUserId: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    usernameMap: PropTypes.objectOf(PropTypes.string).isRequired,
    getStats: PropTypes.func.isRequired,
    userStats: PropTypes.shape(PropTypes.number).isRequired,
    userStatsLoaded: PropTypes.bool.isRequired
  };

  componentDidMount() {
    const { getUsernameByUserId, userId, getStats } = this.props;
    getUsernameByUserId(userId);
    getStats({ userId });
  }

  render() {
    const { userId, usernameMap, userStats, userStatsLoaded } = this.props;
    const username = findKey(usernameMap, (k, v) => {
      return k === userId;
    });
    return (
      <UserCardWithLoading
        username={username}
        isFetching={!userStatsLoaded}
        userId={userId}
        userStats={userStats}
      />
    );
  }
}

function mapStateToProps(state, props) {
  const userStats = get(state, `auth.statsByUserId.${props.userId}`);
  return {
    usernameMap: state.auth.usernameMap,
    userStats: userStats || {},
    userStatsLoaded: !!userStats
  };
}

export default connect(
  mapStateToProps,
  {
    getUsernameByUserId: getUsernameByUserIdAction,
    getStats: getStatsAction
  }
)(UserCardContainer);
