import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ModelsUser } from "models";
import SettingsForm from "modules/settings/components/SettingsForm";
import { updateUsername as updateUsernameAction } from "actions/authActions";
import get from "lodash/get";

class Settings extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(ModelsUser),
    updateUsername: PropTypes.func.isRequired
  };

  static defaultProps = {
    user: null
  };

  render() {
    const { user, updateUsername } = this.props;
    const userId = get(user, "id");
    const username = get(user, "username");
    const email = get(user, "email");

    return (
      <SettingsForm
        userId={userId}
        username={username}
        email={email}
        updateUsername={updateUsername}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user
  };
}

export default connect(
  mapStateToProps,
  { updateUsername: updateUsernameAction }
)(Settings);
