import React from "react";
import PropTypes from "prop-types";
import { List } from "semantic-ui-react";
import { Link } from "react-router-dom";

const UserCard = ({ username, userStats }) => (
  <List.Item
    style={{
      marginTop: "10px"
    }}
  >
    <Link to={`/public/${username}`}>
      <List.Icon name="user circle" size="large" verticalAlign="middle" />
      <List.Content>
        <List.Header style={{ color: "#4183c4" }}>{username}</List.Header>
        <span style={{ fontSize: "50%" }}>
          {`${userStats.metricsResourceInstanceCount} Books | ${
            userStats.metricsAnnotationCount
          } Annotations`}{" "}
        </span>
      </List.Content>
    </Link>
  </List.Item>
);

UserCard.propTypes = {
  username: PropTypes.string.isRequired
};

export default UserCard;
