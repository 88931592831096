const sortAlphabeticalWithoutThe = book => {
  /* Sorts a list of book alphabetically but ignoring "The " at the beginning of the title */
  let title = book.title.toLowerCase();
  if (title.substring(0, 4) === "the ") {
    title = title.slice(4);
  }
  return title;
};

export default sortAlphabeticalWithoutThe;
