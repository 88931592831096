import React from "react";
import PropTypes from "prop-types";
import { List } from "semantic-ui-react";
import AnnotationNote from "modules/annotation/note/Note";
import map from "lodash/map";
import { ModelsNote } from "models";

const AnnotationNoteList = props => {
  const { note, customNotes, annotationId, deleteNote } = props;
  const hasNotes = note || customNotes.length > 0;

  return hasNotes ? (
    <List divided relaxed>
      <List.Header>Notes</List.Header>
      <br />
      {note && (
        <AnnotationNote
          key={note.amazonId}
          noteId={note.amazonId}
          content={note.content}
          dateCreated={note.dateCreated}
        />
      )}
      {map(customNotes, val => {
        return (
          <AnnotationNote
            key={val.id}
            noteId={val.id}
            content={val.content}
            dateCreated={val.dateCreated}
            deleteNote={() => {
              deleteNote(annotationId, val.id);
            }}
            annotationId={annotationId}
            type={val.type}
            userId={val.userId}
          />
        );
      })}
    </List>
  ) : null;
};

AnnotationNoteList.propTypes = {
  note: PropTypes.shape({
    amazonId: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
  }),
  customNotes: PropTypes.arrayOf(PropTypes.instanceOf(ModelsNote)).isRequired,
  annotationId: PropTypes.string.isRequired,
  deleteNote: PropTypes.func.isRequired
};

AnnotationNoteList.defaultProps = {
  note: null
};

export default AnnotationNoteList;
