export const UPDATE_INSTALLED = "UPDATE_INSTALLED";
export const updateInstalled = installed => ({
  type: UPDATE_INSTALLED,
  installed
});

export const ADD_TO_RECENT_IMPORTS = "ADD_TO_RECENT_IMPORTS";
export const addToRecentImports = (resourceId, numberOfAnnotations) => ({
  type: ADD_TO_RECENT_IMPORTS,
  resourceId,
  numberOfAnnotations
});
