import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Message } from "semantic-ui-react";
import { auth } from "fire";
import config from "config";

class ShareBookModal extends Component {
  static propTypes = {
    bookId: PropTypes.string,
    toggleModal: PropTypes.func.isRequired
  };

  static defaultProps = {
    bookId: undefined
  };

  state = {
    email: "",
    emailError: false,
    loading: false
  };

  _handleSubmit() {
    this.setState({ loading: true });
    const { email } = this.state;
    const { bookId } = this.props;
    const apiURL = config.apiUrl;
    auth()
      .currentUser.getIdToken()
      .then(token =>
        fetch(`${apiURL}/api/share-book`, {
          body: JSON.stringify({ email, bookId }),
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json"
          },
          method: "POST"
        })
          .then(() => this.props.toggleModal())
          .catch(e => console.log(e))
          .finally(() => this.setState({ loading: false }))
      );
  }

  _handleChange(e) {
    let emailError = false;
    if (!this._validateEmail(e.target.value)) {
      emailError = true;
    }
    this.setState({ email: e.target.value, emailError });
  }

  // TODO: Abstract out form validation
  _validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render() {
    const { open, toggleModal, bookName } = this.props;
    const { emailError, email, loading } = this.state;
    return (
      <Modal
        open={open}
        closeOnEscape
        closeOnRootNodeClick={false}
        onClose={toggleModal}
        closeIcon
        className="shareModal"
      >
        <Modal.Header>Share your book.</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div>
              Want to share these notes with a friend? Simply enter their email
              and they will have access to all your notes from{" "}
              {bookName || "this book"}.
            </div>
            <div style={{ marginTop: "10px" }}>
              <Form onSubmit={() => this._handleSubmit()} error={emailError}>
                <Form.Input
                  value={email}
                  onChange={e => this._handleChange(e)}
                  placeholder="YourFriend@example.com"
                  width={6}
                />
                <Message error content="Please enter a valid email address." />
                <Form.Button
                  content={loading ? "Sending..." : "Submit"}
                  disabled={loading}
                  data-clickable="true"
                  data-location="ShareBookModal"
                  data-action="shareSubmit"
                  data-label={this.props.bookId}
                />
              </Form>
            </div>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default ShareBookModal;
