import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import isProd from "utils/isProd";
import ModelsUser from "models/User";

const withFullStoryIdentity = Component => {
  class FullStoryIdentity extends React.Component {
    isProd = isProd(window.location.hostname);

    static propTypes = {
      user: PropTypes.instanceOf(ModelsUser)
    };

    static defaultProps = {
      user: null
    };

    componentDidMount() {
      const { user } = this.props;
      if (user) {
        this.identify();
      }
    }

    componentDidUpdate(prevProps) {
      const { user } = this.props;
      if (prevProps.user && !user && this.isProd) {
        window.FS.clearUserCookie();
      } else if (!prevProps.user && user) {
        this.identify();
      }
      return null;
    }

    identify() {
      const { user } = this.props;
      // full story only works in prod
      if (this.isProd) {
        window.FS.identify(user.id, {
          email: user.email
        });
      }
      window.LogRocket.identify(user.id, {
        email: user.email
      });
    }

    render() {
      const { user, ...rest } = this.props;
      return <Component {...rest} />;
    }
  }

  const mapStateToProps = state => ({ user: state.auth.user });

  return connect(
    mapStateToProps,
    {}
  )(FullStoryIdentity);
};

export default withFullStoryIdentity;
