import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Image } from "semantic-ui-react";
import { ModelsBook } from "models";

const EvernoteAuthDescription = ({
  bookInstance,
  displayError,
  displayMessage,
  loading,
  apiURL,
  firebaseToken
}) => (
  <React.Fragment>
    <Modal.Content image scrolling>
      <Image wrapped size="small" bordered src={bookInstance.imgUrl} />
      <Modal.Description>
        <div>Connect your Evernote to Owl & Scroll</div>
        <div
          style={
            displayError ? { color: "red" } : { color: "rgba(0, 0, 0, 0.6)" }
          }
        >
          {displayMessage}
        </div>
      </Modal.Description>
    </Modal.Content>

    <Modal.Actions>
      <Button
        basic
        as="a"
        disabled={loading}
        href={`${apiURL}/api/evernote-login/?token=${firebaseToken}`}
        target="_blank"
        color="blue"
        compact
        size="tiny"
        data-clickable="true"
        data-location="evernoteExportModal"
        data-action="loginToEvernote"
        data-label={bookInstance.id}
      >
        {loading ? "Loading..." : "Login to Evernote"}
      </Button>
    </Modal.Actions>
  </React.Fragment>
);

EvernoteAuthDescription.propTypes = {
  bookInstance: PropTypes.instanceOf(ModelsBook).isRequired,
  displayError: PropTypes.bool.isRequired,
  displayMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  apiURL: PropTypes.string.isRequired
};

export default EvernoteAuthDescription;
