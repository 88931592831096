import React, { Component } from "react";
import { connect } from "react-redux";
import { Card } from "semantic-ui-react";
import AnnotationToolbar from "modules/annotation/toolbar/Toolbar";
import AnnotationTitle from "modules/annotations/components/AnnotationTitle";
import AnnotationAddNote from "modules/annotation/addNote/AddNote";
import NoteList from "modules/annotation/noteList/NoteList";
import {
  deleteNote as deleteNoteAction,
  addNote as addNoteAction
} from "actions/notesActions";
import { selectNotesList } from "reducers/notes";
import "./Annotation.css";
import {
  setKindleModalInfo as setKindleModalInfoAction,
  showOpenKindleModal as showOpenKindleModalAction
} from "actions/uiActions";

class Annotation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addingNote: false
    };
  }

  toggleAddingNote = () => {
    this.setState({ addingNote: !this.state.addingNote });
  };

  handleKindleLink = () => {
    const {
      showOpenKindleModal,
      setKindleModalInfo,
      title,
      ASIN,
      bookId,
      book,
      location,
      shared,
      annotiationId
    } = this.props;
    setKindleModalInfo(
      title,
      ASIN,
      bookId,
      book.imgUrl,
      location,
      shared,
      annotiationId
    );
    showOpenKindleModal();
  };

  render() {
    const {
      annotationId,
      location,
      note,
      content,
      customNotes,
      order,
      ASIN,
      bookId,
      shared,
      type,
      deleteNote,
      addNote,
      isLoading,
      selectedAnnotation,
      publicShare,
      favorite
    } = this.props;
    const { addingNote } = this.state;

    return (
      <Card
        fluid
        raised
        className="annotation"
        data-clickable="false"
        data-location="annotationWindow"
        data-action="annotationLoaded"
        data-label={annotationId}
        data-value={order}
      >
        <Card.Content>
          <Card.Meta>
            <AnnotationTitle
              type={type}
              location={location}
              ASIN={ASIN}
              annotationId={annotationId}
              handleKindleLink={this.handleKindleLink}
            />
          </Card.Meta>
          <br />
          <div className="annotationContent">{content}</div>
          <br />
          <NoteList
            note={note}
            customNotes={customNotes}
            annotationId={annotationId}
            deleteNote={deleteNote}
          />
          <AnnotationAddNote
            isLoading={isLoading}
            selectedAnnotation={selectedAnnotation}
            annotationId={annotationId}
            toggleAddingNote={this.toggleAddingNote}
            addingNote={addingNote}
            addNote={addNote}
          />
        </Card.Content>
        <AnnotationToolbar
          annotationId={annotationId}
          ASIN={ASIN}
          bookId={bookId}
          location={location}
          order={order}
          publicShare={publicShare}
          shared={shared}
          showNote={!shared}
          fullPage={false}
          toggleAddingNote={this.toggleAddingNote}
          handleKindleLink={this.handleKindleLink}
          favorite={favorite}
          content={content}
        />
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  isLoading: state.notes.isFetching,
  selectedAnnotation: state.notes.selectedAnnotation,
  notes: selectNotesList(state)
});

export default connect(
  mapStateToProps,
  {
    setKindleModalInfo: setKindleModalInfoAction,
    showOpenKindleModal: showOpenKindleModalAction,
    addNote: addNoteAction,
    deleteNote: deleteNoteAction
  }
)(Annotation);
