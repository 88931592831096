import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchNotes as fetchNotesAction } from "actions/notesActions";
import { fetchAnnotations as fetchAnnotationsAction } from "actions/annotationsActions";
import AnnotationList from "modules/annotations/components/annotationList/AnnotationList";
import searchAnnotations from "utils/searchAnnotations";
import { selectBookById } from "reducers/books";
import { selectNotesList } from "reducers/notes";
import { selectAnnotationListByBook } from "reducers/annotations";
import PropTypes from "prop-types";
import { ModelsNote, ModelsBook, ModelsUser, ModelsAnnotation } from "models";
import withLoading from "hoc/withLoading";
import GettingStartedContent from "modules/annotations/components/gettingStartedContent/GettingStartedContent";

const AnnotationListWithLoading = withLoading(AnnotationList);

class SharedAnnotations extends Component {
  static propTypes = {
    selectedSharedBook: PropTypes.instanceOf(ModelsBook),
    filteredAnnotations: PropTypes.arrayOf(
      PropTypes.instanceOf(ModelsAnnotation)
    ).isRequired,
    user: PropTypes.instanceOf(ModelsUser).isRequired,
    notes: PropTypes.arrayOf(PropTypes.instanceOf(ModelsNote)).isRequired,
    isFetching: PropTypes.bool.isRequired,
    showWelcome: PropTypes.bool.isRequired,
    fetchNotes: PropTypes.func.isRequired,
    fetchAnnotations: PropTypes.func.isRequired
  };

  static defaultProps = {
    selectedSharedBook: null
  };

  componentDidMount() {
    const { fetchAnnotations, fetchNotes, selectedSharedBook } = this.props;

    fetchNotes();
    selectedSharedBook && fetchAnnotations(selectedSharedBook.id);
  }

  componentDidUpdate(prevProps) {
    const { selectedSharedBook, fetchAnnotations } = this.props;

    if (
      selectedSharedBook &&
      prevProps.selectedSharedBook !== selectedSharedBook
    ) {
      fetchAnnotations(selectedSharedBook.id);
    }
  }

  render() {
    const {
      filteredAnnotations,
      selectedSharedBook,
      isFetching,
      user,
      notes,
      showWelcome
    } = this.props;
    return showWelcome ? (
      <GettingStartedContent user={user} />
    ) : (
      <AnnotationListWithLoading
        book={selectedSharedBook}
        annotations={filteredAnnotations}
        isFetching={isFetching}
        user={user}
        customNotes={notes}
        type="shared"
      />
    );
  }
}

function mapStateToProps(state) {
  const annotations = selectAnnotationListByBook(
    state,
    state.books.selectedSharedBookId
  );
  const selectedSharedBook = selectBookById(
    state,
    state.books.selectedSharedBookId
  );

  return {
    selectedSharedBook,
    user: state.auth.user,
    filteredAnnotations: searchAnnotations(annotations, state.ui.filterText),
    isFetching: state.annotations.isFetching || state.books.isFetching,
    notes: selectNotesList(state),
    showWelcome:
      !!state.books.receivedAt && state.books.sharedItems.length === 0
  };
}

export default connect(
  mapStateToProps,
  {
    fetchNotes: fetchNotesAction,
    fetchAnnotations: fetchAnnotationsAction
  }
)(SharedAnnotations);
