/*global ga*/
import { debounce } from "lodash";
// import faker from "faker";
import React, { Component } from "react";
import { connect } from "react-redux";
import { setSearchVal, setSearchFilter } from "actions/uiActions";
import { Search, Grid } from "semantic-ui-react";

class GlobalSearch extends Component {
  componentWillMount() {
    this.resetComponent();
  }

  resetComponent = () => {
    this.setState({ isLoading: false });
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true });
    this.props.setSearchVal(value);
    // Mocking asynchronous for later
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
      this.props.setSearchFilter(value);
    }, 300);
  };

  render() {
    const { isLoading } = this.state;

    return (
      <Grid>
        <Search
          style={{ marginRight: "auto", marginLeft: "auto" }}
          loading={isLoading}
          resultRenderer={() => {}}
          showNoResults={false}
          onSearchChange={debounce(this.handleSearchChange, 500, {
            leading: true
          })}
          /* NOTE: should abstract search analytics to some sort of DataLayer or GTM concern*/
          onFocus={() => {
            ga(
              "send",
              "event",
              "topMenu",
              "annotations-search",
              this.props.currentSelectedBook
            );
          }}
          onBlur={(event, data) => {
            ga("send", "event", "topMenu", "annotations-search", data.value);
          }}
          value={this.props.searchVal}
        />
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  searchVal: state.ui.searchVal
});

export default connect(
  mapStateToProps,
  { setSearchVal, setSearchFilter }
)(GlobalSearch);
