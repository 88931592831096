import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ModelsBook } from "models";
import { fetchPublicBooks as fetchPublicBooksAction } from "actions/booksActions";
import {
  followUser as followUserAction,
  unfollowUser as unfollowUserAction,
  getFollowed as getFollowedAction,
  getFollowers as getFollowersAction,
  getStats as getStatsAction,
  getUserId as getUserIdAction
} from "actions/authActions";
import get from "lodash/get";
import indexOf from "lodash/indexOf";
import ProfileBooks from "modules/publicProfile/components/ProfileBooks";
import ProfileHeader from "modules/publicProfile/components/ProfileHeader";
import Following from "modules/publicProfile/components/Following";
import Followers from "modules/publicProfile/components/Followers";
import ProfileNotFound from "modules/publicProfile/components/ProfileNotFound";
import withLoading from "hoc/withLoading";
import { selectPublicBooksByUsername } from "../../../reducers/books";

const ProfileBooksWithLoading = withLoading(ProfileBooks);
const ProfileHeaderWithLoading = withLoading(ProfileHeader);
const FollowingWithLoading = withLoading(Following);
const FollowersWithLoading = withLoading(Followers);

class PublicProfile extends Component {
  static propTypes = {
    books: PropTypes.arrayOf(PropTypes.instanceOf(ModelsBook)),
    fetchPublicBooks: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired, // eslint-disable-line
    isFetching: PropTypes.bool.isRequired,
    initialAuthSet: PropTypes.bool.isRequired,
    hasFetchedUsername: PropTypes.bool.isRequired,
    hasFetchedFollowed: PropTypes.bool.isRequired,
    hasFetchedFollowers: PropTypes.bool.isRequired,
    user: PropTypes.object, // eslint-disable-line
    followUser: PropTypes.func.isRequired,
    getFollowed: PropTypes.func.isRequired,
    getFollowers: PropTypes.func.isRequired,
    getStats: PropTypes.func.isRequired,
    getUserId: PropTypes.func.isRequired,
    unfollowUser: PropTypes.func.isRequired,
    hasFetchedUserStats: PropTypes.bool.isRequired,
    followed: PropTypes.array.isRequired, // eslint-disable-line
    followers: PropTypes.array.isRequired, // eslint-disable-line
    userStats: PropTypes.object // eslint-disable-line
  };

  static defaultProps = {
    books: [],
    user: null,
    userStats: null
  };

  constructor(props) {
    super(props);
    this.state = { selected: "books" };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const prevUsername = prevProps.match.params.username;
    const newUsername = match.params.username;
    if (prevUsername !== newUsername) {
      this.fetchData();
      this.setState({ selected: "books" }); // eslint-disable-line
    }
  }

  fetchData = () => {
    const {
      match,
      fetchPublicBooks,
      getFollowed,
      getFollowers,
      getStats,
      getUserId
    } = this.props;
    fetchPublicBooks(match.params.username);
    getUserId(match.params.username);
    getFollowed(match.params.username);
    getFollowers(match.params.username);
    getStats({ username: match.params.username });
  };

  generateContent = selected => {
    const { books, isFetching, followed, followers, userStats } = this.props;

    switch (selected) {
      case "books":
        return (
          <ProfileBooksWithLoading
            books={books}
            isFetching={isFetching}
            userStats={userStats}
          />
        );
      case "following":
        return (
          <FollowingWithLoading followed={followed} isFetching={isFetching} />
        );
      case "followers":
        return (
          <FollowersWithLoading followers={followers} isFetching={isFetching} />
        );

      default:
        return null;
    }
  };

  setSelectedState = selected => {
    this.setState({ selected });
  };

  render() {
    const {
      match,
      hasFetchedUsername,
      initialAuthSet,
      user,
      followUser,
      followers,
      followed,
      unfollowUser,
      userStats,
      hasFetchedFollowed,
      hasFetchedFollowers,
      hasFetchedUserStats,
      profileUserId
    } = this.props;
    const { selected } = this.state;
    const profileUsername = get(match, "params.username");
    const activeUsername = get(user, "username");
    const isLoggedIn = user && initialAuthSet;
    const loggedInUserId = get(user, "id");
    const isFollowingUser = indexOf(followers, loggedInUserId) > -1;
    const content = this.generateContent(selected);
    const numberOfFollowed = followed.length;
    const numberOfFollowers = followers.length;
    // if profileUserId is NULL then user does not exist in the system
    const userNotFound = profileUserId === null;

    return userNotFound ? (
      <ProfileNotFound />
    ) : (
      <React.Fragment>
        <ProfileHeaderWithLoading
          username={profileUsername}
          isLoggedIn={isLoggedIn}
          showFollow={
            (initialAuthSet && !user) ||
            (initialAuthSet &&
              hasFetchedUsername &&
              activeUsername !== profileUsername)
          }
          isFetching={
            !initialAuthSet ||
            !hasFetchedFollowers ||
            !hasFetchedFollowed ||
            !hasFetchedUserStats
          }
          isFollowingUser={isFollowingUser}
          followUser={followUser}
          unfollowUser={unfollowUser}
          setSelectedState={this.setSelectedState}
          selected={selected}
          numberOfFollowers={numberOfFollowers}
          numberOfFollowed={numberOfFollowed}
          userStats={userStats}
        />
        {content}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  const { match } = props;
  const username = get(match, "params.username");
  const books = selectPublicBooksByUsername(
    state,
    get(match, "params.username")
  );
  const profileUserId = get(state, `auth.usernameMap.${username}`);
  const followed = get(state, `auth.followedByUserId.${profileUserId}`);
  const followers = get(state, `auth.followersByUserId.${profileUserId}`);
  const userStats = get(state, `auth.statsByUserId.${profileUserId}`);

  return {
    books,
    isFetching: books.length === 0 && state.books.isFetching,
    hasFetchedUsername: state.auth.hasFetchedUsername,
    initialAuthSet: state.auth.initialAuthSet,
    followed: followed || [],
    followers: followers || [],
    hasFetchedFollowed: !!followed,
    hasFetchedFollowers: !!followers,
    hasFetchedUserStats: !!userStats,
    userStats,
    usernameMap: state.auth.usernameMap,
    profileUserId
  };
}

export default connect(
  mapStateToProps,
  {
    fetchPublicBooks: fetchPublicBooksAction,
    followUser: followUserAction,
    unfollowUser: unfollowUserAction,
    getFollowed: getFollowedAction,
    getStats: getStatsAction,
    getUserId: getUserIdAction,
    getFollowers: getFollowersAction
  }
)(PublicProfile);
