import { createSelector } from "reselect";
import keyBy from "lodash/keyBy";
import values from "lodash/values";
import omit from "lodash/omit";

const initialState = {
  byId: {},
  isFetching: false,
  receivedAt: null,
  selectedAnnotation: null
};

function notesReducer(state = initialState, action) {
  switch (action.type) {
    case "FETCH_NOTES": {
      return { ...state, isFetching: true };
    }
    case "FETCH_NOTES_FAILURE": {
      return { ...state, isFetching: false };
    }
    case "FETCH_NOTES_SUCCESS": {
      return {
        ...state,
        byId: keyBy(action.notes, "id"),
        isFetching: false,
        receivedAt: Date.now()
      };
    }
    case "EDIT_NOTE":
    case "ADD_NOTE":
    case "DELETE_NOTE": {
      return {
        ...state,
        isFetching: true,
        selectedAnnotation: action.annotationId
      };
    }
    case "EDIT_NOTE_FAILURE":
    case "ADD_NOTE_FAILURE":
    case "DELETE_NOTE_FAILURE": {
      return {
        ...state,
        isFetching: true,
        selectedAnnotation: null
      };
    }
    case "ADD_NOTE_SUCCESS": {
      return {
        ...state,
        isFetching: false,
        selectedAnnotation: null,
        byId: { ...state.byId, [action.note.id]: action.note }
      };
    }
    case "EDIT_NOTE_SUCCESS": {
      return {
        ...state,
        isFetching: false,
        selectedAnnotation: null,
        byId: {
          ...state.byId,
          [action.noteId]: {
            id: action.noteId,
            content: action.noteContent,
            dateModified: action.dateModified,
            dateCreated: action.dateCreated,
            userId: action.userId,
            annotationId: action.annotationId,
            type: "custom"
          }
        }
      };
    }
    case "DELETE_NOTE_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        selectedAnnotation: null,
        byId: omit(state.byId, action.noteId)
      };
    }
    default:
      return state;
  }
}

export default notesReducer;

export const selectNotes = state => {
  return state.notes.byId;
};

export const selectNotesList = createSelector([selectNotes], notes =>
  values(notes)
);
