import React from "react";
import Import from "modules/import/Import";
import LayoutsDefaultNoSideNav from "layouts/defaultNoSideNav/DefaultNoSideNav";

const ScreensImport = props => (
  <LayoutsDefaultNoSideNav {...props}>
    <Import {...props} />
  </LayoutsDefaultNoSideNav>
);

export default ScreensImport;
