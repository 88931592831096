import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { List, Grid, Segment, Header, Icon } from "semantic-ui-react";
import UserCardContainer from "modules/publicProfile/containers/UserCardContainer";

const Following = ({ followed }) => {
  return (
    <Grid columns={1} centered style={{ marginTop: "30px" }} doubling>
      <List
        horizontal
        selection
        verticalAlign="middle"
        size="massive"
        style={{ width: "70%" }}
      >
        {followed.length === 0 ? (
          <Segment basic>
            <Header icon>
              <Icon name="group" />
              Not sure who to follow? Give
              <Link to="/public/kevin">
                <strong> Kevin </strong>
              </Link>
              a follow to see what he's been reading lately!
            </Header>
          </Segment>
        ) : (
          followed.map(follow => <UserCardContainer userId={follow} />)
        )}
      </List>
    </Grid>
  );
};

Following.propTypes = {
  followed: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Following;
