import { put, takeLatest, all, call } from "redux-saga/effects";
import { sortBy } from "lodash";
import sortAlphabeticalWithoutThe from "utils/sortAlphabeticalWithoutThe";

import {
  FETCH_BOOKS,
  FETCH_BOOKS_SUCCESS,
  FETCH_BOOKS_FAILURE,
  FETCH_BOOK,
  FETCH_BOOK_SUCCESS,
  FETCH_BOOK_FAILURE,
  TOGGLE_PUBLIC_BOOK,
  TOGGLE_PUBLIC_BOOK_SUCCESS,
  TOGGLE_PUBLIC_BOOK_FAILURE,
  FETCH_PUBLIC_BOOKS,
  FETCH_PUBLIC_BOOKS_SUCCESS,
  FETCH_PUBLIC_BOOKS_FAILURE
} from "actions/booksActions";
import { ADD_TO_RECENT_IMPORTS } from "actions/extensionActions";

import { firestore } from "fire";
import { Base64 } from "js-base64";
import { ModelsBook } from "models";
import { auth } from "../fire";

function encodeEmail(email) {
  /* Encode the email using base64 and removing the two == at the end */
  let encodedEmail = Base64.encode(email);
  encodedEmail = encodedEmail.substring(0, encodedEmail.length - 2);
  return encodedEmail;
}

const requestResourceInstances = () => {
  const userId = auth().currentUser.uid;

  return firestore()
    .collection("resourceInstances")
    .where("userId", "==", userId)
    .get()
    .then(querySnapshot => {
      var resourceInstances = [];
      // should be mapping
      querySnapshot.forEach(function(doc) {
        if (doc.exists) {
          let resourceData = doc.data();
          let book = new ModelsBook(
            doc.id,
            resourceData.bookId,
            resourceData.userId,
            resourceData.userInfo.displayName,
            resourceData.resourceInfo.ASIN,
            resourceData.resourceInfo.author,
            resourceData.resourceInfo.dateCreated,
            resourceData.resourceInfo.dateModified,
            resourceData.resourceInfo.title,
            resourceData.resourceInfo.url,
            resourceData.resourceInfo.imgUrl,
            false, // shared... may want to be explicit... named args?
            resourceData.publicShare,
            "", // username NA
            resourceData.metricsAnnotationCount
          );
          resourceInstances.push(book);
        }
      });

      return resourceInstances;
    });
};

const requestSharedResourceInstances = () => {
  const encodedEmail = encodeEmail(auth().currentUser.email);
  return firestore()
    .collection("resourceInstances")
    .where(`permissions.${encodedEmail}`, "==", true)
    .get()
    .then(querySnapshot => {
      var resourceInstances = [];
      // should be mapping
      querySnapshot.forEach(function(doc) {
        if (doc.exists) {
          let resourceData = doc.data();
          let book = new ModelsBook(
            doc.id,
            resourceData.bookId,
            resourceData.userId,
            resourceData.userInfo.displayName,
            resourceData.resourceInfo.ASIN,
            resourceData.resourceInfo.author,
            resourceData.resourceInfo.dateCreated,
            resourceData.resourceInfo.dateModified,
            resourceData.resourceInfo.title,
            resourceData.resourceInfo.url,
            resourceData.resourceInfo.imgUrl,
            true, // shared... may want to be explicit... named args?
            resourceData.publicShare,
            "", // username NA
            resourceData.metricsAnnotationCount
          );
          resourceInstances.push(book);
        }
      });

      return resourceInstances;
    });
};

function* fetchBooks(action) {
  try {
    let books = yield call(requestResourceInstances);
    let sharedBooks = yield call(requestSharedResourceInstances);
    books = yield sortBy(books, [book => sortAlphabeticalWithoutThe(book)]);
    sharedBooks = yield sortBy(sharedBooks, [
      book => sortAlphabeticalWithoutThe(book)
    ]);

    yield put({ type: FETCH_BOOKS_SUCCESS, books, sharedBooks });
  } catch (e) {
    yield put({ type: FETCH_BOOKS_FAILURE, message: e.message });
    console.log(e);
  }
}

const requestResourceInstance = resourceInstanceId => {
  return firestore()
    .collection("resourceInstances")
    .doc(resourceInstanceId)
    .get()
    .then(doc => {
      if (doc.exists) {
        let resourceData = doc.data();
        let book = new ModelsBook(
          doc.id,
          resourceData.bookId,
          resourceData.userId,
          resourceData.userInfo.displayName,
          resourceData.resourceInfo.ASIN,
          resourceData.resourceInfo.author,
          resourceData.resourceInfo.dateCreated,
          resourceData.resourceInfo.dateModified,
          resourceData.resourceInfo.title,
          resourceData.resourceInfo.url,
          resourceData.resourceInfo.imgUrl,
          resourceData.isShared,
          resourceData.publicShare,
          "", // username NA
          resourceData.metricsAnnotationCount
        );
        return book;
      }
    });
};

const requestUsernameById = userId => {
  return (
    firestore()
      .collection("usernames")
      .where("userId", "==", userId)
      // there should only be one username per id
      .limit(1)
      .get()
      .then(querySnapshot => {
        let res = null;
        querySnapshot.forEach(doc => {
          if (doc.exists) {
            res = doc.id;
          }
        });
        return res;
      })
  );
};

function* fetchBook(action) {
  try {
    const book = yield call(requestResourceInstance, action.resourceId);
    const username = yield call(requestUsernameById, book.ownerId);
    book.username = username;
    yield put({ type: FETCH_BOOK_SUCCESS, book });
  } catch (e) {
    yield put({ type: FETCH_BOOK_FAILURE, message: e.message });
    console.log(e);
  }
}

function requestTogglePublicBook(resourceInstanceId, publicShareState) {
  return firestore()
    .collection("resourceInstances")
    .doc(resourceInstanceId)
    .update({
      publicShare: publicShareState
    });
}

function* togglePublicBook(action) {
  try {
    yield call(
      requestTogglePublicBook,
      action.resourceInstanceId,
      action.publicShareState
    );
    yield put({
      type: TOGGLE_PUBLIC_BOOK_SUCCESS,
      resourceInstanceId: action.resourceInstanceId,
      publicShareState: action.publicShareState
    });
  } catch (e) {
    yield put({ type: TOGGLE_PUBLIC_BOOK_FAILURE, payload: e.message });
    console.log(e);
  }
}

const requestPublicBooks = (userId, username) => {
  return firestore()
    .collection("resourceInstances")
    .where("userId", "==", userId)
    .where("publicShare", "==", true)
    .get()
    .then(querySnapshot => {
      var resourceInstances = [];
      // should be mapping
      querySnapshot.forEach(function(doc) {
        if (doc.exists) {
          let resourceData = doc.data();
          let book = new ModelsBook(
            doc.id,
            resourceData.bookId,
            resourceData.userId,
            resourceData.userInfo.displayName,
            resourceData.resourceInfo.ASIN,
            resourceData.resourceInfo.author,
            resourceData.resourceInfo.dateCreated,
            resourceData.resourceInfo.dateModified,
            resourceData.resourceInfo.title,
            resourceData.resourceInfo.url,
            resourceData.resourceInfo.imgUrl,
            true, // shared... may want to be explicit... named args?
            resourceData.publicShare,
            username,
            resourceData.metricsAnnotationCount
          );
          resourceInstances.push(book);
        }
      });

      return resourceInstances;
    });
};

const getUserIdByUsername = username => {
  return firestore()
    .collection("usernames")
    .doc(username)
    .get()
    .then(doc => (doc.exists ? doc.data().userId : null));
};
function* fetchPublicBooks(action) {
  try {
    const userId = yield call(getUserIdByUsername, action.username);
    let publicBooks = yield call(requestPublicBooks, userId, action.username);
    publicBooks = yield sortBy(publicBooks, [
      book => sortAlphabeticalWithoutThe(book)
    ]);
    yield put({ type: FETCH_PUBLIC_BOOKS_SUCCESS, publicBooks });
  } catch (e) {
    yield put({ type: FETCH_PUBLIC_BOOKS_FAILURE, message: e.message });
    console.log(e);
  }
}

export default function* booksSaga() {
  yield all([
    takeLatest(FETCH_BOOKS, fetchBooks),
    takeLatest(FETCH_BOOK, fetchBook),
    takeLatest(ADD_TO_RECENT_IMPORTS, fetchBook),
    takeLatest(TOGGLE_PUBLIC_BOOK, togglePublicBook),
    takeLatest(FETCH_PUBLIC_BOOKS, fetchPublicBooks)
  ]);
}
