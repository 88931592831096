import React, { Component } from "react";
import { Form, Message, Segment } from "semantic-ui-react";
import { auth } from "fire";
/**
 * @name EmailLogin
 * @description Login with email
 */
export default class EmailLogin extends Component {
  state = {
    email: "",
    password: "",
    hasError: false,
    errorHeader: undefined,
    errorContent: undefined,
    loading: false
  };

  handleChange = event =>
    this.setState({ [event.target.name]: event.target.value });

  submit = () => {
    const { email, password } = this.state;
    this.setState({ loading: true, hasError: false });
    // firebase handles redirecting to home.
    return auth()
      .signInWithEmailAndPassword(email.trim(), password.trim())
      .catch(err => {
        this.setState({
          loading: false,
          hasError: true,
          errorHeader: err.code,
          errorContent: err.message
        });
        console.log(err);
      });
  };

  render() {
    const {
      email,
      password,
      loading,
      hasError,
      errorHeader,
      errorContent
    } = this.state;
    return (
      <Segment stacked>
        <Form loading={loading} error={hasError} onSubmit={this.submit}>
          <Message error header={errorHeader} content={errorContent} />
          <Form.Input
            name="email"
            value={email}
            onChange={this.handleChange}
            type="email"
            fluid
            icon="user"
            iconPosition="left"
            placeholder="E-mail address"
          />
          <Form.Input
            name="password"
            value={password}
            onChange={this.handleChange}
            type="password"
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Password"
          />
          <Form.Button fluid color="blue">
            Submit
          </Form.Button>
        </Form>
      </Segment>
    );
  }
}
