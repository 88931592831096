import React from "react";
import LayoutsDefault from "layouts/default/Default";
import SharedAnnotations from "modules/annotations/containers/SharedAnnotations";
import SharedBooksSelector from "modules/annotations/containers/SharedBooksSelector";

const ScreensSharedAnnotations = props => (
  <LayoutsDefault {...props} renderMenu={() => <SharedBooksSelector />}>
    <SharedAnnotations />
  </LayoutsDefault>
);

export default ScreensSharedAnnotations;
