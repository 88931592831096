import React from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const ReturnToProfileButton = ({ username }) => (
  <Link to={{ pathname: `/public/${username}` }}>
    <Button
      className="shareButton"
      icon
      labelPosition="left"
      basic
      color="blue"
      compact
      size="tiny"
      data-clickable="true"
      data-location="bookTable"
      data-action="returnToProfile"
      data-label={username}
    >
      {username}'s Profile
      <Icon
        name="user"
        data-clickable="true"
        data-location="bookTable"
        data-action="returnToProfile"
        data-label={username}
      />
    </Button>
  </Link>
);

ReturnToProfileButton.propTypes = {
  username: PropTypes.string.isRequired
};

export default ReturnToProfileButton;
