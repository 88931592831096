import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchNotes as fetchNotesAction } from "actions/notesActions";
import { fetchAnnotations as fetchAnnotationsAction } from "actions/annotationsActions";
import { selectAnnotationListByBook } from "reducers/annotations";
import { selectBookById } from "reducers/books";
import { selectNotesList } from "reducers/notes";
import AnnotationList from "modules/annotations/components/annotationList/AnnotationList";
import searchAnnotations from "utils/searchAnnotations";
import PropTypes from "prop-types";
import { ModelsNote, ModelsBook, ModelsUser, ModelsAnnotation } from "models";
import withLoading from "hoc/withLoading";
import GettingStartedContent from "modules/annotations/components/gettingStartedContent/GettingStartedContent";

const AnnotationListWithLoading = withLoading(AnnotationList);

class Annotations extends Component {
  static propTypes = {
    selectedBook: PropTypes.instanceOf(ModelsBook),
    filteredAnnotations: PropTypes.arrayOf(
      PropTypes.instanceOf(ModelsAnnotation)
    ).isRequired,
    user: PropTypes.instanceOf(ModelsUser).isRequired,
    notes: PropTypes.arrayOf(PropTypes.instanceOf(ModelsNote)).isRequired,
    isFetching: PropTypes.bool.isRequired,
    showWelcome: PropTypes.bool.isRequired,
    fetchNotes: PropTypes.func.isRequired,
    fetchAnnotations: PropTypes.func.isRequired
  };

  static defaultProps = {
    selectedBook: null
  };

  componentDidMount() {
    const { fetchNotes, fetchAnnotations, selectedBook } = this.props;

    fetchNotes(); // Currently fetching all -- Should be fetched when annotations are fetched
    selectedBook && fetchAnnotations(selectedBook.id);
  }

  componentDidUpdate(prevProps) {
    const { selectedBook, fetchAnnotations } = this.props;

    if (selectedBook && prevProps.selectedBook !== selectedBook) {
      fetchAnnotations(selectedBook.id);
    }
  }

  render() {
    const {
      filteredAnnotations,
      selectedBook,
      isFetching,
      showWelcome,
      user,
      notes
    } = this.props;

    return showWelcome ? (
      <GettingStartedContent user={user} />
    ) : (
      <AnnotationListWithLoading
        book={selectedBook}
        annotations={filteredAnnotations}
        type="own"
        isFetching={isFetching}
        user={user}
        customNotes={notes}
      />
    );
  }
}

function mapStateToProps(state) {
  const annotations = selectAnnotationListByBook(
    state,
    state.books.selectedBookId
  );
  const selectedBook = selectBookById(state, state.books.selectedBookId);
  return {
    selectedBook,
    user: state.auth.user,
    filteredAnnotations: searchAnnotations(annotations, state.ui.filterText),
    isFetching: state.annotations.isFetching || state.books.isFetching,
    showWelcome: !!state.books.receivedAt && state.books.items.length === 0,
    notes: selectNotesList(state)
  };
}

export default connect(
  mapStateToProps,
  {
    fetchNotes: fetchNotesAction,
    fetchAnnotations: fetchAnnotationsAction
  }
)(Annotations);
